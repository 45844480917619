import React, { Component, Fragment } from "react";
import { Row, FormGroup, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import TooltipItemText from "../../components/common/TooltipItemText"
import ProductGrid from "../../components/pos/ProductGrid";
import GetPackagesModal from "../../containers/pointofsale/GetPackagesModal";
export default class DealGrid extends Component {
	constructor(props) {
		super();
		this.state = {
			search: '',
			filtered_products: [],
			openProduct: false,
			deal: [],
			modalOpenGetPackages: false
		}

		this.addToCart = this.addToCart.bind(this);
		this.productGrid = this.productGrid.bind(this);
		this.toggleModalGetPackages = this.toggleModalGetPackages.bind(this);
	}

	addToCart(product_package) {
		console.log('####Nush', product_package);

		product_package = JSON.parse(JSON.stringify(product_package));
		var values = {};
		values.product_id = product_package.product_id;
		values.deal_id = this.state.deal?.deal_id;
		values.product_price = product_package.product_unit_price;
		values.product_unit_price = product_package.product_unit_price;
		values.exise_tax = product_package.exise_tax;
		values.sales_tax = product_package.sales_tax;
		values.quantity = 1;
		values.variation_id = product_package.variation_id;
		values.including_tax = product_package.including_tax;
		values.amount_of_flower = product_package.amount_of_flower;

		values.package_id = product_package.package_id;
		values.is_finished = product_package.is_finished;
		values.is_deal = 1;
		values.deal_amount = this.state.deal?.amount
		values.deal_type = this.state.deal?.type
		// values.name = product_package.name;
		// values.real_stock = Math.min(...product_package.deal_products.map(r => parseInt(r.stock)));
		// values.deal_products = JSON.stringify(product_package.deal_products.map(r => {
		// 	delete r.stock;
		// 	r.quantity = parseInt(r.quantity);
		// 	return r;
		// }));
		this.setState({
			modalOpenGetPackages: false,
			// deal: []
		})
		this.props.addToCart(values);


	}
	productGrid(row) {
		console.log('######', row.deal_products);

		this.setState({
			openProduct: true,
			filtered_products: row.deal_products,
			deal: row,
		})
	}
	toggleModalGetPackages = (e, product_id = 0) => {
		this.setState({
			product_id,
			modalOpenShowProducts: false,
			modalOpenGetPackages: !this.state.modalOpenGetPackages
		});
	};
	render() {
		const { products, currency_rate, currency_symbol, index } = this.props;
		const filtered_products = this.state.filtered_products;
		console.log('Annusssssss',filtered_products);
		
		var final_products = products;
		if (this.state.search) {
			final_products = final_products.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		console.log('######123', this.state.deal);
		return (<Fragment>
			<div className="mb-2 p-2 product-item-search dropdown-edit">
				<FormGroup row className="mb-0">
					<Colxx lg="12">
						<Input
							type="text"
							name={"product_search" + index}
							id={"product_search" + index}
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
						/>
					</Colxx>
				</FormGroup>
			</div>
			<PerfectScrollbar
				option={{ suppressScrollX: true, wheelPropagation: false }}
			>
				<div className="d-flex flex-wrap">
					{final_products.map((row, index) => {
						return (<Colxx md="3" xxl="2" className="text-center product-item" key={index} onClick={(e) => { this.productGrid(row); }}>


							<div>
								<img src={row.thumbnail_url} className="product-thumnail img-responsive" />
								<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
								<p className="mb-0 text-danger font-weight-bold">{(parseFloat(row.amount * currency_rate).toFixed(2))}{row.type == 0 ? " $ OFF" : "% OFF"}
								</p>
								<div className="text-center">
									<TooltipItemText key={"tip_deal_" + index} item={{
										className: 'mr-1 btn btn-primary btn-xs round-tip ',
										placement: "bottom",
										text: "?",
										body: <p className="text-left">Product: {row.product_name}<br />Description:<br />{row.description}</p>
									}} id={"tip_deal_" + index} />
								</div>
							</div>
						</Colxx>)
					})}
				</div>
			</PerfectScrollbar>
			{this.state.openProduct ? <ProductGrid
				products={filtered_products}
				currency_rate={this.state.currency_rate}
				currency_symbol={this.state.currency_symbol}
				toggleModalGetPackages={this.toggleModalGetPackages}
				index={index}
			/> : ""}
			<GetPackagesModal
				modalOpen={this.state.modalOpenGetPackages}
				toggleModal={this.toggleModalGetPackages}
				addToCart={this.addToCart}
				product_id={this.state.product_id}
			/>
		</Fragment>);
	}
}
