import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Select from "react-select";
import swal from "sweetalert";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class EditAccountsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
            account:null,
			startDate: null,
			selectCategory:[],		
			selectCategoryType:null,
			selectStaff:[],
			selectStaffType:null,
			selectOrders:[],
			name:"",
			amount:"",
			orders:[],
		};	
	}
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-account").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("accountForm"));
			formData.append('expense_id',this.props.account_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			if(!this.state.selectCategoryType){
				swal("Warning!","Please add  a type in data variable module","warning");
				return;
			}
			formData.append('data_variable',this.state.selectCategoryType.id)
			
			// axios.post(REST_API_END_POINT+'account/edit-account',formData).then((res)=>{
				axios.post(REST_API_END_POINT+'kit/edit-kit',formData).then((res)=>{
				var data = res.data;
				
				var account=this.state.account;
				account.name=values.name;
				account.amount=values.amount;
				this.setState({ account
					});
				
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
				}
				
				document.getElementById("update-account").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-account").disabled=false;
			});

		}
	}
	componentDidMount() {		
		var category=[];
		var staff=[]
		var orders=[]
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'kit/get_all_data_variable',formData).then((res)=>{
			var data = res.data;
			orders=data.orders;
			staff=data.staffs;
			
			
			staff = staff.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			staff.push({id:0,label:"None",value:0})
			category=data.categories;
			orders = orders.map((element,index)=>{
				element.label=element.order_id;
				element.value=element.order_id;
				element.key=index;
				return element;
			});
			
			
			category = category.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			
			this.setState({
				selectCategory:category,
				orders,
				selectStaff:staff,
				
				
			});	
			let account; 	
		
			var formData = new FormData();
			formData.append('account_id',this.props.account_id);
	
				axios.post(REST_API_END_POINT+'kit/kit-by-id',formData).then((res)=>{
					var data = res.data;
					var status=null;
					account=data.account;
					var name=account.name
					var amount=account.amount;
					var date=moment(account.spend_on*1000).format("MM/DD/YYYY")
						//var date=moment.unix(account.spend_on).format("MM/DD/YYYY")
							
						const selectStaffType =staff.find(r=>{ return r.id==account.staff_id });
						let Ids =account.orders.split(',');
						let selectOrders = this.state.orders.filter(row=>{ return Ids.indexOf(row.value)!==-1 });
						
							
						const selectCategoryType = category.find(r=>{ return r.id==account.data_variable});	
					this.setState({ 
						account,
						startDate:date,
						selectStaffType,
						name,
						amount,
						selectCategoryType,
						selectOrders
					});
				
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
			
	}
	// componentDidUpdate(prevProps, prevState, snapshot){	   
	// 	console.log('EEEEEEEEEE',this.props.modalOpen);
		
    //     if(this.props.modalOpen){
	// 		let account; 	
	// 		console.log('Hi',prevProps.account_id);
	// 		console.log('Hello',this.props.account_id);
				  
    //         if (prevProps.account_id != this.props.account_id && this.props.account_id!=0) {
	// 			console.log('EffE',this.props.modalOpen);
	// 			var formData = new FormData();
	// 			formData.append('account_id',this.props.account_id);
	
	// 			axios.post(REST_API_END_POINT+'expenses/expense-by-id',formData).then((res)=>{
	// 				var data = res.data;
	// 				var status=null;
	// 				account=data.account;
	// 					var date=moment(account.spend_on*1000).format("MM/DD/YYYY")
	// 					//var date=moment.unix(account.spend_on).format("MM/DD/YYYY")
	// 					console.log('REEERERER',this.state.selectStaff);
							
	// 					const selectStaffType =this.state.selectStaff.find(r=>{ return r.id==account.staff_id });
	// 					console.log('REEERERER',selectStaffType);
							
	// 					const selectCategoryType = this.state.selectCategory.find(r=>{ return r.id==account.data_variable});	
	// 				this.setState({ 
	// 					account,
	// 					startDate:date,
	// 					selectStaffType,
	// 					selectCategoryType,
	// 				});
				
					
	// 			}).catch(e =>{
	// 				console.log("Addition failed , Error ", e);
	// 			});	
	// 		}
	// 	}
	// }

	render() {
		const {  selectCategory,selectStaff,orders} = this.state;
		var account=this.state.account;
		// if(this.state.account!=null){
			
		// }else{
		// 	var account;
		// }
		console.log('##Orders',orders);
		
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add order to kit
			</ModalHeader>
			<AvForm
		        id="accountForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
				<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label> Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								value={ account?((account.name!=null)?account.name:''):'' }
								readOnly={true}
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  },
								//   pattern: {
								// 	value: "^[a-zA-Z0-9 ,.'-]+$",
								// 	errorMessage: "Invalid name"
								//   },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
				</Row>
				
				<Row >
					<Colxx lg="12 ">
						<Label >Orders<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							isMulti
							id="orders"
							name="orders[]"
							value={this.state.selectOrders }
							onChange={selectOrders=> { this.setState({ selectOrders }) }}
							options={orders}
						/>
					</Colxx>									
				</Row>
				<br></br>
				
				
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-account">
					  <IntlMessages id="customer.update" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
	}
};
