import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import { zeroPad } from "../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_PAYMENTS } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ApproveOrderModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'point-of-sale/get-order-detail',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("approve-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("approveForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);

			axios.post(REST_API_END_POINT+'point-of-sale/approve-order',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("approve-order").disabled=false;
				this.props.dataListRender();
				this.props.toggleModal();
				
				if(data.status==1){
					swal("Success",data.msg,"success");
				}else{
					swal("Failed",data.msg,"warning");
				}
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("approve-order").disabled=false;
			});

		}
	}
	
    render() {
		const { order_data } = this.state;
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Approve Order
				</ModalHeader>
				
				<AvForm
					id="approveForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{order_data!=null ? <Fragment>
							<Row>
								<Colxx md="8">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{zeroPad(order_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered>
											<thead>
												<tr>
													<th className="mb-2">#</th>						
													<th className="mb-2">Product</th>						
													<th className="mb-2 text-right">Quantity</th>	
																		
												</tr>
											</thead>
											<tbody>
												{order_data.order_products.map((row,index)=>{
													return(<tr key={index}>
													<td>{index+1}</td>
													<td>{row.product_name} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
													<td className="text-right">{number_format(parseFloat(row.product_qty),2,'.','')}</td>
													
												</tr>)
												})}
											</tbody>
										</Table>
										<img src={order_data.medical_card!=null ? REST_API_BASE_URL+"uploads/medical/"+order_data.medical_card:""}></img>
										{order_data.delivery_date!=0 ? <h4>Delivery Date  : {moment(order_data.delivery_date*1000).format('MM/DD/YYYY')}</h4>:""}
									</div>
								</Colxx>
							</Row>
							
							<hr/>
							<Row>
								<Colxx md="12">
									<AvGroup>
										<Label>Payment Mode<span className="text-danger">*</span></Label>
										<AvField
											id="payment_mode"
											name="payment_mode"
											type="select"
											value={this.state.order_data.payment_mode}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required."
												}
											}}
										>
											<option value="">-- Select Payment Mode --</option>
											{CUSTOMER_ORDER_PAYMENTS.map(row=>{
												return(<option value={row.value} key={row.key} >{row.label}</option>)
											})}
										</AvField>
									</AvGroup>
								</Colxx>
							</Row>
							
						</Fragment>:""}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="approve-order">
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
