import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
    Row,
    CustomInput,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
//import DatePicker from "react-datepicker";
import DatePicker from "../../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import moment from 'moment';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ViewFileModal from "../../../components/common/ViewFileModal";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectData = [
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
];
// const reasons = [
//     { label: "Customer didn’t have cash", value: "1", key: 0 },
//     { label: "Customer was unhappy with pricing", value: "2", key: 1 },
//     { label: "Customer didn’t like the customer experience", value: "3", key: 2 },
//     { label: "Customer was part of a group", value: "4", key: 3 },
//     { label: "I don’t know", value: "5", key: 4 },
//     { label: "Other", value: "6", key: 5 }
// ];



class EditCustomerModal extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.state = {
            reasons:[
                { label: this.props.customerName+" didn’t have cash", value: "1", key: 0 },
                { label: this.props.customerName+" was unhappy with pricing", value: "2", key: 1 },
                { label: this.props.customerName+" didn’t like the customer experience", value: "3", key: 2 },
                { label: this.props.customerName+" was part of a group", value: "4", key: 3 },
                { label: "I don’t know", value: "5", key: 4 },
                { label: "Other", value: "6", key: 5 }
            ],
            selectedReason: '',
            otherReason: '',
            showPopup: false,
        };

    }

    


    handleSubmit(event, errors, values) {
       

        if(this.state.selectedReason.label==null){
           
            
            swal("Warning!","Please choose a reason","warning");
            return ;
        }
			

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData();
			formData.append('customer_id', this.props.customer_id);
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
            formData.append('reason',this.state.selectedReason.label)
            formData.append('other_reason',this.state.otherReason)
			
			axios.post(REST_API_END_POINT + 'customer/customer_left', formData).then((res) => {
				var data = res.data;

				
				window.setTimeout(function () {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);

				JQUERY_MODULE.$('.alert .close').off("click", function () { }).on("click", function (e) {
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				});

                this.state = {

                    selectedReason: '',
                    otherReason: '',
                    showPopup: false,
                };

				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e => {
				console.log("Addition failed , Error ", e);
				
			});

		

    }



    render() {



        return (
            <Modal
                isOpen={this.props.modalOpen}
                toggle={this.props.toggleModal}
                wrapClassName="modal-right"
                size="md"
            >
                <ModalHeader toggle={this.props.toggleModal}>
                    Customer Left
                </ModalHeader>
                <AvForm
                    id="customerForm"
                    className="av-tooltip tooltip-right-bottom"
                    onSubmit={this.handleSubmit}
                >
                    <ModalBody>
                        <p>Select the reason why:</p>
                        <AvGroup className="error-t-negative ">

                            <Select
                                components={{ Input: CustomSelectInput }}
                                className="react-select"
                                classNamePrefix="react-select"
                                name="customer_type"
                                id="customer_type"
                                value={this.state.customer_type}
                                options={this.state.reasons}
                                onChange={reason => { this.setState({ selectedReason: reason }) }}
                            />
                        </AvGroup>

                       
                      

                    
                        {this.state.selectedReason && this.state.selectedReason.key === 5 && (    <Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="Other Reason" /></Label>
									<AvField
										id="reason"
										name="reason"
										type="text"
										value={this.state.otherReason}
                                        onChange={(e) => this.setState({ otherReason: e.target.value })}
									/>
								</AvGroup>
							</Colxx>
                        </Row>
                          )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" id="update-customer" >Submit</Button>
                        <Button color="secondary" outline onClick={this.props.toggleModal}>
                            <IntlMessages id="customer.cancel" />
                        </Button>{" "}
                    </ModalFooter>
                </AvForm>
            </Modal>
        );
    }
};

export default EditCustomerModal;
