import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus =  [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 },
];

class EditDrawerModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this); 
		this.state = {	   
			Status:null,	
		};	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){		
            let drawer;
			var status=null;
			 if (prevProps.drawer_id!== this.props.drawer_id && this.props.drawer_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('drawer_id',this.props.drawer_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'drawer/get-drawer-by-id',formData).then((res)=>{
					
					var data = res.data;
					drawer=data.drawer
					
						selectStatus.forEach((element,index) => {
                       if(element.value==drawer.status){
							status=element;
						}
					});
				
					this.setState({ 
						drawer,
					   statusType:status
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    }
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {			
			document.getElementById("update-drawer").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("drawerForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('drawer_id',this.props.drawer_id);

			axios.post(REST_API_END_POINT+'drawer/edit-drawer',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-drawer").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-drawer").disabled=false;
			});
		}
	}
	
	render() {
		if(this.state.drawer!=null){
			var drawer=this.state.drawer;
		}else{
			var drawer;
		}

		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Drawer
				</ModalHeader>
				<AvForm
					id="drawerForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Name<span className="text-danger">*</span></Label>
								  <AvField
									id="drawer_name"
									name="drawer_name"
									type="text"
									value={ drawer?((drawer.drawer_name!=null)?drawer.drawer_name:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required"
									  },
									  pattern: {
										value: "^[a-zA-Z0-9 ,.'-]+$",
										errorMessage: "Invalid name"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>					
						</Row>			
						{/* {drawer ? (drawer.assigned_status=='0' ?  */}
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Status</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="status"
										id="status"
										value={this.state.statusType}						
										options={selectStatus}
										onChange={statusType => { this.setState({ statusType }) }}
									/>
								</AvGroup>
							</Colxx>					
						</Row>
						 {/* :""):""} */}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="update-drawer" >
						  <IntlMessages id="customer.save" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditDrawerModal;
