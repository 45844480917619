import React, { Component, Fragment } from "react";
import { Row, FormGroup, Label, Input, Badge, Button } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

class AllPackages extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			search:''
		};
		this.addToCart = this.addToCart.bind(this);
	}
	
	addToCart(product_package){
		
		
		var product=this.props.product;
		var values={};
		// values.package_price=product_package.price
		values.product_id=product.id;
		values.product_price=product.product_price;
		values.product_unit_price=product_package.price;		
		values.exise_tax=product.exise_tax;
		values.sales_tax=product.sales_tax;
		values.quantity=1;
		values.variation_id=product.variation_id;
		
		values.package_id=product_package.package_id;
		values.is_finished=0;
		
		this.props.addToCart(values);		
	}
	
	render() {
		let data = this.props.all_packages;
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading w-100">{props.value}</p>
			},
			{
				Header: "Available",
				accessor: "available",
				Cell: props => <p className="text-muted w-100 mb-0 ">{props.value}</p>
			},
			{
				Header: "",
				width:50,
				Cell: props => <Button size="xs" color="success" className="mb-0" onClick={e=>{ this.addToCart(props.original) }} ><i className="fa fa-plus" ></i></Button>
			}
		];
	 
		return (
			<Fragment>				
				<Row>
					<Colxx lg="12">
						<div className="pr-2 pl-2  dropdown-edit">						
							<FormGroup row className="mb-0">
								<Colxx lg="12">
									<Input
										type="text"
										name="table_search"
										id="all_package_table_search"
										placeholder="Search"
										value={this.state.search}
										onChange={e => this.setState({search: e.target.value})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={4}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={false}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
				    </Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default AllPackages;
