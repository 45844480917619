import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad } from "../../helpers/Utils";
import number_format from "number_format-php";

export default class InvoiceModalContent extends Component {

	render() {
		const { order_data, order_products, facility_data, currencies, tax } = this.props;
		var current_currency_symbol = '$';
		var sales_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				current_currency_symbol = current_currency.currency_symbol;
			}
		}
		let tax_split = []
		let totalOff=0;
	
		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="">
					<div className="mb-5 invoice-contents">
						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">
										<div className="font-weight-bold h5">{facility_data != null ? facility_data.facility_name : ""}</div>
										{facility_data != null ? (facility_data.facility_address != null ? <p className="mb-0">{facility_data.facility_address}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_phone != null ? <p className="mb-0">{facility_data.facility_phone}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_email != null ? <p className="mb-0">{facility_data.facility_email}</p> : "") : ""}
										{facility_data != null ? (facility_data.license_number != null ? <p className="mb-0">Lic No: {facility_data.license_number}</p> : "") : ""}
									</div>
									{order_data != null ? <div className="w-30 justify-content-end text-right ">
										<div className="font-weight-bold text-uppercase h4">Invoice</div>
										<p className="mb-0">
											INVOICE # {zeroPad(order_data.order_number, 6)}<br />
											DATE: {moment(order_data.timestamp * 1000).format("MMMM DD, YYYY")}
											{order_data.payment_mode != 0 && order_data.payment_mode != "" ? <span className="mb-1">PAYMENT MODE: {order_data.payment_mode}</span> : ""}
										</p>
									</div> : ""}
								</div>
								<div className="border-bottom pt-4 mb-5" />

								{order_data != null ? <div className="d-flex flex-row justify-content-between mb-5">
									<div className="d-flex flex-column w-50 mr-2 p-4 bg-semi-muted">
										<div className="font-weight-bold h5">TO:</div>
										<p className="mb-0">
											{order_data.customer_name}<br />
											{order_data.billing_address != null ? <Fragment>{order_data.billing_address}<br /></Fragment> : ""}
											{order_data.customer_phone != null ? <Fragment>Tel: {order_data.customer_phone}</Fragment> : ""}
										</p>
									</div>
									<div className="d-flex w-50 flex-column p-4 bg-semi-muted">
										<div className="font-weight-bold h5">SHIPPED TO:</div>
										<p className="mb-0">
											{order_data.customer_name}<br />
											{order_data.shipping_address != null ? <Fragment>{order_data.shipping_address}<br /></Fragment> : ""}
											{order_data.customer_phone != null ? <Fragment>Tel: {order_data.customer_phone}</Fragment> : ""}
										</p>
									</div>
								</div> : ""}

								{order_data != null ? <Table borderless>
									<thead>
										<tr>
											<th className="mb-2">#</th>
											<th className="mb-2">Product</th>
											<th className="mb-2 text-right">Unit Price</th>
											<th className="mb-2 text-right">Quantity</th>
											<th className="mb-2 text-right">Price</th>
											<th className="mb-2 text-right">Tax</th>
											<th className="mb-2 text-right">Discount</th>
											<th className="mb-2 text-right">Total</th>
										</tr>
									</thead>
									<tbody>

										{order_products.map((row, index) => {
											sales_tax += parseFloat(row.product_sales_tax);
											p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

											let line_total = parseFloat(row.product_unit_price) * parseFloat(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

											if (row.variation_id == order_data.discount_variation_id) {
												line_total -= parseFloat(order_data.manual_discount);
											}
											sub_total += parseFloat(row.product_unit_price) * parseFloat(row.product_qty);
											totalOff+=parseFloat(row.totalOff);
											
											
											return (<tr key={index}>
												<td>{index + 1}</td>
												<td>{row.product_name} </td>
												<td className="text-right">{current_currency_symbol + "" + number_format(parseFloat(row.product_unit_price), 2, '.', '')}</td>
												{/* <td className="text-right">{number_format(parseFloat(row.product_qty),2,'.','')}</td> */}
												<td className="text-right">{row.is_finished == 0 ? number_format(parseFloat(row.product_qty), 2, '.', '') + " g" : parseInt(row.product_qty)}{row.variation_name!=null?" *"+row.variation_name:""} </td>
												<td className="text-right">{current_currency_symbol + "" + number_format(parseFloat((parseFloat(row.product_unit_price) * parseFloat(row.product_qty)).toFixed(2)), 2, '.', '')}</td>
												<td className="text-right">{current_currency_symbol + "" + number_format(parseFloat(row.product_sales_tax), 2, '.', '')}</td>
												<td className="text-right">{current_currency_symbol + ""}{row.variation_id != order_data.discount_variation_id ? number_format(parseFloat(row.product_discount) + parseFloat(row.coupon_discount)+ parseFloat(row.totalOff), 2, '.', '') : number_format(parseFloat(row.product_discount) + parseFloat(row.coupon_discount) + parseFloat(order_data.manual_discount), 2, '.', '')}</td>
												<td className="text-right">{current_currency_symbol + "" + number_format(line_total, 2, '.', '')}</td>
											</tr>)
										})}
									</tbody>
								</Table> : ""}
							</div>

							{tax.map((row, index) => {
								let currentTaxSplit;
								
								
								if (row.tax_split_up) {
									const taxSplitArray = JSON.parse(row.tax_split_up);
									currentTaxSplit = taxSplitArray.map((split) => {
										const roundedValue = (split.rate * sub_total / 100).toFixed(2);
										return { [split.name + " " + "(" + split.rate + "%)"]: parseFloat(roundedValue) };
									});
								} else {
									const taxRate = parseFloat(row.tax_rate);
									const roundedValue = (taxRate * sub_total / 100).toFixed(2);
									currentTaxSplit = [{ [row.name + " " + "(" + taxRate + "%)"]: parseFloat(roundedValue) }];
								}

								// Concatenate the current tax splits to the overall tax_splits array
								tax_split = tax_split.concat(currentTaxSplit);
							})}
						
							
							{order_data != null ? <div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										<tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol + "" + number_format(sub_total, 2, '.', '')}</td>
										</tr>
										<tr>
											<td className="pb-0"> Tax :</td>
											
											{tax_split?.map((row, index) => {
												return (
													<tr key={index}>
														
														{Object.entries(row).map(([name, value]) => (
															<td key={name} className="text-right pb-0">
																{`${name}:${current_currency_symbol}${value}`}
															</td>
														))}

													</tr>
												);
											})}
											<tr>
												{tax_split ? <td className="pb-0 ">Total Tax:{current_currency_symbol + "" + number_format(sales_tax, 2, '.', '')}</td> : <td className="pb-0 ">{current_currency_symbol + "" + number_format(sales_tax, 2, '.', '')}</td>}

											</tr>
										</tr>
										<tr>
											<td className="pb-0">Discount :</td>
											<td className="text-right pb-0">{current_currency_symbol + "" + number_format(p_discount + parseFloat(order_data.auto_apply_discount) + parseFloat(order_data.coupon_discount) + parseFloat(order_data.manual_discount_value) + parseFloat(totalOff) + parseFloat(order_data.point_discount), 2, '.', '') }</td>
										</tr>
										{parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
											<td className="pb-0">Refund :</td>
											<td className="text-right pb-0">{current_currency_symbol + "" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
										</tr> : ""}
										<tr className="font-weight-bold">
											<td className="pb-0">Grand Total :</td>
											<td className="text-right pb-0">{current_currency_symbol + "" + number_format(parseFloat(order_data.total) - parseFloat(p_discount)- parseFloat(totalOff), 2, '.', '')}</td>
										</tr>
									</tbody>
								</Table>
								<div className="border-bottom pt-2 mb-3" />

								{typeof this.props.removeNotice == 'undefined' ? <p className="text-muted text-center mb-0">
									Payment in full due in 14 days<br />
									If you have any questions concerning this invoice, contact {facility_data != null ? (facility_data.facility_email != null ? "<" + facility_data.facility_email + ">" : "") : ""}
								</p> : ""}
							</div> : ""}
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
	}
};
