import React, { Component, Fragment } from "react";
import { ToastProvider } from "react-toast-notifications";
import { Row } from "reactstrap";

import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import SideMenu from "../components/my-account/SideMenu";
import { Colxx } from "../components/common/CustomBootstrap";

class MyAccountLayout extends Component {
	constructor(props) {
		super(props);
	}
	
	componentDidMount() {
		document.body.classList.add("background");
	}
	
	componentWillUnmount() {
		document.body.classList.remove("background");
	}

  render() {
    return (
		<Fragment>
			<ToastProvider placement="bottom-left">
				<div className="main-pages-layout landing-root">
					<main className={'my-account'}>
						<HomeHeader />
						<div className="container-fluid px-5">
							<div className="py-5">
								<Row>
									<Colxx md="3">
										<SideMenu 
											active={'my-account'}
										/>
									</Colxx>
									<Colxx md="9" className="pl-0 pt-2">
										{this.props.children}
									</Colxx>
								</Row>
							</div>
						</div>
						<HomeFooter />
					</main>
				</div>
			</ToastProvider>
        </Fragment>
    );
  }
}

export default MyAccountLayout;
