import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import allcustomer from "./allcustomer";
import coupons from "./coupons";
import buyOne from "./buyOne";	
import customerPoints from "./customerPoints";
import customerPos from "./customerPos";
import customerReferrals from "./allReferals"
import customerTypes from"./allCustomerType"
const Customer = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>            
			<Route path={`${match.url}/all-customers`} component={allcustomer} />
			
			<Route path={`${match.url}/customer-pos`} component={customerPos} />
			<Route path={`${match.url}/coupons`} component={coupons} />	
			<Route path={`${match.url}/buy-one-get-offers`} component={buyOne} />
			<Route path={`${match.url}/customer-points`} component={customerPoints} />	
			<Route path={`${match.url}/customer-referrals`} component={customerReferrals} />	
			<Route path={`${match.url}/customer-type`} component={customerTypes} />	
			<Route path={`${match.url}`} component={allcustomer} />
			<Redirect to="/error" />
        </Switch>
    </div>
);
export default Customer;