import React, { Component,Fragment } from "react";
import { Row, FormGroup, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

export default class ProductGrid extends Component {
	constructor(props) {
		super();
		this.state = {
			search:''
		}
	}
	
	render() {
		const{ products, currency_rate, currency_symbol, index } = this.props;
		
		var final_products = products;		
		if (this.state.search) {
			final_products = final_products.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.barcode.includes(this.state.search)
			})
		}
		
		return (<Fragment>
				<div className="mb-2 p-2 product-item-search dropdown-edit">						
					<FormGroup row className="mb-0">
						<Colxx lg="12">
							<Input
								type="text"
								name={"product_search"+index}
								id={"product_search"+index}
								placeholder="Search"
								value={this.state.search}
								onChange={e => this.setState({search: e.target.value.toLowerCase()})}
							/>
						</Colxx>
					</FormGroup>
				</div>
				<PerfectScrollbar
				  option={{ suppressScrollX: true, wheelPropagation: false }}
				>
					<div className="d-flex flex-wrap">
					{final_products.map((row,index)=>{
						return(<Colxx md="3" xxl="2" className="text-center product-item" key={index} onClick={(e)=>{ this.props.toggleModalGetPackages(e,row.id); }}>
							<img src={row.thumbnail_url} className="product-thumnail img-responsive" />	
							<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
							{/* <p className="mb-0 text-danger font-weight-bold">{currency_symbol+" "+(parseFloat(row.product_unit_price*currency_rate).toFixed(2))}</p> */}
						</Colxx>)
					})}					
					</div>
				</PerfectScrollbar>
			</Fragment>);
	}
}
