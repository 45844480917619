import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import ShopSidebar from "../components/front-end/ShopSidebar";
import ShopTopbar from "../components/front-end/ShopTopbar";
import ShopProducts from "../components/front-end/ShopProducts";

import { REST_API_END_POINT, dataEncryptKey } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class Shop extends Component {

	constructor(props) {
		super(props);
		this.state = {
			sortType: 'asc',
			sortValue: 'default',
			category_id: '',
			search: '',
			productCount: 0,
			offset: 0,
			currentPage: 1,
			pageLimit: 9,
			currentData: [],
			uniqueCategories: [],
			coupons: []
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.getSortParams = this.getSortParams.bind(this);
		this.sortProducts = this.sortProducts.bind(this);
		this.setCategoryParams = this.setCategoryParams.bind(this);
		this.shopSearchSubmit = this.shopSearchSubmit.bind(this);
		this.setCurrentPage = this.setCurrentPage.bind(this);
		this.setOffset = this.setOffset.bind(this);
		seo({
			title: 'Shop | ' + projectTitle,
			metaDescription: 'Shop with us'
		});
	}

	componentDidMount() {

		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var user_id = localStorage.getItem('user_id');
		if (typeof user_id == 'undefined' || user_id == null) {
			localStorage.setItem('redirect_url', '/shop');
		}
		var facility_id = localStorage.getItem('facility_id');
		if (facility_id) {
			this.dataListRender();
		} else {
			window.location = '/';
		}
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}

	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		if (this.state.category_id !== '') {
			formData.append('category_id', this.state.category_id);
		}
		if (this.state.search !== '') {
			formData.append('search', this.state.search);
		}
		formData.append('pageLimit', this.state.pageLimit);
		formData.append('currentPage', this.state.currentPage);

		axios.post(REST_API_END_POINT + 'front-end/shop', formData).then((res) => {
			var data = res.data;

			if (data.status == 1) {
				var uniqueCategories = data.categories;
				this.setState({
					productCount: parseInt(data.product_count),
					currentData: data.products,
					uniqueCategories,
					coupons: data.discounts
				}, () => {
					this.sortProducts();
				});
			} else {
				this.setState({
					currentData: [],
					uniqueCategories: []
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	getSortParams = (sortType, sortValue) => {
		this.setState({ sortType, sortValue }, () => {
			this.sortProducts();
		})
	}

	sortProducts = () => {
		const sortValue = this.state.sortValue;
		var currentData = this.state.currentData;
		if (sortValue == 'default') {
			currentData = currentData.sort((a, b) => parseInt(a.timestamp) < parseInt(b.timestamp) ? 1 : (parseInt(a.timestamp) > parseInt(b.timestamp) ? -1 : 0))
		} else if (sortValue == 'priceLowToHigh') {
			currentData = currentData.sort((a, b) => parseFloat(a.price) > parseFloat(b.price) ? 1 : (parseFloat(a.price) < parseFloat(b.price) ? -1 : 0))
		} else if (sortValue == 'priceHighToLow') {
			currentData = currentData.sort((a, b) => parseFloat(a.price) < parseFloat(b.price) ? 1 : (parseFloat(a.price) > parseFloat(b.price) ? -1 : 0))
		}
		currentData = JSON.parse(JSON.stringify(currentData));
		this.setState({ currentData })
	}

	setCategoryParams = (category_id) => {
		this.setState({ category_id }, () => {
			this.dataListRender();
		})
	}

	shopSearchSubmit(event, errors, values) {
		if (errors.length == 0) {
			this.setState({ search: values.shop_search }, () => {
				this.dataListRender();
			})
		}
	}

	setCurrentPage(currentPage) {
		this.setState({ currentPage }, () => {
			this.dataListRender();
		})
	}

	setOffset(offset) {
		this.setState({ offset })
	}

	render() {

		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />

							<div className="shop-area pt-5 pb-5 main-shop-listing">

								<div className="container">

									<div className="row">
										<div className="col-lg-3 order-2 order-lg-1">
											{/* shop sidebar */}

											<ShopSidebar uniqueCategories={this.state.uniqueCategories} setCategoryParams={this.setCategoryParams} shopSearchSubmit={this.shopSearchSubmit} sideSpaceClass="mr-30" />
										</div>
										<div className="col-lg-9 order-1 order-lg-2">
											{/* shop topbar default */}

											<div className="row d-flex flex-nowrap">
												<h6 className="mr-2 ml-3">Group Discounts:</h6>
												{this.state.coupons?.map((row, i) => (
													<div key={i} className="d-flex align-items-center" style={{ marginRight: '10px' }}>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 14 15"
															width="14"
															height="15"
															fill="#cf369b"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M0 2.00482V7.17639C0 7.70683 0.210714 8.21554 0.585786 8.59061L6.58579 14.5906C7.36684 15.3717 8.63317 15.3717 9.41421 14.5906L14.5858 9.41903C15.3668 8.63799 15.3668 7.37166 14.5858 6.59061L8.58579 0.590608C8.21071 0.215535 7.70201 0.00482178 7.17157 0.00482178H2C0.895431 0.00482178 0 0.900252 0 2.00482ZM4 6.00482C5.10457 6.00482 6 5.10939 6 4.00482C6 2.90025 5.10457 2.00482 4 2.00482C2.89543 2.00482 2 2.90025 2 4.00482C2 5.10939 2.89543 6.00482 4 6.00482Z"
																fill="#cf369b"
															></path>
														</svg>
														<span style={{ marginLeft: '8px', color: '#cf369b' }}>{row.name}</span>
													</div>
												))}
											</div>

											{/* {this.state.coupons ? this.state.coupons : ""} */}
											<ShopTopbar getFilterSortParams={this.getSortParams} productCount={this.state.productCount} sortedProductCount={this.state.currentData.length} />

											{/* shop page content default */}
											<ShopProducts layout={'grid three-column'} products={this.state.currentData} />

											{/* shop product pagination */}
											{this.state.productCount > 9 ? <div className="pro-pagination-style text-center mt-30">
												<Paginator
													totalRecords={this.state.productCount}
													pageLimit={this.state.pageLimit}
													pageNeighbours={2}
													setOffset={this.setOffset}
													currentPage={this.state.currentPage}
													setCurrentPage={this.setCurrentPage}
													pageContainerClass="mb-0 mt-0"
													pagePrevText="«"
													pageNextText="»"
												/>
											</div> : ""}
										</div>
									</div>
								</div>
							</div>
							<HomeFooter />
						</main>
					</div>
				</ToastProvider>
			</Fragment>
		);
	}
}

const mapStateToProps = ({ authUser }) => {
	const { user, loading } = authUser;
	return { user, loading };
};

export default connect(
	mapStateToProps,
	{
		loginUser
	}
)(Shop);
