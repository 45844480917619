import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE } from "../../../constants/defaultValues";
import swal from "sweetalert";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
	{ label: "Active", value: "1", key: 0 },
	{ label: "Inactive", value: "0", key: 1 }
];

class EditTypeModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			statusType: { label: "Active", value: "1", key: 0 },
			typeCategory: null,
			tax: [],
			selectTax: [],
		};
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			if(this.state.selectTax.length==0){
				swal("Warning!", "Please choose tax category", "warning")
				return;
			}
			
			document.getElementById("update-type").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("typeForm"));
			formData.append('type_id', this.props.type_id);
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);

			axios.post(REST_API_END_POINT + 'customer/edit-customer-type', formData).then((res) => {
				var data = res.data;

				var typeCategory = this.state.typeCategory;
				typeCategory.name = values.name;
				this.setState({ typeCategory: typeCategory });

				var api_message = document.getElementById("api_message");
				api_message.innerHTML = "<div class=\"rounded alert alert-" + (data.status == 1 ? "success" : "danger") + " alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

				window.setTimeout(function () {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);

				JQUERY_MODULE.$('.alert .close').on("click", function (e) {
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				});

				document.getElementById("update-type").disabled = false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("update-type").disabled = false;
			});

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.modalOpen) {
			let typeCategory;
			if (prevProps.type_id !== this.props.type_id) {
				var formData = new FormData();
				formData.append('type_id', this.props.type_id);
				let tax = this.props.tax
				axios.post(REST_API_END_POINT + 'customer/get-customer-type-by-id', formData).then((res) => {
					var data = res.data;
					var status = null;
					typeCategory = data.result;
					let Ids = typeCategory.tax.split(',');
					let selectTax = tax.filter(row => { return Ids.indexOf(row.id) !== -1 });
					selectStatus.forEach((element, index) => {
						if (element.value == typeCategory.status) {
							status = element;
						}
					});
					this.setState({
						typeCategory,
						statusType: status,
						tax,
						selectTax
					});

				}).catch(e => {
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}

	render() {
		if (this.state.typeCategory != null) {
			var typeCategory = this.state.typeCategory;
		} else {
			var typeCategory;
		}
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Customer Type
				</ModalHeader>
				<AvForm
					id="typeForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Name<span className="text-danger">*</span></Label>
									<AvField
										id="cname"
										name="name"
										value={typeCategory ? ((typeCategory.name != null) ? typeCategory.name : '') : ''}
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											pattern: {
												value: "^[a-zA-Z0-9 ,.'-]+$",
												errorMessage: "Invalid name"
											},
											minLength: {
												value: 2,
												errorMessage:
													"Your name must be between 2 and 100 characters"
											},
											maxLength: {
												value: 100,
												errorMessage:
													"Your name must be between 2 and 100 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<AvGroup className="error-t-negative ">
							<Label>Tax<span className="text-danger">*</span> </Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								isMulti
								classNamePrefix="react-select"
								name="customer_tax[]"
								id="customer_tax"
								value={this.state.selectTax}
								options={this.state.tax}
								onChange={selectTax => { this.setState({ selectTax }) }}
							/>
						</AvGroup>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Status</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="status"
										id="status"
										value={this.state.statusType}
										options={selectStatus}
										onChange={statusType => { this.setState({ statusType }) }}

									/>
								</AvGroup>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="update-type" >Update</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditTypeModal;
