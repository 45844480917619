import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input, CustomInput, Label, Button, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import printJS from 'print-js'

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PrinterSettings extends Component {
	
	constructor(props) {
		super(props);
		this.state={
			print_product_label:{},
			print_customer_label:{},
			print_receipt:{},
			receipt_logo:null,
			print_product_label_reload:false,
			print_customer_label_reload:false,
			print_receipt_reload:false,
			facility_id:"6",
			live_sale:false,
			cart_preference:false
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.printCustomerLabel = this.printCustomerLabel.bind(this);
		this.printProductLabel = this.printProductLabel.bind(this);
		this.printReceipt = this.printReceipt.bind(this);
	}

	componentDidMount(){
		this.dataListRender();
	}
	
	dataListRender(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var user_id = localStorage.getItem('user_id');
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-printer-settings',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var print_product_label=data.product_label;	
				var print_customer_label=data.customer_label;	
				var print_receipt=data.receipt_label;	
				var receipt_logo=data.receipt_logo;	
				this.setState({
					print_product_label,
					print_customer_label,
					print_receipt,
					
					receipt_logo,
					data_loaded:true,
					facility_id
				});
			}else{
				this.setState({
					currencies:[],
					data_loaded:true,
					facility_id
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	printCustomerLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-customer-label/"+0+"/"+facility_id);
    }
	
	printProductLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-product-label/"+0+"/"+facility_id);
    }
	
	printReceipt(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-receipt/"+0+"/"+facility_id);
    }
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("settings-btn1").disabled=true;
			document.getElementById("settings-btn2").disabled=true;
			document.getElementById("settings-btn3").disabled=true;
			document.getElementById("settings-btn4").disabled=true;
			document.getElementById("settings-btn5").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData;
			if(values.setting_field=='print_receipt'){
				formData = new FormData(document.getElementById("print_receipt_form"));
			}else{
				formData = new FormData();
			}
			
			formData.append('setting_field',values.setting_field);
			formData.append('facility_id',facility_id);
			formData.append('setting_value',JSON.stringify(this.state[values.setting_field]));

			axios.post(REST_API_END_POINT+'point-of-sale/update-printer-settings',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("settings-btn1").disabled=false;
				document.getElementById("settings-btn2").disabled=false;
				document.getElementById("settings-btn3").disabled=false;
				document.getElementById("settings-btn4").disabled=false;
				document.getElementById("settings-btn5").disabled=false;
				if(data.status==1){
					let reload_print = {};
					reload_print[values.setting_field+'_reload']=true;
					this.setState({ ...reload_print },()=>{
						let reload_print = {};
						reload_print[values.setting_field+'_reload']=false;
						this.setState({ ...reload_print });
					});
					
					if(values.setting_field=='print_receipt'){
						this.dataListRender();
					}
					
					swal("Success",data.msg,"success");					
				}else{
					swal("Failed",data.msg,"warning");
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("settings-btn1").disabled=false;
				document.getElementById("settings-btn2").disabled=false;
				document.getElementById("settings-btn3").disabled=false;
				document.getElementById("settings-btn4").disabled=false;
				document.getElementById("settings-btn5").disabled=false;
			});

		}
	}
	
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	render(){
		const { print_product_label, print_customer_label, print_receipt, receipt_logo, facility_id } = this.state;
		return (
			<Fragment>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.printer-settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row className="mb-3">	
										<Colxx md="6">
											<h2>Product Label</h2>
											
											<AvForm
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
												<AvInput type="hidden" name="setting_field" value="print_product_label" />
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Dimensions (Inches)<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Width</InputGroupAddon>
																		<AvInput
																			name="dimension_width"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.dimension_width}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.dimension_width=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Height</InputGroupAddon>
																		<AvInput
																			name="dimension_height"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.dimension_height}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.dimension_height=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Margins (Inches)<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="6">
																	<InputGroup className="mb-2" >
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Top</InputGroupAddon>
																		<AvInput
																			name="margin_top"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.margin_top}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.margin_top=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Right</InputGroupAddon>
																		<AvInput
																			name="margin_right"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.margin_right}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.margin_right=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Bottom</InputGroupAddon>
																		<AvInput
																			name="margin_bottom"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.margin_bottom}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.margin_bottom=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Left</InputGroupAddon>
																		<AvInput
																			name="margin_left"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.margin_left}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.margin_left=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Font Size<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="12">
																	<InputGroup className="mb-2">																		
																		<AvInput
																			name="font_size"
																			type="number"
																			autoComplete="off"
																			value={print_product_label.font_size}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.font_size=e.target.value;
																				this.setState({ print_product_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																		<InputGroupAddon addonType="append" >pt</InputGroupAddon>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Label Info</Label>
															<Row>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.product_name}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.product_name=!print_product_label.product_name;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.store_name}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.store_name=!print_product_label.store_name;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.store_license}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.store_license=!print_product_label.store_license;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store License #</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.supplier_name}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.supplier_name=!print_product_label.supplier_name;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Supplier Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.supplier_license}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.supplier_license=!print_product_label.supplier_license;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Supplier License #</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.product_category}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.product_category=!print_product_label.product_category;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Category</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.product_price}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.product_price=!print_product_label.product_price;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Price</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_product_label.product_barcode}
																			onChange={(e)=>{
																				let print_product_label = this.state.print_product_label;
																				print_product_label.product_barcode=!print_product_label.product_barcode;
																				this.setState({ print_product_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Barcode</span>
																	</Label>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Disclaimer / Text</Label>
															<p className="text-small text-muted">Add state specific disclaimer or custom freeform text.</p>
															<Row>
																<Colxx lg="12">
																	<AvField
																		name="disclaimer_text"
																		type="textarea"
																		autoComplete="off"
																		value={print_product_label.disclaimer_text}
																		placeholder="Add state specific disclaimer or custom freeform text."
																		onChange={(e)=>{
																			let print_product_label = this.state.print_product_label;
																			print_product_label.disclaimer_text=e.target.value;
																			this.setState({ print_product_label });
																		}}
																		rows={4}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Button color="primary" id="settings-btn1" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
										<Colxx md="6" className="text-center">
											<Row className="mb-3">
												<Colxx lg="12" className="text-center">
													<Button color="primary" onClick={this.printProductLabel} >
														Print Sample Product Label
													</Button>
												</Colxx>
											</Row>
											{!this.state.print_product_label_reload ? <iframe src={REST_API_BASE_URL+"print-product-label/"+0+"/"+facility_id} style={{height:'90%',width:'320px'}} ></iframe>:""}
										</Colxx>
									</Row>
									<hr/>
									<Row className="mb-3">	
										<Colxx md="6">
											<h2>Customer Label</h2>
											<AvForm
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
												<AvInput type="hidden" name="setting_field" value="print_customer_label" />
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Dimensions (Inches)<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Width</InputGroupAddon>
																		<AvInput
																			name="dimension_width"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.dimension_width}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.dimension_width=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Height</InputGroupAddon>
																		<AvInput
																			name="dimension_height"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.dimension_height}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.dimension_height=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Margins (Inches)<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="6">
																	<InputGroup className="mb-2" >
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Top</InputGroupAddon>
																		<AvInput
																			name="margin_top"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.margin_top}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.margin_top=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Right</InputGroupAddon>
																		<AvInput
																			name="margin_right"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.margin_right}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.margin_right=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Bottom</InputGroupAddon>
																		<AvInput
																			name="margin_bottom"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.margin_bottom}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.margin_bottom=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Left</InputGroupAddon>
																		<AvInput
																			name="margin_left"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.margin_left}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.margin_left=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Font Size<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="12">
																	<InputGroup className="mb-2">																		
																		<AvInput
																			name="font_size"
																			type="number"
																			autoComplete="off"
																			value={print_customer_label.font_size}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.font_size=e.target.value;
																				this.setState({ print_customer_label });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				},
																				min: {
																					value: "0",
																					errorMessage: "The value should be greater than 0"
																				}
																			}}
																		/>
																		<InputGroupAddon addonType="append" >pt</InputGroupAddon>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Top Text</Label>
															<Row>
																<Colxx lg="12">
																	<AvField
																		name="top_text"
																		type="text"
																		autoComplete="off"
																		value={print_customer_label.top_text}
																		onChange={(e)=>{
																			let print_customer_label = this.state.print_customer_label;
																			print_customer_label.top_text=e.target.value;
																			this.setState({ print_customer_label });
																		}}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Label Info</Label>
															<Row>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.product_name}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.product_name=!print_customer_label.product_name;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.store_name}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.store_name=!print_customer_label.store_name;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.store_license}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.store_license=!print_customer_label.store_license;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store License #</span>
																	</Label>
																</Colxx>																
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.weight}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.weight=!print_customer_label.weight;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Weight</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.order_date}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.order_date=!print_customer_label.order_date;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Order Date</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.order_number}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.order_number=!print_customer_label.order_number;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Order Number</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.order_barcode}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.order_barcode=!print_customer_label.order_barcode;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Order Barcode</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.customer_name}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.customer_name=!print_customer_label.customer_name;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Customer Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.customer_license}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.customer_license=!print_customer_label.customer_license;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Customer License #</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.supplier_name}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.supplier_name=!print_customer_label.supplier_name;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Supplier Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.supplier_license}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.supplier_license=!print_customer_label.supplier_license;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Supplier License #</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.product_category}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.product_category=!print_customer_label.product_category;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Category</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.product_price}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.product_price=!print_customer_label.product_price;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Price</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_customer_label.product_barcode}
																			onChange={(e)=>{
																				let print_customer_label = this.state.print_customer_label;
																				print_customer_label.product_barcode=!print_customer_label.product_barcode;
																				this.setState({ print_customer_label });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Product Barcode</span>
																	</Label>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Disclaimer / Text</Label>
															<p className="text-small text-muted">Add state specific disclaimer or custom freeform text.</p>
															<Row>
																<Colxx lg="12">
																	<AvField
																		name="disclaimer_text"
																		type="textarea"
																		autoComplete="off"
																		value={print_customer_label.disclaimer_text}
																		placeholder="Add state specific disclaimer or custom freeform text."
																		onChange={(e)=>{
																			let print_customer_label = this.state.print_customer_label;
																			print_customer_label.disclaimer_text=e.target.value;
																			this.setState({ print_customer_label });
																		}}
																		rows={4}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Button color="primary" id="settings-btn2" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
										<Colxx md="6" className="text-center">
											<Row className="mb-3">
												<Colxx lg="12" className="text-center">
													<Button color="primary" onClick={this.printCustomerLabel} >
														Print Sample Customer Label
													</Button>
												</Colxx>
											</Row>
											{!this.state.print_customer_label_reload ? <iframe src={REST_API_BASE_URL+"print-customer-label/"+0+"/"+facility_id} style={{height:'90%',width:'320px'}} ></iframe>:""}
										</Colxx>
									</Row>
									<hr/>
									<Row className="mb-3">	
										<Colxx md="6">
											<h2>Receipt</h2>
											<AvForm
												id="print_receipt_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
												<AvInput type="hidden" name="setting_field" value="print_receipt" />
												<Row>
													<Colxx lg="12">
														<AvGroup>
															<Row>
																<Colxx lg="12">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.enable_logo}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.enable_logo=!print_receipt.enable_logo;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Enable Logo</span>
																	</Label>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												{print_receipt.enable_logo&& receipt_logo!=null ? <Row>
													<Colxx lg="12" >
														<div className="d-flex justify-content-start">
															<div className="text-center ">
																<img src={BASE_URL+'pages/'+receipt_logo} alt="Logo" className="img-responsive" />
															</div>
														</div>
													</Colxx>
												</Row>:""}
												
												{!this.state.print_receipt_reload && print_receipt.enable_logo ? <Row className="mt-2">
													<Colxx lg="12" >						
														<AvGroup className="error-l-125 error-t-negative">
															<Label>Logo</Label>
															<InputGroup className="mb-3">
															  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
															  <CustomInput
																type="file"
																name="thumbnail"
																accept="image/*"
																onChange={this.onChange}
															  />
															</InputGroup>
														</AvGroup>					
													</Colxx>
												</Row>:""}
												<Row>
													<Colxx lg="12">														
														<Label>Width<span className="text-danger">*</span></Label>
														<Row>
															<Colxx lg="12">																	
																<AvField
																	name="dimension_width"
																	type="select"
																	autoComplete="off"
																	value={print_receipt.dimension_width}
																	onChange={(e)=>{
																		let print_receipt = this.state.print_receipt;
																		print_receipt.dimension_width=e.target.value;
																		this.setState({ print_receipt });
																	}}
																	validate={{
																		required: {
																			value: true,
																			errorMessage: "This field is required."
																		}
																	}}
																>
																	<option value="55">2 1/4" (55mm)</option>
																	<option value="72">2 7/8" (72mm)</option>
																	<option value="80">3 1/8" (80mm)</option>
																</AvField>
															</Colxx>
														</Row>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Margins (Inches)<span className="text-danger">*</span></Label>
															<Row>
																<Colxx lg="6">
																	<InputGroup className="mb-2" >
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Top</InputGroupAddon>
																		<AvInput
																			name="margin_top"
																			type="number"
																			autoComplete="off"
																			value={print_receipt.margin_top}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.margin_top=e.target.value;
																				this.setState({ print_receipt });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Right</InputGroupAddon>
																		<AvInput
																			name="margin_right"
																			type="number"
																			autoComplete="off"
																			value={print_receipt.margin_right}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.margin_right=e.target.value;
																				this.setState({ print_receipt });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Bottom</InputGroupAddon>
																		<AvInput
																			name="margin_bottom"
																			type="number"
																			autoComplete="off"
																			value={print_receipt.margin_bottom}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.margin_bottom=e.target.value;
																				this.setState({ print_receipt });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
																<Colxx lg="6">
																	<InputGroup className="mb-2">
																		<InputGroupAddon addonType="prepend" className="printer-settings-left-addon">Left</InputGroupAddon>
																		<AvInput
																			name="margin_left"
																			type="number"
																			autoComplete="off"
																			value={print_receipt.margin_left}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.margin_left=e.target.value;
																				this.setState({ print_receipt });
																			}}
																			validate={{
																				required: {
																					value: true,
																					errorMessage: "This field is required."
																				}
																			}}
																		/>
																	</InputGroup>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Top Text</Label>
															<p className="text-small text-muted">Example: For medical use only in accordance with the law.</p>
															<Row>
																<Colxx lg="12">
																	<AvField
																		name="top_text"
																		type="textarea"
																		autoComplete="off"
																		value={print_receipt.top_text}
																		placeholder={"Example: For medical use only in accordance with the law."}
																		onChange={(e)=>{
																			let print_receipt = this.state.print_receipt;
																			print_receipt.top_text=e.target.value;
																			this.setState({ print_receipt });
																		}}
																		rows={3}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Store Info</Label>
															<Row>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_name}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_name=!print_receipt.store_name;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Name</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_license}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_license=!print_receipt.store_license;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store License #</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_address}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_address=!print_receipt.store_address;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Address</span>
																	</Label>
																</Colxx>
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_phone}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_phone=!print_receipt.store_phone;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Phone</span>
																	</Label>
																</Colxx>
																{/*<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_website}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_website=!print_receipt.store_website;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Website</span>
																	</Label>
																</Colxx>*/}
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.store_email_address}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.store_email_address=!print_receipt.store_email_address;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Store Email Address</span>
																	</Label>
																</Colxx>															
																<Colxx lg="6">
																	<Label>													  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.customer_details}
																			onChange={(e)=>{
																				let print_receipt = this.state.print_receipt;
																				print_receipt.customer_details=!print_receipt.customer_details;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4">Customer Details</span>
																	</Label>
																</Colxx>																
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															<Label>Bottom Text</Label>
															<p className="text-small text-muted">Example: Exchange or Refund within 24 hours. Some restrictions may apply.</p>
															<Row>
																<Colxx lg="12">
																	<AvField
																		name="bottom_text"
																		type="textarea"
																		autoComplete="off"
																		value={print_receipt.bottom_text}
																		placeholder="Example: Exchange or Refund within 24 hours. Some restrictions may apply."
																		onChange={(e)=>{
																			let print_receipt = this.state.print_receipt;
																			print_receipt.bottom_text=e.target.value;
																			this.setState({ print_receipt });
																		}}
																		rows={4}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Button color="primary" id="settings-btn3" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
										<Colxx md="6" className="text-center">
											<Row className="mb-3">
												<Colxx lg="12" className="text-center">
													<Button color="primary" onClick={this.printReceipt} >
														Print Sample Receipt
													</Button>
												</Colxx>
											</Row>
											{!this.state.print_receipt_reload ? <iframe src={REST_API_BASE_URL+"print-receipt/"+0+"/"+facility_id} style={{height:'90%',width:'320px'}} ></iframe>:""}
										</Colxx>
									</Row>	
									<hr/>	
									<Row className="mb-3">	
										<Colxx md="6">
											<h2>Cart</h2>
											<AvForm
												id="print_receipt_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
											<AvInput type="hidden" name="setting_field" value="print_receipt" />	
												<Row>
													<Colxx lg="12">
														<AvGroup>
															<Row>
																<Colxx lg="12">
																	<Label>		
																												  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.cart_preference}
																			onChange={(e)=>{
																				let cart_preference = this.state.cart_preference;
																				print_receipt.cart_preference=!print_receipt.cart_preference;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4"> Minimum of $1 - $0.01 </span>
																	</Label>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												
												<Button color="primary" id="settings-btn4" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
										
									</Row>	
									<hr/>	
									<Row className="mb-3">	
										<Colxx md="6">
											<h2>Track & Trace</h2>
											<AvForm
												id="print_receipt_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
											<AvInput type="hidden" name="setting_field" value="print_receipt" />	
												<Row>
													<Colxx lg="12">
														<AvGroup>
															<Row>
																<Colxx lg="12">
																	<Label>		
																												  
																		<Switch
																			className="custom-switch custom-switch-primary im-inline-block"
																			checked={print_receipt.live_sale}
																			onChange={(e)=>{
																				let live_sale = this.state.live_sale;
																				print_receipt.live_sale=!print_receipt.live_sale;
																				this.setState({ print_receipt });
																			}}
																			
																		/>
																		<span className="h6 ml-4"> Live Sale </span>
																	</Label>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Button color="primary" id="settings-btn5" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
										
									</Row>								
								</CardBody>
							</Card>
						</Colxx>
					</Row>
			</Fragment>
		);
	}
}
