import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import MyAccountLayout from "../../layout/MyAccountLayout";

import allOrders from "./all-orders";
import editProfile from "./edit-profile";
import changePassword from "./change-password";

class MyAccount extends Component {
  render() {
    const { match } = this.props;

    return (  
		<MyAccountLayout>
			<Switch>
				<Route path={`${match.url}/edit-profile`} component={editProfile} />
				<Route path={`${match.url}/change-password`} component={changePassword} />
				<Route path={`${match.url}`} component={allOrders} />
				<Redirect to="/error" />
			</Switch>
        </MyAccountLayout>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(MyAccount)
);
