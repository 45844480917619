import React,{ useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutUser } from "../../redux/actions";

const SideMenu = (props) => {
	
	const handleLogout = () => {
		props.logoutUser(props.history);
	};
	
	const [active, setActive] = useState('my-account');
	
	useEffect(()=>{
		let activeLink = window.location.pathname.split('/').pop();
		setActive(activeLink);
	},[])

	return (
		<ul className="my-account-side-menu">
			<li className={active=="my-account" ? "active":""} onClick={()=>{ setActive("my-account") }}><NavLink to="/my-account" >All Orders</NavLink></li>
			<li className={active=="edit-profile" ? "active":""} onClick={()=>{ setActive("edit-profile") }}><NavLink to="/my-account/edit-profile" >Edit Profile</NavLink></li>
			<li className={active=="change-password" ? "active":""} onClick={()=>{ setActive("change-password") }}><NavLink to="/my-account/change-password" >Change Password</NavLink></li>
			<li className={active=="logout" ? "active":""} ><NavLink to="/my-account" onClick={handleLogout} >Logout</NavLink></li>
		</ul>
    );
}

SideMenu.propTypes = {
  logoutUser: PropTypes.func,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: history => {
      dispatch(logoutUser(history));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
