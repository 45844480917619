import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
export default class DebitPaymentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			card_name:"",
			card_number:"",
			total_due:0,
			pinValidate:true,
			user_pin:this.props.user_pin,
			drawer_id: "",
			pinEntered: "",
		};	
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var card_name = this.state.card_name;
		var card_number = this.state.card_number;
		
		if(payment_data!=null){
			card_name = payment_data['card_name'];
			card_number = payment_data['card_number'];
		}
		this.setState({	card_name,card_number, total_due:this.props.total_due+payment_data['debit'] });
	}
	handleInputChange(event) {


		// var user_pin=this.state.user_pin?this.state.user_pin:""
		var user_pins = this.state.drawer_id ? this.state.drawer_id.user_pins : ""
		console.log('Nush123', this.state.drawer_id);
		let userPinsArray = user_pins.split(',');
		let isPinValid = userPinsArray.includes(event.target.value);
		if (isPinValid) {
			this.setState({ pinValidate: false })
		}
		else {
			this.setState({ pinValidate: true })
		}
		this.setState({ pinEntered: event.target.value })

	}
	handleSelect(drawer_id) {
		let pin = this.state.pinEntered;
		console.log('#####nush',drawer_id);
		
		this.setState({ drawer_id: drawer_id })
		let user_pins = drawer_id.user_pins
		let userPinsArray = user_pins.split(',');
		let isPinValid = userPinsArray.includes(pin);
		if (isPinValid) {
			this.setState({ pinValidate: false })
		}
		else {
			this.setState({ pinValidate: true })
		}
	}
	resetCard = ()=>{
		this.setState({	card_name:"",card_number:"" });
		var payment_data = this.props.payment_data;
		payment_data['card_name'] = "";
		payment_data['card_number'] = "";
		payment_data['debit'] = 0;
		
		this.props.updatePaymentData(payment_data);
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			var payment_data = this.props.payment_data;
			var card_name = this.state.card_name;
			var card_number = this.state.card_number;
			
			document.getElementById("debit-form-btn").disabled=true;
			
			payment_data['card_name'] = card_name;
			payment_data['card_number'] = card_number;
			payment_data['debit'] = this.props.total_due>0 ? this.props.total_due:0; 
			if(this.state.drawer_id){
				payment_data['drawer_id']=this.state.drawer_id
				payment_data['pin']=this.state.pinEntered
			}
			this.props.updatePaymentData(payment_data);
			this.props.toggleModal();
		}
	}

	render() {
		
		const total_due = this.state.total_due;
		return (
	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Debit Payment
				</ModalHeader>
				<AvForm
					id="debitForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
				<Row className="mt-4">
							<Colxx  lg="12">
							
								<Label>Choose Drawer<span className="text-danger">*</span></Label>
						
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="customer_refer_from"
									id="customer_refer_from"
									value={this.state.drawer_id}
									options={this.props.drawers}
									// onChange={drawer_id => { this.setState({ drawer_id: drawer_id }) }}
									onChange={drawer_id => { this.handleSelect(drawer_id) }}
								/>
						</Colxx>
						</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup>
								<Label>Card Name<span className="text-danger">*</span></Label>
								<AvField
									id="card_name"
									name="card_name"
									type="text"
									value={this.state.card_name}
									onChange={(e)=>{ this.setState({ card_name:e.target.value }) }}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the card name"
										},										
										pattern: {
											value: "^[a-zA-Z ,.'-]+$",
											errorMessage: "Invalid card name"
										}
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup >
								<Label>Card Number<span className="text-danger">*</span></Label>
								<AvField
									id="card_number"
									name="card_number"
									type="number"
									value={this.state.card_number}
									onChange={(e)=>{ this.setState({ card_number:e.target.value }) }}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the card number"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx xxs="12">
							<AvGroup >
								<Label>Total Due</Label>
								<AvField
									id="total_due"
									name="total_due"
									type="text"
									readOnly={true}
									placeholder="0"
									value={total_due.toFixed(2)}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx xxs="12">
							<AvGroup >
								<Label>Pin</Label>
								<AvField
									id="pin"
									name="pin"
									type="password"
									style={{  borderColor: this.state.pinValidate ? 'red' : 'green' }}
									// readOnly={true}
									placeholder="pin"
									value=""
									onChange={this.handleInputChange}
								/>
							</AvGroup>
						</Colxx>
					</Row>
								
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="primary" id="debit-form-btn" disabled={this.state.pinValidate} >
						{/* <IntlMessages id="customer.submit" /> */}
						Checkout
					</Button>
					<Button color="primary" outline onClick={this.resetCard}>
						<IntlMessages id="customer.reset" />
					</Button>{" "}
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};
