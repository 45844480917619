import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	InputGroup,
	InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
//import DatePicker from "react-datepicker";
import DatePicker from "../../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import moment from 'moment';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ViewFileModal from "../../../components/common/ViewFileModal";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectData = [
	{ label: "Normal", value: "1", key: 0 },
	{ label: "Veterans", value: "2", key: 1 },
	{ label: "Senior Citizens", value: "3", key: 2 },
	{ label: "Student", value: "4", key: 3 },
	{ label: "Inbound Customer", value: "5", key: 4 }
];
const referFrom = [
	{ label: "weedmaps", value: "1", key: 0 },
	{ label: "friend", value: "2", key: 1 },
	{ label: "Google", value: "3", key: 2 },
	{ label: "Facebook", value: "4", key: 3 },
];

class EditCustomerModal extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleModalViewDoc = this.toggleModalViewDoc.bind(this);
		this.state = {
			startTime: null,
			startDate: null,
			expirationDate: null,
			issueDate: null,
			careDob: null,
			customer: null,
			licenseExpirationDate: null,
			stateExpirationDate: null,
			selectStateType: null,
			mmjCardExpDate: null,
			customer_type: { label: "Normal", value: "1", key: 0 },
			customer_refer: { label: "weedmaps", value: "1", key: 0 },
			is_member_yes_value: "1",
			is_member_no_value: "0",
			is_member_yes: true,
			is_member_no: false,
			tax_exempt_yes_value: "1",
			tax_exempt_no_value: "2",
			tax_exempt_yes: false,
			tax_emempt_no: true,
			values: null,
			doc_url: null,
			is_member: false,
			is_resident: false,
			is_senior: false,
			is_veteran: false,
			is_disabled: false,
			tax_exempt: false,
			loyalty_membership: false,
			is_employee: false,
			is_student: false,
			is_temporary_patient: false,
			stateOptions: [],
			verified: false,
			stateChange: this.props.stateChange,
			customer_referral: [],
			customerTypes: [],
			selectTax: [],
			Tax: []
		};

	}

	componentDidMount() {
		var stateOptions = [];
		var customer_referral = [];
		var customerTypes = [];
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'wholesale/get-data-for-add-vendor', formData).then((res) => {
			var data = res.data;

			if (data.partner_types) {
				stateOptions = data.states;
				let selectStateType = null;
				let tax = data.tax_categories
				stateOptions = stateOptions.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				if (stateOptions.length) {
					selectStateType = stateOptions[0];
				}
				customer_referral = data.customer_referrals
				customer_referral = customer_referral.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				tax = tax.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				customerTypes = data.customer_types
				customerTypes = customerTypes.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				this.setState({
					stateOptions,
					selectStateType,
					customer_referral,
					customerTypes,
					tax
				});
			} else {
				this.setState({
					stateOptions,
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	toggleModalViewDoc = (e, doc_url = null) => {
		this.setState({
			modalOpenViewDoc: !this.state.modalOpenViewDoc,
			doc_url
		});
	};

	handleChangeLicenseExpirationDate = date => {
		this.setState({
			licenseExpirationDate: date
		});
	};
	handleChangeStateExpirationDate = date => {
		this.setState({
			stateExpirationDate: date
		});
	};

	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
		let minorAge = moment().subtract(18, "years");
		if (moment(date).isAfter(minorAge)) {
			this.setState({caregiver:true})
			
		
		} else {
			this.setState({caregiver:false})
		}
	};

	handleExpirationDate = date => {
		this.setState({
			expirationDate: date
		});
	};
	handleIssueDate = date => {
		this.setState({
			issueDate: date
		});
	};
	handleCareDob = date => {
		this.setState({
			careDob: date
		});
	};

	handleMMJCardExpDate = date => {
		this.setState({
			mmjCardExpDate: date
		});
	};

	handleChangeTime = time => {
		this.setState({
			startTime: time
		});
	};

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			let dateOfBirth = document.getElementById("date_of_birth").value.trim();
			// let expiryDate = document.getElementById("exp_date").value.trim();
			// let caregiverDateOfBirth = document.getElementById("caregiver_date_of_birth").value.trim();
			// let mmjCardExpiryDate = document.getElementById("caregiver_exp_date").value.trim();
			let errorMessage = '';

			if (dateOfBirth.length == 0) {
				errorMessage += "Date of Birth is empty\n";
			}


			// if(this.state.expirationDate==null){
			// 	swal("Warning", "Please choose Medical card expiration Date ", "warning");
			// 	return false;
			// }


			/*if(caregiverDateOfBirth.length==0)
			{
				errorMessage+="Caregiver Date of Birth is empty\n";
			}*/

			if (errorMessage.length != 0) {
				swal("Warning", errorMessage, "warning");
				return false;
			}

			document.getElementById("update-customer").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("customerForm"));
			formData.append('customer_id', this.props.customer_id);
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);

			formData.append('is_member', this.state.is_member ? 1 : 0);
			formData.append('is_resident', this.state.is_resident ? 1 : 0);
			formData.append('is_senior', this.state.is_senior ? 1 : 0);
			formData.append('is_veteran', this.state.is_veteran ? 1 : 0);
			formData.append('is_disabled', this.state.is_disabled ? 1 : 0);
			formData.append('loyalty_membership', this.state.loyalty_membership ? 1 : 0);
			formData.append('tax_exempt', this.state.tax_exempt ? 1 : 0);
			formData.append('is_employee', this.state.is_employee ? 1 : 0);
			formData.append('is_student', this.state.is_student ? 1 : 0);
			formData.append('is_temporary_patient', this.state.is_temporary_patient ? 1 : 0);
			formData.append('verified', this.state.verified ? 1 : 0);
			axios.post(REST_API_END_POINT + 'customer/edit-customer', formData).then((res) => {
				var data = res.data;

				var api_message = document.getElementById("api_message");
				if (api_message) {
					api_message.innerHTML = "<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";
				}
				window.setTimeout(function () {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);

				JQUERY_MODULE.$('.alert .close').off("click", function () { }).on("click", function (e) {
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				});

				document.getElementById("update-customer").disabled = false;

				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("update-customer").disabled = false;
			});

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {


		if (this.props.modalOpen) {



			if ((prevProps.customer_id !== this.props.customer_id && this.props.customer_id != 0) || (this.props.customer_id != 0 && this.state.stateChange == true)) {
				var formData = new FormData();
				formData.append('customer_id', this.props.customer_id);
				axios.post(REST_API_END_POINT + 'customer/get-customer-by-id', formData).then((res) => {

					let customer = res.data.customer;
					let customer_type = { label: "Normal", value: "1", key: 0 };
					let customer_refer = { label: "weedmaps", value: "1", key: 0 }
					let is_member_yes = true;
					let is_member_no = false;
					let tax_exempt_yes = true;
					let tax_exempt_no = false;
					let verified = false;
					let is_member = false, is_resident = false, is_senior = false, is_veteran = false, is_disabled = false, tax_exempt = false, is_employee = false, is_student = false, is_temporary_patient = false, loyalty_membership = false;
					let caregiver = false
					let selectTax = []
					let stateExpirationDate = customer.state_id_exp_license != '0000-00-00' ? moment(customer.state_id_exp_license, 'YYYY-MM-DD') : null
					console.log("!!!!!!!!!!!!!!!", stateExpirationDate);
					let dob = customer ? ((customer.date_of_birth) ? moment(customer.date_of_birth, 'YYYY-MM-DD') : null) : null
					if (dob != null) {
						let minorAge = moment().subtract(18, "years");
						if (moment(dob).isAfter(minorAge)) {
							caregiver=true;
						} else {
							caregiver=false;
						}
					}
					this.state.customerTypes.forEach((element, index) => {
						if (element.value == customer.customer_type) {
							customer_type = element
						}
					});
					let Ids = customer.tax.split(',');
					selectTax = this.state.tax.filter(row => { return Ids.indexOf(row.id) !== -1 });
					this.state.customer_referral.forEach((element, index) => {
						if (element.value == customer.customer_refer_from) {
							customer_refer = element
						}
					});
					let selectStateType = this.state.stateOptions.find(r => { return r.id == customer.vendor_state });
					let stateChange = false;
					is_member = customer.is_member == 1;
					is_resident = customer.is_resident == 1;
					is_senior = customer.is_senior == 1;
					is_veteran = customer.is_veteran == 1;
					is_disabled = customer.is_disabled == 1;
					loyalty_membership = customer.loyalty_membership == 1;
					tax_exempt = customer.tax_exempt == 1;
					is_employee = customer.is_employee == 1;
					is_student = customer.is_student == 1;
					is_temporary_patient = customer.is_temporary_patient == 1;
					verified = customer.omma_verified == 1;


					this.setState({ customer,caregiver, stateChange, selectTax, customer_type, customer_refer, stateChange, selectStateType, verified, is_member, is_resident, is_senior, is_veteran, is_disabled, tax_exempt, is_employee, is_student, is_temporary_patient, loyalty_membership, stateExpirationDate });

				}).catch(e => {
					console.log("Addition failed , Error ", e);
				});

			}
		}
	}

	onChange = e => {
		switch (e.target.name) {
			case 'license_img':
				if (e.target.files[0]) {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			case 'caregiver_license_img':
				if (e.target.files[0]) {

					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};

	handleVerifyClick = () => {
		window.open('https://omma.us.thentiacloud.net/webs/omma/register/#', '_blank');
	}
	render() {
		if (this.state.customer != null) {
			var customer = this.state.customer;
		} else {
			var customer;
		}

		var customer_type = [];
		const { stateOptions } = this.state;

		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Customer
				</ModalHeader>
				<AvForm
					id="customerForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">BASIC INFO</h4>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.name" /><span className="text-danger">*</span></Label>
									<AvField
										id="customer_name"
										name="customer_name"
										type="text"
										value={customer ? ((customer.vendor_name != null) ? customer.vendor_name : '') : ''}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the first name"
											},
											pattern: {
												value: "^[a-zA-Z ,.'-]+$",
												errorMessage: "Invalid first name"
											},
											minLength: {
												value: 2,
												errorMessage:
													"Your first name must be between 2 and 100 characters"
											},
											maxLength: {
												value: 100,
												errorMessage:
													"Your first name must be between 2 and 100 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
									<DatePicker
										id="date_of_birth"
										name="date_of_birth"
										className=""
										autoComplete="off"
										selected={(this.state.startDate) ? this.state.startDate : (customer ? ((customer.date_of_birth) ? moment(customer.date_of_birth, 'YYYY-MM-DD') : null) : null)}
										onChange={this.handleChangeDate}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Gender</Label>
									<AvRadioGroup
										inline={true}
										name="gender"
										value={customer ? ((customer.gender != null) ? customer.gender : 'M') : 'M'}
										validate={{
											required: {
												value: true,
												errorMessage: "Please select any of the options"
											}
										}}
									>
										<AvRadio customInput label="Male" value="M" />
										<AvRadio customInput label="Female" value="F" />
										<AvRadio customInput label="Non-Binary" value="N" />
										<AvRadio customInput label="Rather Not say" value="O" />
									</AvRadioGroup>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Drivers License Number</Label>
									<AvField
										id="license"
										name="license"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.license != null) ? customer.license : '') : ''}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<Label>Drivers License Expiration Date</Label>
								<DatePicker
									id="license_exp"
									name="license_exp"
									autoComplete="off"
									className="mb-2"
									selected={(this.state.licenseExpirationDate) ? this.state.licenseExpirationDate : (customer ? ((customer.license_exp) ? moment(customer.license_exp, 'YYYY-MM-DD') : null) : null)}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
									onChange={this.handleChangeLicenseExpirationDate}
								/>
							</Colxx>
						</Row>
						<AvGroup className="error-l-125 error-t-negative d-none">
							<Label><IntlMessages id="customer.license" /></Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="license_img"
									id="license_img"
									accept="image/*"
									onChange={this.onChange}
								/>
							</InputGroup>
						</AvGroup>
						{customer ? ((customer.license_img != null) ? <Row>
							<Colxx lg="12" className="mb-2">
								<table role="presentation" className="table table-striped">
									<tbody className="files">
										<tr className="template-download" >
											<td>
												<p className="name mb-0"><span className="open-document btn-link cursor-pointer" onClick={(e) => { this.toggleModalViewDoc(e, BASE_URL + 'vendors/' + customer.license_img) }}>{customer.license_img}</span></p>
											</td>
										</tr>
									</tbody>
								</table>
							</Colxx>
						</Row> : "") : ""}

						<Row>
							<Colxx lg="6">
								<AvGroup className="error-t-negative ">
									<Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
									<AvField
										id="phone"
										name="phone"
										type="phone"
										value={customer ? ((customer.phone != null) ? customer.phone : '') : ''}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											pattern: {
												value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
												errorMessage: "Invalid phone number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.email" /></Label>
									<AvField
										id="email"
										name="email"
										type="email"
										value={customer ? ((customer.email_id != null) ? customer.email_id : '') : ''}
										validate={{
											// required: {
											// 	value: true,
											// 	errorMessage: "Please enter the email address"
											// },
											email: {
												value: true,
												errorMessage: "Please enter a valid email address"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>

						<AvGroup className="error-t-negative ">
							<Label><IntlMessages id="customer.notes" /></Label>	&nbsp;&nbsp;
							<AvField
								id="designation_notes"
								name="designation_notes"
								type="textarea"
								value={customer ? ((customer.designation_notes != null) ? customer.designation_notes : '') : ''}
								placeholder=""
								rows="3"
								validate={{
									minLength: {
										value: 10,
										errorMessage:
											"Your message must have minimum of 10 characters"
									},
								}}
							/>
						</AvGroup>
						<hr />
						<Row>
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">MEDICAL INFO</h4>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Med ID<span className="text-danger">*</span></Label>
									<AvField
										id="mmmj_number"
										name="mmmj_number"
										type="text"
										value={customer ? ((customer.mmmj_number != null) ? customer.mmmj_number : '') : ''}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6 d-none">
								<AvGroup className="error-t-negative ">
									<Label>Med License Issue Date<span className="text-danger"></span></Label>
									<DatePicker
										id="issue_date"
										name="issue_date"
										autoComplete="off"
										//  value={ customer?((customer.issue_date!=null)?moment(customer.issue_date,'YYYY-MM-DD'):null):null }
										selected={(this.state.issueDate) ? this.state.issueDate : (customer ? ((customer.issue_date != "0000-00-00" || null) ? moment(customer.issue_date, 'YYYY-MM-DD') : null) : null)}
										onChange={this.handleIssueDate}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
										//  value={ customer?((customer.issue_date!=null)?customer.issue_date:''):'' }
										validate={{
											required: {
												value: false,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Med Card Expiration<span className="text-danger">*</span></Label>
									<DatePicker
										id="exp_date"
										name="exp_date"
										autoComplete="off"
										// value={ customer?((customer.exp_date!=null)?moment(customer.exp_date,'YYYY-MM-DD'):null):null }
										selected={(this.state.expirationDate) ? this.state.expirationDate : (customer ? ((customer.exp_date != null) ? moment(customer.exp_date, 'YYYY-MM-DD') : null) : null)}
										minDate={new Date()}
										onChange={this.handleExpirationDate}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
										//  value={ customer?((customer.exp_date!=null)?customer.exp_date:''):'' }
										validate={{
											required: {
												value: false,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Plant Count<span className="text-danger"></span></Label>
									<AvField
										id="recommended_plants"
										name="recommended_plants"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.recommended_plants != null) ? customer.recommended_plants : '') : ''}
										validate={{
											required: {
												value: false,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Gram Limit<span className="text-danger"></span></Label>
									<AvField
										id="smokable_quantity"
										name="smokable_quantity"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.smokable_quantity != null) ? customer.smokable_quantity : '') : ''}
										validate={{
											required: {
												value: false,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="12">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Physician  Name</Label>
									<AvField
										id="patient_physician"
										name="patient_physician"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.patient_physician != null) ? customer.patient_physician : '') : ''}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6 ">
								<Button color="primary" className="mt-4" onClick={this.handleVerifyClick}>
									Verify With OMMA
								</Button>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block mt-4"
										checked={this.state.verified}
										onChange={status => { this.setState({ verified: !this.state.verified }) }}
									/>
									<span className="h6 ml-4">Verified</span>
								</Label>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="mb-2 d-none">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_temporary_patient}
										onChange={status => { this.setState({ is_temporary_patient: !this.state.is_temporary_patient }) }}
									/>
									<span className="h6 ml-4">Temporary Patient</span>
								</Label>
							</Colxx>
						</Row>

						<Row>
							<Colxx lg="6 d-none">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Condition</Label>
									<AvField
										id="patient_condition"
										name="patient_condition"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.patient_condition != null) ? customer.patient_condition : '') : ''}
									/>
								</AvGroup>
							</Colxx>

						</Row>
						<hr />

						<Row>
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">ADDITIONAL INFO</h4>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>Street</Label>
									<AvField
										id="customer_address"
										name="customer_address"
										value={customer ? ((customer.vendor_address != null) ? customer.vendor_address : '') : ''}
										type="text"
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>City</Label>
									<AvField
										id="vendor_city"
										name="vendor_city"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.vendor_city != null) ? customer.vendor_city : '') : ''}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<Label >State</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="vendor_state"
									id="vendor_state"
									value={this.state.selectStateType}
									options={stateOptions}
									onChange={selectStateType => { this.setState({ selectStateType }) }}
								/>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.zip" /></Label>
									<AvField
										id="zip_code"
										name="zip_code"
										type="text"
										min='0'
										value={customer ? ((customer.zip_code != null) ? customer.zip_code : '') : ''}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						{console.log("################", customer ? ((customer.state_id_license != null) ? customer.state_id_license : '') : '')}
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label> State ID <span className="text-danger">*</span></Label>
									<AvField
										id="state_id_license"
										name="state_id_license"
										type="text"
										autoComplete="off"
										value={customer ? ((customer.state_id_license != null) ? customer.state_id_license : '') : ''}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							{console.log('$###############&', this.state.stateExpirationDate)
							}
							<Colxx lg="6">
								<Label> State ID Expiration</Label>
								<DatePicker
									id="state_id_exp_license"
									name="state_id_exp_license"
									autoComplete="off"
									className="mb-2"
									// selected={(this.state.stateExpirationDate) ? this.state.stateExpirationDate : (customer ? ((customer.state_id_exp_license) ? moment(customer.state_id_exp_license, 'YYYY-MM-DD') : null) : null)}
									selected={this.state.stateExpirationDate ? this.state.stateExpirationDate : null}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
									onChange={this.handleChangeStateExpirationDate}
								/>
							</Colxx>

						</Row>
						<hr />
						<Row>
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">STATUS</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_member}
										onChange={status => { this.setState({ is_member: !this.state.is_member }) }}
									/>
									<span className="h6 ml-4">Medical Member</span>
								</Label>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_resident}
										onChange={status => { this.setState({ is_resident: !this.state.is_resident }) }}
									/>
									<span className="h6 ml-4">Resident</span>
								</Label>
							</Colxx>
						</Row>

						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_veteran}
										onChange={status => { this.setState({ is_veteran: !this.state.is_veteran }) }}
									/>
									<span className="h6 ml-4">Veteran</span>
								</Label>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_senior}
										onChange={status => { this.setState({ is_senior: !this.state.is_senior }) }}
									/>
									<span className="h6 ml-4">Senior</span>
								</Label>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_disabled}
										onChange={status => { this.setState({ is_disabled: !this.state.is_disabled }) }}
									/>
									<span className="h6 ml-4">Disabled</span>
								</Label>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.tax_exempt}
										onChange={status => { this.setState({ tax_exempt: !this.state.tax_exempt }) }}
									/>
									<span className="h6 ml-4">Tax Exempt</span>
								</Label>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_employee}
										onChange={status => { this.setState({ is_employee: !this.state.is_employee }) }}
									/>
									<span className="h6 ml-4">Employee</span>
								</Label>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_student}
										onChange={status => { this.setState({ is_student: !this.state.is_student }) }}
									/>
									<span className="h6 ml-4">Student</span>
								</Label>
							</Colxx>
						</Row>
						<AvGroup className="error-t-negative d-none ">
							<Label>Tax </Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								isMulti
								classNamePrefix="react-select"
								name="customer_tax[]"
								id="customer_tax"
								value={this.state.selectTax}
								options={this.state.tax}
								onChange={selectTax => { this.setState({ selectTax }) }}
							/>
						</AvGroup>
						<Row>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.tax_exempt}
										onChange={status => { this.setState({ tax_exempt: !this.state.tax_exempt }) }}
									/>
									<span className="h6 ml-4">Tax Exempt</span>
								</Label>
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<Label>
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.loyalty_membership}
										onChange={status => { this.setState({ loyalty_membership: !this.state.loyalty_membership }) }}
									/>
									<span className="h6 ml-4">Sign Up For Loyalty Membership</span>
								</Label>
							</Colxx>
						</Row>
						<hr />
						<AvGroup className="error-t-negative ">
							<Label>How were you referred? </Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="customer_refer_from"
								id="customer_refer_from"
								value={this.state.customer_refer}
								options={this.state.customer_referral}
								onChange={customer_refer => { this.setState({ customer_refer: customer_refer }) }}
							/>
						</AvGroup>

						{this.state.caregiver?<>
						<Row className="">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.caregiver" /></Label>
									<AvField
										id="caregiver_name"
										name="caregiver_name"
										type="text"
										value={customer ? ((customer.caregiver_name != null) ? customer.caregiver_name : '') : ''}
										validate={{
											pattern: {
												value: "^[a-zA-Z ,.'-]+$",
												errorMessage: "Invalid caregiver name"
											},
											minLength: {
												value: 2,
												errorMessage:
													"Your caregiver name must be between 2 and 100 characters"
											},
											maxLength: {
												value: 100,
												errorMessage:
													"Your caregiver name must be between 2 and 100 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<Label><IntlMessages id="customer.caredob" /><span className="text-danger">*</span></Label>
								<DatePicker
									id="caregiver_date_of_birth"
									name="caregiver_date_of_birth"
									className="mb-5"
									selected={(this.state.careDob) ? this.state.careDob : (customer ? ((customer.caregiver_date_of_birth != null) ? moment(customer.caregiver_date_of_birth, 'YYYY-MM-DD') : null) : null)}
									onChange={this.handleCareDob}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
								/>
							</Colxx>
						</Row>
						<Row className="">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.caremmjno" /></Label>
									<AvField
										id="caregiver_mmmj_number"
										name="caregiver_mmmj_number"
										type="text"
										value={customer ? ((customer.caregiver_mmmj_number != null) ? customer.caregiver_mmmj_number : '') : ''}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.expdatemmjcard" /></Label>
									<DatePicker
										id="caregiver_exp_date"
										name="caregiver_exp_date"
										selected={(this.state.mmjCardExpDate) ? this.state.mmjCardExpDate : customer ? ((customer.caregiver_exp_date != null) ? moment(customer.caregiver_exp_date, 'YYYY-MM-DD') : null) : null}
										onChange={this.handleMMJCardExpDate}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
										value={customer ? ((customer.caregiver_exp_date != null) ? customer.caregiver_exp_date : '') : ''}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						</>:""}
						<AvGroup className="error-l-125 error-t-negative d-none">
							<Label><IntlMessages id="customer.caregiverLicense" /></Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="caregiver_license_img"
									id="caregiver_license_img"
									accept="image/*"
									onChange={this.onChange}
								/>
							</InputGroup>
						</AvGroup>
						{customer ? ((customer.caregiver_license_img != null) ? <Row>
							<Colxx lg="12" className="mb-2">
								<table role="presentation" className="table table-striped">
									<tbody className="files">
										<tr className="template-download" >
											<td>
												<p className="name mb-0"><span className="open-document btn-link cursor-pointer" onClick={(e) => { this.toggleModalViewDoc(e, BASE_URL + 'vendors/' + customer.caregiver_license_img) }}>{customer.caregiver_license_img}</span></p>
											</td>
										</tr>
									</tbody>
								</table>
							</Colxx>
						</Row> : "") : ""}
						{/* <hr /> */}
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">OTHER</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.employeeNumber" /></Label>
									<AvField
										id="employee_agent_number"
										name="employee_agent_number"
										type="text"
										value={customer ? ((customer.employee_agent_number != null) ? customer.employee_agent_number : '') : ''}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.hypurIsn" /></Label>
									<AvField
										id="hypur_isn"
										name="hypur_isn"
										type="text"
										value={customer ? ((customer.hypur_isn != null) ? customer.hypur_isn : '') : ''}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<AvGroup className="error-t-negative ">
							<Label><IntlMessages id="customer.typeOfCustomer" /><span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="customer_type"
								id="customer_type"
								value={this.state.customer_type}
								options={this.state.customerTypes}
								onChange={customer_type => { this.setState({ customer_type: customer_type }) }}
							/>
						</AvGroup>



						{this.state.doc_url != null ? <ViewFileModal
							modalOpen={this.state.modalOpenViewDoc}
							toggleModal={this.toggleModalViewDoc}
							doc_url={this.state.doc_url}
						/> : ""}

					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="update-customer" >Update</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditCustomerModal;
