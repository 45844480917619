import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label, Input, Table
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";
import Switch from "rc-switch";
import { DEFAULT_QTY_TYPES, LBS_CONSTANT } from "../../../../constants/defaultValues";
import Barcode from 'react-barcode';
import moment from 'moment';
import axios from 'axios';
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import SimpleCrypto from "simple-crypto-js";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, BASE_URL } from "../../../../constants/defaultValues";
const source = { '0': 'Inventory', '1': 'Grow House', '2': 'Vendor', '3': 'Extrator', '4': 'Rotovap', '5': 'Distillator', '6': 'Kitchen', '7': 'Oven', '8': 'MCP' };
const package_type = { '1': 'Package', '2': 'Finished Product' };
var dataCrypto = new SimpleCrypto(dataEncryptKey);
export default class ViewPackageDetailContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			verified: false,
			metrcLabel:""
		}
	}

	handleStateUpdate = () => {
		
		let verified = this.state.verified
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('package_id', this.props.product_package.package_id);
		formData.append('status', verified ? 1 : 3);
		formData.append('metrc_label',this.state.metrcLabel.value);
		axios.post(REST_API_END_POINT + 'inventory/update-package-status', formData).then((res) => {
			var data = res.data;
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});

	}
	handleVerifyClick = () => {
		window.open('https://ok.metrc.com', '_blank');
	}
	statusUpdate=()=>{
		this.setState({ verified: !this.state.verified })
	}
	render() {
		var qty_type = 'Grams';
		var quantity = '0';
		var barcode = '';
		const product_package = this.props.product_package;

		const chain_of_custody = this.props.chain_of_custody;
		var metrcPackage=this.props.metrcPackage;

		metrcPackage = metrcPackage?.map((element, index) => {
			element.label = element.metrc_label;
			element.value = element.metrc_label;
			element.key = index;
			return element;
		});
		
		
		if (product_package != null) {
			if (product_package['qty_type'] == '1') {
				qty_type = 'Grams';
				quantity = parseFloat(product_package['quantity']).toFixed(2);
			} else if (product_package['qty_type'] == '2') {
				qty_type = 'Lbs';
				quantity = parseFloat(product_package['quantity'] / LBS_CONSTANT).toFixed(2);
			} else {
				qty_type = 'Units';
				quantity = parseFloat(product_package['quantity']).toFixed(0);
			}

			if (product_package['variation_type'] != null || parseInt(product_package['variation_type']) > -1) {
				if (product_package['variation_type'] == '0') {
					qty_type = 'Milligrams';
				} else if (product_package['variation_type'] == '1') {
					qty_type = 'Grams';
				} else if (product_package['variation_type'] == '2') {
					qty_type = 'OZ';
				} else if (product_package['variation_type'] == '3') {
					qty_type = 'Units';
				}
			}

			if (product_package['source'] == 5) {
				barcode = product_package['batch_uid'];
			} else {
				barcode = product_package['barcode'];
			}
		}
		return (
			<div>
				{product_package['metrc_label'] ? <Row>

					<Colxx lg="3 mt-2">
						<Label>Metrc Label</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['metrc_label'] ? product_package['metrc_label'] : ""}
					</Colxx>
				</Row> : ""}
				<Row>

					<Colxx lg="3">
						<Label>Barcode</Label>
					</Colxx>
					<Colxx lg="9">
						<div className="">
							<Barcode value={barcode} height={30} width={0.95} margin={0} marginTop={5} displayValue={false} />
							<p className="text-left">{(barcode.match(/.{1,4}/g)).join(' ')}</p>
						</div>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Available</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['package_type'] == '1' ? quantity + ' ' + qty_type : parseInt(product_package['quantity'])}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Type</Label>
					</Colxx>
					<Colxx lg="9">
						{package_type[product_package['package_type']]}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Source</Label>
					</Colxx>
					<Colxx lg="9">
						{source[product_package['source']]}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Batch UID</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['batch_uid']}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Added Date</Label>
					</Colxx>
					<Colxx lg="9">
						{moment(product_package['timestamp'] * 1000).format("MM/DD/YYYY")}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Time In Room</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['time_in_room']}
					</Colxx>
				</Row>
				{product_package['status'] == 3 ?
					<Row>
						<Colxx lg="9 ">
							<Button color="primary" className="mt-4" onClick={this.handleVerifyClick}>
								Check status of Metrc
							</Button>

							<Label>
								<Switch
									className="custom-switch custom-switch-primary im-inline-block mt-4 ml-2"
									checked={this.state.verified}
									onChange={this.statusUpdate}
									// onChange={this.handleStateUpdate}
								/>
								<span className="h6 ml-4">Verified</span>
							</Label>
						</Colxx>

					</Row> : ""}
					{this.state.verified?<Row>
							<Colxx lg="12">
								<Label >Metrc Label<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="metrc_label"
									id="metrc_label"
									value={this.state.metrcLabel}
									options={metrcPackage}
									// onChange={this.handleStateUpdate(metrcLabel)}
									onChange={(selectedOption) => {
										const metrcLabel = selectedOption;
										this.setState({ metrcLabel }, () => {
										  this.handleStateUpdate();
										});
									  }}
								/>
							
							</Colxx>
						</Row>:""}
				<h3 className="font-weight-bold mt-3">Chain of Custody</h3>
				<Table>
					<thead>
						<tr>
							<th>Operation</th>
							<th>Done By</th>
							<th>Filed Date & Time</th>
						</tr>
					</thead>
					<tbody>
						{chain_of_custody.map((row, index) => {
							return (<tr key={index}>
								<td><div dangerouslySetInnerHTML={{ __html: row.operation }} /></td>
								<td>{row.done_by}</td>
								<td>{moment(row.filed_date).format('MM/DD/YYYY HH:mm')}</td>
							</tr>);
						})}
					</tbody>
				</Table>
			</div>
		);
	}
};