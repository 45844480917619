import React, { Component,Fragment } from "react";
import { Row, FormGroup, Input, CustomInput } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

export default class MixProductGrid extends Component {
	constructor(props) {
		super();
		this.state = {
			search:''
		}
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
		}
	}
		
	render() {
		const{ products, currency_rate, currency_symbol, index } = this.props;
		
		var final_products = products;
		
				
		if (this.state.search) {
			final_products = final_products.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		return (<Fragment>
				<div className="mb-2 p-2 product-item-search dropdown-edit">						
					<FormGroup row className="mb-0">
						<Colxx lg="12">
							<Input
								type="text"
								name={"product_search"+index}
								id={"product_search"+index}
								placeholder="Search"
								value={this.state.search}
								onChange={e => this.setState({search: e.target.value.toLowerCase()})}
							/>
						</Colxx>
					</FormGroup>
				</div>
				<PerfectScrollbar
				  option={{ suppressScrollX: true, wheelPropagation: false }}
				  className="mix-and-match-scrollbar"
				>
					<div className="d-flex flex-wrap">
					{final_products.map((row,index)=>{
						return(<Colxx md="3" xxl="2" className="text-center product-item" key={index} >
							<img src={row.thumbnail_url} className="product-thumnail img-responsive" />	
							<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
							<div className="d-flex justify-content-center">
								<CustomInput
									name={"selected_p_"+index}									
									type="checkbox"
									id={"selected_p_"+index}
									checked={row.selected}
									value={row.selected}
									onClick={(e)=>{
										this.props.selectProduct(row)
									}}
								/>
							</div>
							{row.selected ? <div className="d-flex justify-content-center">
							<AvForm							 
								className="av-tooltip tooltip-right-bottom w-100"
								onSubmit={this.handleSubmit}
							>	
								<AvField
									name={"selected_q_"+index}									
									type="number"
									id={"selected_q_"+index}
									className="text-center"
									value={row.quantity}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required"
										},
										min: {
											value: "1",
											errorMessage:"The quantity must be greater than or equal to 1"
										},
										max: {
											value: parseInt(row.stock).toString(),
											errorMessage:"The quantity must be less than or equal to "+parseInt(row.stock).toString()
										},
									}}
									onChange={(e)=>{
										let value = e.target.value;
										this.props.updateProductQuantity(row,e.target.value)
									}}
								/>
							</AvForm>
							</div>:""}
						</Colxx>)
					})}					
					</div>
				</PerfectScrollbar>
			</Fragment>);
	}
}
