import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);
class Reminder extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dataListRender = this.dataListRender.bind(this);
    this.state = {
      selectSourceType:null,
      selectAdditives: [],
     
    };
  }
  switchChange = (status,e) =>{
	  if(status){
		
	  }else{		
	  }
	}	
	toggleModal = () => {
		this.setState({
		  modalOpen: !this.state.modalOpen
		});
	};
	handleChangeDate = date => {
    this.setState({
      startDate: date
    });
  };
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-additive").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantAdditiveForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			

			axios.post(REST_API_END_POINT+'growhouse/add-plantAdditives',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_additive");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-additive").disabled=false;
				this.form && this.form.reset();
				
				var selectSourceType=null;
				if(this.state.selectAdditives.length){
					selectSourceType = this.state.selectAdditives[0];
				}
				
				this.setState({ selectSourceType })
				this.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-additive").disabled=false;
			});

		}
	}
  
  
    componentDidMount(){		
		var additives=[];	
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-additives',formData).then((res)=>{
			var data = res.data;
		
						
			additives=data.additives;			
					
			
			additives = additives.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var selectSourceType=null;
			if(additives.length){
				selectSourceType = additives[0];
			}
				
			this.setState({			
				selectAdditives:additives,
				selectSourceType
			});				
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
		this.dataListRender();
	   
	}

	
    dataListRender() {
		
		var plant_additives=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/get-plant-additives',formData).then((res)=>{
			var data = res.data;
			
			plant_additives=data.plant_additives;
		
			if(data.plant_additives){
				this.setState({
			        plant_additives: data.plant_additives,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					plant_additives: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}  
  
	render() {
		let data = this.state.plant_additives  
		const {selectAdditives} = this.state;
	  
		const dataTableColumns = [			  
			{
				Header: "Date",
				accessor: "created_at",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Additive",
				accessor: "additive",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Type",
				accessor: "type",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Weight",
				accessor: "weight",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Cost",
				accessor: "cost",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			}
		];
		
    return( 
      <Fragment>       
        <div className="p-4">	
         <div id="api_message_additive"></div>				
		  <div className="mb-4 dropdown-edit">
			{this.props.user_privileges.indexOf(144)!==-1 ? <AvForm
		        id="plantAdditiveForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			  	<Row className="mt-2">
					<Colxx lg="4" >
						<AvGroup className="error-t-negative">
							<Label>Additive<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="additive_id"
								
								id="additive"
								value={this.state.selectSourceType}						
								options={selectAdditives}
							   onChange={selectSourceType => { this.setState({ selectSourceType }) }}	
							/>
						</AvGroup>
					</Colxx>
				
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
						  <Label>Additive Weight<span className="text-danger">*</span></Label>
						  <AvField
							id="additive_weight"
							name="additive_wg"
							type="number"
							value=""
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},
								min: {
									value: "0",
									errorMessage: "Value should be greater than 0"
								}
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
							<Label>Additive Cost<span className="text-danger">*</span></Label>
							<AvField
								id="additive_cost"
								name="additive_cst"
								type="number"
								value=""
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min: {
										value: "0",
										errorMessage: "Value should be greater than 0"
									}
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2 mb-4">	
					<Colxx xxs="6">
						<Button
							id="add-additive"
							color="primary"
							size="sm"
							className=""
							>
							Add Terpenes
						</Button>					
					</Colxx>					
				</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Reminder;
