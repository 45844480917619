import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  Table
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditAdditive extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addItem = this.addItem.bind(this);
		this.removeItem = this.removeItem.bind(this);
		
		this.state = {
			additive_template:null,
			temp_item_name:'',
			items:[]
		};		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){	   
        if(this.props.modalOpen){
			let additive_template; 		  
            if (prevProps.additive_id !== this.props.additive_id && this.props.additive_id!=0 ) {
				var formData = new FormData();
				formData.append('template_id',this.props.additive_id);
	
				axios.post(REST_API_END_POINT+'inventory/get-additive-template-by-id',formData).then((res)=>{
					var data = res.data;
					var status=null;
					additive_template=data.additive_template;
					
					var items = additive_template.items;
					
					this.setState({ 
						additive_template,
						items,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-additive").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("additiveForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('template_id',this.props.additive_id);

			axios.post(REST_API_END_POINT+'inventory/edit-additive-template',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-additive").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-additive").disabled=false;
			});

		}
	}
	
	addItem(){ 
		var temp_item_name = this.state.temp_item_name;
		if(temp_item_name==''){
			return;
		}
		var items = this.state.items;
		if(items.findIndex(row=>{ return row.name==temp_item_name })==-1){
			items.push({id:"",name:temp_item_name});
		}else{
			swal("The additive already present in the list!");
		}
		this.setState({ 
			temp_item_name:'',
			items
		});
	}
	
	removeItem(data){
		var items = this.state.items;
		items = items.filter(row=>{ return row.name!==data });
		this.setState({ 
			items
		});
	}
	
	
	render() {
		  
			if(this.state.additive_template!=null){
				var additive_template=this.state.additive_template;
			}else{
			   var additive_template;
			}
		  return (
		  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<AvForm
					id="additiveForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
	
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Terpenes Template
				</ModalHeader>
			  
				<ModalBody>			
					<Row>
						<Colxx lg="12">						
							<AvGroup className="error-t-negative">
							  <Label>Template Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								value={ additive_template?((additive_template.name!=null)?additive_template.name:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z0-9 ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
							
						</Colxx>
						
					</Row>
					<Row>
					
						<Colxx lg="4" className="mt-3">
							<AvGroup className="error-t-negative">
							  <Label>Terpenes Name</Label>						  
							</AvGroup>
						</Colxx>
						<Colxx lg="4">
							<AvGroup className="error-t-negative">						 
							  <AvField
								id="item_name"
								name="temp_item_name"
								type="text"	
								autoComplete="off"
								value={this.state.temp_item_name}
								onChange={(e)=>{
									this.setState({ 
										temp_item_name:e.target.value
									});
								}} 						
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="4">
							<Button																		 
								  color="primary"
								  size="sm"
								  className=""	
								  onClick = {this.addItem}			  
								>
								  Add							  
							</Button>	
						</Colxx>
						
					</Row>
					
					<Table>
						<thead>
							<tr><th>Terpenes</th><th></th></tr>
						</thead>
						<tbody>
							{this.state.items.map((data,i)=>{
								return(<tr key={i}>
									<td>{data.name}<input className="d-none" type="text" name="item_id[]" value={data.id} readOnly={true} /><input className="d-none" type="text" name="item_name[]" value={data.name} readOnly={true} /></td>                                                           
									<td> <div className="remove-section float-right" onClick={()=>this.removeItem(data.name)} ><i className="simple-icon-close"></i></div></td>                                         
								  </tr>)
							})}
						</tbody>
					</Table>
					
			  </ModalBody>
			  <ModalFooter>
					<Button color="primary" id="update-additive" >
						Update Template
					</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			  </ModalFooter>
			  </AvForm>
		  </Modal>
		  );
	  }
};

export default EditAdditive;
