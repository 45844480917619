import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import DropMoneyModal from "../../../containers/bankmanagement/drawers/DropMoneyModal";
import EditDrawerAssignee from "../../../containers/bankmanagement/drawers/EditDrawerAssignee"
import ReactTable from "react-table";
import swal from 'sweetalert';
import { REST_API_END_POINT, REST_API_BASE_URL, BASE_URL, dataEncryptKey, JQUERY_MODULE } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { AvForm, AvField } from "availity-reactstrap-validation";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class drawerview extends Component {

	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);

		this.state = {
			modalOpenAdd: false,
			modalOpenEditDrawer:false,
			search_cash: '',
			search_cash_taken: '',
			drawer: null,
			currencies: [],
			currency_code: 'USD',
			staffs:[],
			assignedStaff:[],
			drawerStartingAmount:'',
			refundAmount:0,

		};
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		
	}

	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEditDrawer = () => {
		this.setState({
			modalOpenEditDrawer: !this.state.modalOpenEditDrawer
		});
	};

	dataListRender() {
		let drawer;
		let cash;
		let cashtaken;
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);

		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('drawer_id', this.props.match.params.drawer_id);
		formData.append('currency_code', this.state.currency_code);

		axios.post(REST_API_END_POINT + 'drawer/drawer-detail', formData).then((res) => {
			var data = res.data;
			drawer = data.drawer;
			cash = data.cash;
			cashtaken = data.cash_taken;
			var currencies = data.currencies;
			var staffs=data.staffs,
			staffs = staffs.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			let refundAmount=0;
			if(cashtaken){
				cashtaken.forEach(row => {
					refundAmount+=parseFloat(row.total)
				}); 
			}
			
			
			
			var assignedStaff=[]
			if(drawer)
			{
				 assignedStaff=drawer['assigned_to'];
			
				assignedStaff = assignedStaff.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
			}
		
			
			this.setState({
				drawer,
				cash,
				cashtaken,
				currencies,
				staffs,
				assignedStaff,
				refundAmount,
				drawerStartingAmount:data.drawer_starting_cash
			});
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}


	componentDidMount() {
		this.dataListRender();
	}
	handleMouseEnter = (index) => {
		const nameOverlay = document.getElementById(`nameOverlay-${index}`);
		nameOverlay.style.visibility = 'visible';

	};

	handleMouseLeave = (index) => {
		const nameOverlay = document.getElementById(`nameOverlay-${index}`);
		nameOverlay.style.visibility = 'hidden';

	};

	render() {
		const currencies = this.state.currencies;
		let data = this.state.cash;


		if (this.state.search_cash) {
			data = data?.filter(row => {

				return row['1_s'].includes(this.state.search_cash) ||
					row['2_s'].includes(this.state.search_cash) ||
					row['5_s'].includes(this.state.search_cash) ||
					row['10_s'].includes(this.state.search_cash) ||
					row['20_s'].includes(this.state.search_cash) ||
					row['50_s'].includes(this.state.search_cash) ||
					row['100_s'].includes(this.state.search_cash) ||
					row['cents'].includes(this.state.search_cash) ||
					row['nickles'].includes(this.state.search_cash) ||
					row['dimes'].includes(this.state.search_cash) ||
					row['quarters'].includes(this.state.search_cash) ||
					row['50_cents'].includes(this.state.search_cash) ||
					row['1_d'].includes(this.state.search_cash) ||
					row['total'].includes(this.state.search_cash) ||
					row['added_by'].toLowerCase().includes(this.state.search_cash) ||
					row['added_at_date'].toLowerCase().includes(this.state.search_cash)
			})
		}

		let datataken = this.state.cashtaken;
		if (this.state.search_cash_taken) {
			datataken = datataken?.filter(row => {
				return row['1_s'].includes(this.state.search_cash_taken) ||
					row['2_s'].includes(this.state.search_cash_taken) ||
					row['5_s'].includes(this.state.search_cash_taken) ||
					row['10_s'].includes(this.state.search_cash_taken) ||
					row['20_s'].includes(this.state.search_cash_taken) ||
					row['50_s'].includes(this.state.search_cash_taken) ||
					row['100_s'].includes(this.state.search_cash_taken) ||
					row['cents'].includes(this.state.search_cash_taken) ||
					row['nickles'].includes(this.state.search_cash_taken) ||
					row['dimes'].includes(this.state.search_cash_taken) ||
					row['quarters'].includes(this.state.search_cash_taken) ||
					row['50_cents'].includes(this.state.search_cash_taken) ||
					row['1_d'].includes(this.state.search_cash_taken) ||
					row['total'].includes(this.state.search_cash_taken) ||
					row['added_by'].toLowerCase().includes(this.state.search_cash_taken) ||
					row['add_user'].toLowerCase().includes(this.state.search_cash_taken) ||
					row['added_at_date'].toLowerCase().includes(this.state.search_cash_taken)
			})
		}

		if (this.state.search) {
			data = data?.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}

		var drawer = this.state.drawer;

		if (this.state.drawer != null) {
			// var total = parseInt(drawer['1_s']) + parseInt(drawer['2_s']) * 2 + parseInt(drawer['5_s']) * 5 + parseInt(drawer['10_s']) * 10 + parseInt(drawer['20_s']) * 20 + parseInt(drawer['50_s']) * 50 + parseInt(drawer['100_s']) * 100 + parseInt(drawer['cents']) * 0.01 + parseInt(drawer['nickles']) * 0.05 + parseInt(drawer['dimes']) * 0.1 + parseInt(drawer['quarters']) * 0.25 + drawer['50_cents'] * 0.5 + parseInt(drawer['1_d'])
			var total = parseFloat(this.state.drawer.totalNew) + parseFloat(this.state.cash[0].total)-parseFloat(this.state.refundAmount)
		} else {
			var total = 0;
		}

		const commonColumns = [
			{
				Header: "1's",
				accessor: "1_s",

				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "2's",
				accessor: "2_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "5's",
				accessor: "5_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "10's",
				accessor: "10_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "20's",
				accessor: "20_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50's",
				accessor: "50_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "100's",
				accessor: "100_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Cents",
				accessor: "cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Nickels",
				accessor: "nickles",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dimes",
				accessor: "dimes",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Quarters",
				accessor: "quarters",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50 Cents",
				accessor: "50_cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dollar",
				accessor: "1_d",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="list-item-heading">{parseFloat(props.value).toFixed(2)}</p>
			}
		];

		const selected_currency = currencies.find(row => { return row.currency_code == this.state.currency_code });
		var bank_total_view = [];
		var finalCommonColumns = commonColumns;
		if (typeof selected_currency != 'undefined') {
			finalCommonColumns = [];
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for (let key in currency_struct) {
				finalCommonColumns.push(commonColumns.find(r => { return r.accessor == key }));

				bank_total_view.push(<Fragment>
					<Colxx xxs="3" className="mt-4">
						<img alt={currency_struct[key].alt} src={REST_API_BASE_URL + currency_struct[key].src} className="img-responsive" />
					</Colxx>
					<Colxx xxs="3" className="mt-4">
						<h1 className="font-weight-bold mt-3">{drawer ? ((drawer[key] != null) ? drawer[key] : '0') : '0'}</h1>
					</Colxx>
				</Fragment>)

			}
		}

		const dataTableColumns = [
			// ...finalCommonColumns,


			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added By",
				accessor: "add_user",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Account Holder",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added At",
				accessor: "added_at_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];

		const dataTableColumns1 = [
			// ...finalCommonColumns,
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken By",
				accessor: "add_user",
				Cell: props => <p className="text-muted">{props.value}</p>
			},

			{
				Header: "Taken At",
				accessor: "added_at_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Account Holder",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
		];

		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.drawer-view" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>

				<Card className="p-4">
					<Row>
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						<Colxx xxs="12">
							<div className="float-sm-left" style={{ width: '200px' }}>
								<AvForm
									className="av-tooltip tooltip-right-bottom d-none"
									onSubmit={() => { }}
								>
									<AvField
										type="select"
										name="currency_code"
										id="currency_code"
										value={this.state.currency_code}
										onChange={(e) => { this.setState({ currency_code: e.target.value }, () => { this.dataListRender() }) }}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									>
										{currencies.map(opt => {
											return <option key={opt.id} value={opt.currency_code} >{opt.country_name + " - " + opt.currency_code}</option>
										})}
									</AvField>
								</AvForm>
							</div>
							<div className="float-sm-right d-none">
								{" "}<Button
									color="primary"
									size="sm"
									className=""
									onClick={this.toggleModalAdd}
								>
									Drop Money To Bank
								</Button>

							</div>
							<div className="float-sm-right ">
								{" "}<Button
									color="primary"
									size="sm"
									className=""
									onClick={this.toggleModalEditDrawer}
								>
									Edit Drawer
								</Button>

							</div>
						</Colxx>

						<Colxx xxs="12 d-flex">

							{/* <h1 className="font-weight-bold mt-3"><span className="font-weight-normal">Assigned to:</span> {drawer? ((drawer['assigned_to']!=null)?drawer['assigned_to']:''):'' }</h1> */}

							
								{drawer ? drawer['assigned_to'] != null ?
									<div className="d-flex flex-wrap circle-container">
										<h1 className="font-weight-bold mt-3"><span className="font-weight-normal">Assigned to:</span></h1>
										{drawer['assigned_to'].map((item, index) => (
											<div
												key={index}
												className="position-relative m-2"
												onMouseEnter={() => this.handleMouseEnter(index)}
												onMouseLeave={() => this.handleMouseLeave(index)}
											>
												<img
													src={BASE_URL + "users/" + item.profile_pic}
													alt={item.name}
													className="img-thumbnail rounded-circle"
													style={{ height: '70px', width: '70px' }}
												/>
												<div
													id={`nameOverlay-${index}`}
													className="position-absolute bottom-0 start-0  font-weight-bold"
													style={{ visibility: 'hidden' }}
												>
													{item.name}
												</div>
											</div>
										))}
									</div> : "" : ""}
							{/* {drawer ? drawer['assigned_to'] != null ? (
								<div className="d-flex flex-wrap circle-container">
									<h1 className="font-weight-bold mt-3">
										<span className="font-weight-normal">Assigned to:</span>
									</h1>
									{drawer['assigned_to'].map((item, index) => (
										<div
											key={index}
											className="position-relative m-2 "
											onMouseEnter={() => this.handleMouseEnter(index)}
											onMouseLeave={() => this.handleMouseLeave(index)}
										>
											<img
												src={BASE_URL + "users/" + item.profile_pic}
												alt={item.name}
												className="img-thumbnail rounded-circle "
												style={{ height: '70px', width: '70px' }}
											/>
											<div
												id={`nameOverlay-${index}`}
												className="position-absolute bottom-0 start-0 font-weight-bold"
												style={{ visibility: 'hidden' }}
											>
												{item.name}

											</div>
										</div>
									))}
								</div>
							) : null : null} */}


						</Colxx>

						<Colxx xxs="9">
							{/* <Row className="currency-img align-items-center">
								{bank_total_view}
							</Row> */}

							<Row className="mt-5">
								<Colxx xxs="5">
									<h1>Total</h1>
								</Colxx>
								<Colxx xxs="7">
									<h1>{total.toFixed(2)}</h1>
								</Colxx>
							</Row>
						</Colxx>

					</Row>

					<Row className="mt-4">
						<Colxx xxs="12">
							<div className="mb-4 dropdown-edit">
								<h3 className="font-weight-bold">Cash Added to Drawer</h3>
								<FormGroup row>
									<Colxx lg="6">
										<Input
											type="text"
											name="table_search"
											id="table_search"
											placeholder="Search"
											value={this.state.search_cash}
											onChange={e => this.setState({ search_cash: e.target.value.toLowerCase() })}
										/>
									</Colxx>
								</FormGroup>
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={5}
									filterable={false}
									showPageJump={false}
									responsive
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{ id: "added_at_date", desc: true }]}
								/>
							</div>
							<div className="mb-4 dropdown-edit ">
								<h3 className="font-weight-bold">Cash Taken From Drawer</h3>
								<FormGroup row>
									<Colxx lg="6">
										<Input
											type="text"
											name="table_search"
											id="table_search_taken"
											placeholder="Search"
											value={this.state.search_cash_taken}
											onChange={e => this.setState({ search_cash_taken: e.target.value.toLowerCase() })}
										/>
									</Colxx>
								</FormGroup>
								<ReactTable
									data={datataken}
									columns={dataTableColumns1}
									defaultPageSize={5}
									filterable={false}
									showPageJump={false}
									responsive
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{ id: "added_at_date", desc: true }]}
								/>
							</div>
						</Colxx>
					</Row>

					<DropMoneyModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
						drawer_id={this.props.match.params.drawer_id}
						currencies={this.state.currencies}
						currency_code={this.state.currency_code}
					/>
					{this.state.modalOpenEditDrawer?<EditDrawerAssignee
						modalOpen={this.state.modalOpenEditDrawer}
						toggleModal={this.toggleModalEditDrawer}
						dataListRender={this.dataListRender}
						drawer_id={this.props.match.params.drawer_id}
						currencies={this.state.currencies}
						currency_code={this.state.currency_code}
						staffs={this.state.staffs}
						assigned_to={this.state.assignedStaff}
						drawerStartingAmount={this.state.drawerStartingAmount}
					/>:""}
				</Card>
			</Fragment>
		);
	}
}
