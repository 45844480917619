import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import transportation from "./transportation";
import drivers from "./drivers";
import trackdrivers from "./trackdrivers";
import shipperinformation from "./shipperinformation";
import vehicles from "./vehicles";
import kits from "./kits";
import kitType from "./kitType";
import kitTransport from "./transportKit"
const Transportation = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
			<Route path={`${match.url}/transportation`} component={transportation} />
			<Route path={`${match.url}/drivers`} component={drivers} />
			<Route path={`${match.url}/track-drivers`} component={trackdrivers} />
			<Route path={`${match.url}/shipper-information`} component={shipperinformation} />
			<Route path={`${match.url}/vehicles`} component={vehicles} />
			<Route path={`${match.url}/kit-type`} component={kitType} />
			<Route path={`${match.url}/kits`} component={kits} />
			<Route path={`${match.url}/kit-transport`} component={kitTransport} />
			<Route path={`${match.url}`} component={transportation} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Transportation  ;