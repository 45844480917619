import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input, Table
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES, LBS_CONSTANT, themeColor } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactToPrint from 'react-to-print';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import ViewPackageDetailContent from "./ViewPackageDetailContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ViewPackageDetailModal extends Component{
	constructor(props) {
		super(props);
		this.state={
			package_detail:null,
			chain_of_custody:[],
			blocking: true,
			metrcPackage:[],
		}
	}
	
	componentDidMount(){   
        if(this.props.modalOpen){	
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);		
			var formData = new FormData();
			formData.append('package_id',this.props.product_package_id);
			formData.append('facility_id',facility_id)
			const api_url = this.props.is_finished ? 'inventory/view-finished-detail':'inventory/view-package-detail';

			axios.post(REST_API_END_POINT+api_url,formData).then((res)=>{
				var data = res.data;
				const product_package=data.package_detail;
				const chain_of_custody=data.chain_of_custody;
				var metrcPackage=data.metrc_package;
				this.setState({ 
					product_package,
					chain_of_custody,
					blocking:false,
					metrcPackage
				});
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				this.setState({ blocking:false });
			});			
		}
		
		
	}
	
	render() {
		var qty_type='Grams';
		var quantity='0';
		var barcode='';
		const product_package = this.state.product_package;
		const chain_of_custody = this.state.chain_of_custody;
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="lg"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				View Detail
			  </ModalHeader>
			  
				<ModalBody>
					<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
						{product_package!=null ? <ViewPackageDetailContent 
							ref={el => (this.componentRef2 = el)}
							{...this.state}
							product={this.props.product}
							metrcPackage={this.state.metrcPackage}
							dataListRender={this.props.dataListRender}
							
						/>:""}
					</BlockUi>
				</ModalBody>
				<ModalFooter>
					<ReactToPrint
						trigger={() => <Button color="primary" ><IntlMessages id="customer.print" /></Button>}
						content={() => this.componentRef2}
						copyStyles={true}
						pageStyle={""}
					/>
					{product_package!=null ? <Button color="primary" onClick={(e)=>{ this.props.printBarcodes(e,[product_package.package_id]) }} >Print Barcode</Button>:""}					
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
	  );
  }
};