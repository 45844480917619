import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import { Input, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import swal from 'sweetalert';
import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  cartItemStock,
  deleteAllFromCart,
  ApplyCoupon,
  RemoveCoupon
} from "../../redux/cart/cartActions";
let flag=0;
const Cart = ({
  location,
  cartItems,
  currency,
  decreaseQuantity,
  addToCart,
  deleteFromCart,
  deleteAllFromCart,
  ApplyCoupon,
  RemoveCoupon
}) => {
	const shopCartCouponCode = localStorage.getItem('shopCartCouponCode');
	const [quantityCount] = useState(1);
	const { addToast } = useToasts();
	let cartSubtotalPrice = 0;
	let cartTaxPrice = 0;
	let cartTotalPrice = 0;
	let cartDiscount = 0;
	 flag=0;
	const getCouponDetail = (event, errors, values) => {
		if (errors.length === 0) {
			ApplyCoupon(values.coupon_code,addToast);
		}
	}
	
	if(cartItems && cartItems.length >= 1){
		let coupon_discount=0;
		var coupon_data = localStorage.getItem('shopCartCoupon');
		if(coupon_data!=null){
			coupon_data = JSON.parse(coupon_data);
			if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
				coupon_discount += parseFloat(coupon_data['amount']);
			}
		}
		
	
		cartItems.map((cartItem, key) => {
								
			const finalProductPrice = (cartItem.price * currency.currencyRate).toFixed(2);
			
			cartSubtotalPrice += cartItem.product_unit_price * cartItem.quantity;
			cartTaxPrice += cartItem.sales_tax * cartItem.quantity;
			cartTotalPrice += finalProductPrice * cartItem.quantity;
			
			cartDiscount+= typeof cartItem.coupon_discount!='undefined' ? cartItem.coupon_discount*currency.currencyRate:0
			let totalQuantity=0;
		if(cartItem.product_type==1){
			let variation = cartItem.variations.find(r => { return r.variation_id == cartItem.variation_id });
			totalQuantity=totalQuantity+cartItem.quantity*parseFloat(variation.variation_weight_set);
		}
		cartItem.totalQuantity=totalQuantity;
			return cartItem;
		});
		
		if(coupon_data!=null){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
				coupon_discount+=cartTotalPrice*parseFloat(coupon_data['amount'])/100;
			}
		}
		
		if(cartTotalPrice<coupon_discount){
			coupon_discount=0;
		}
		cartDiscount+=coupon_discount;
		cartTotalPrice-=cartDiscount;
		
	
	let cartdatas
	cartdatas=cartItems?.map(row => {
		let totalQuantity=0;
		
		if(row.daily_limit){
			let variation = row.variations.find(r => { return r.variation_id == row.variation_id });
			totalQuantity=totalQuantity+row.quantity*parseFloat(variation.variation_weight_set);
		}
		
		row.totalQuantity=totalQuantity;
		 return row;
	})
	
	cartdatas.map(row=>{
		
		console.log('######Total Quantity',parseFloat(row.totalQuantity).toFixed(2));
		console.log('######Total Quantity',parseFloat(row.daily_limit_amount).toFixed(2));
		if((parseFloat(row.totalQuantity)>parseFloat(row.daily_limit_amount))&& row.daily_limit){
			swal("Warning!","Daily limit exceed","warning");
			flag=1;
		}
		
		
	})
	
	}
	
	
	
  return (
    <Fragment>      
        <div className="cart-main-area pt-4 pb-4">
          <div className="container">
            {cartItems && cartItems.length >= 1 ? (
              <Fragment>
					<h3 className="cart-page-title">Your cart items</h3>
					<div className="row">
					  <div className="col-12">
						<div className="table-content table-responsive cart-table-content">
						  <table>
							<thead>
							  <tr>
								<th>Image</th>
								<th>Product Name</th>
								<th>Unit Price</th>
								<th>Qty</th>
								{/* <th>Tax</th> */}
								<th>Subtotal</th>
								<th>action</th>
							  </tr>
							</thead>
							<tbody>
							  {cartItems.map((cartItem, key) => {	
							
															
								const finalProductPrice = (cartItem.price * currency.currencyRate).toFixed(2);
								let variation = typeof cartItem.variations!='undefined' ? cartItem.variations.find(s => s.variation_id === cartItem.variation_id):{variation_name:""};
              
								return (
								  <tr key={key}>
									<td className="product-thumbnail">									  
										<img
											className="img-fluid"
											src={cartItem.thumbnail_url}
											alt=""
										/>
									</td>

									<td className="product-name">									  
										<span dangerouslySetInnerHTML={{ __html: cartItem.name }} />
										{variation ? <span className="d-block font-weight-bold">{variation.variation_name}</span>:""}
										{cartItem.product_type=1 ? <span className="d-block font-weight-bold text-danger">Daily Limit:{cartItem.daily_limit}{cartItem.daily_limit_type==1?"g":cartItem.daily_limit_type==2?"oz":"unit"}</span>:""}
										{((cartItem.totalQuantity>cartItem.daily_limit_amount)&& cartItem.product_type==1)?<span className="d-block font-weight-bold text-danger">Daily Limit Exceed</span>:""}
									</td>

									<td className="product-price-cart">									  
										<span className="amount">
										  {currency.currencySymbol + cartItem.product_unit_price}
										</span>
									</td>

									<td className="product-quantity">
									  <div className="cart-plus-minus">
										<button
										  className="dec qtybutton"
										  onClick={() =>
											decreaseQuantity(cartItem, addToast)
										  }
										>
										  -
										</button>
										<input
										  className="cart-plus-minus-box"
										  type="text"
										  value={cartItem.quantity}
										  readOnly
										/>
										<button
										  className="inc qtybutton"
										  onClick={() =>
											addToCart(
											  cartItem,
											  addToast,
											  quantityCount,
											  cartItem.variation_id
											)
										  }
										  disabled={
											cartItem !== undefined &&
											cartItem.quantity &&
											cartItem.quantity >=
											  cartItemStock(cartItem)
										  }
										>
										  +
										</button>
									  </div>
									</td>
									{/* <td className="product-subtotal">
									  {currency.currencySymbol + (cartItem.sales_tax * cartItem.quantity).toFixed(2)}
									</td> */}
									<td className="product-subtotal">
									  {currency.currencySymbol + (finalProductPrice * cartItem.quantity).toFixed(2)}
									</td>

									<td className="product-remove">
									  <button onClick={() =>deleteFromCart(cartItem, addToast) } >
										<i className="fa fa-times"></i>
									  </button>
									</td>
								  </tr>
								);
							  })}
							</tbody>
						  </table>
						</div>
					  </div>
					</div>
					<div className="row">
					  <div className="col-lg-12">
						<div className="cart-shiping-update-wrapper">
						  <div className="cart-shiping-update">
							<Link to={"/shop"} >
							  Continue Shopping
							</Link>
						  </div>
						  <div className="cart-clear">
							<button onClick={() => deleteAllFromCart(addToast)}>
							  Clear Shopping Cart
							</button>
						  </div>
						</div>
					  </div>
					</div>

					<div className="row justify-content-between">				  

					  <div className="col-lg-4 col-md-6">
						<div className="discount-code-wrapper">
						  <div className="title-wrap">
							<h4 className="cart-bottom-title section-bg-gray">
							  Use Coupon Code
							</h4>
						  </div>
						  <div className="discount-code">
							<p>Enter your coupon code if you have one.</p>
							
							{shopCartCouponCode && cartDiscount>0 ? <div className="mb-3"><Button color="primary" type="button" size="xs" onClick={()=>{ RemoveCoupon(addToast) }}>{shopCartCouponCode} <i className="fas fa-times" /></Button></div>:<AvForm 
								id="couponForm"
								className=""
								onSubmit={getCouponDetail}
								>
								<AvField
									id="coupon_code"
									name="coupon_code"
									type="text"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the coupon code"
										}
									}}
								/>
								<button className="cart-btn-2" type="submit">
									Apply Coupon
								</button>
							</AvForm>}
								
						  </div>
						</div>
					  </div>

					  <div className="col-lg-4 col-md-12">
						<div className="grand-totall">
						  <div className="title-wrap">
							<h4 className="cart-bottom-title section-bg-gary-cart">
							  Cart Total
							</h4>
						  </div>
						  <h5 className="mt-3 mb-0">
							Sub total{" "}<span>{currency.currencySymbol + cartSubtotalPrice.toFixed(2)}</span>
						  </h5>
						  <h5 className="mt-2 mb-0">
							Tax{" "}<span>{currency.currencySymbol + cartTaxPrice.toFixed(2)}</span>
						  </h5>
						  <h5 className="mt-2 mb-0">
							Discount{" "}<span>{currency.currencySymbol + cartDiscount.toFixed(2)}</span>
						  </h5>

						  <h4 className="grand-totall-title mt-3">
							Grand Total{" "}<span>{currency.currencySymbol + cartTotalPrice.toFixed(2)}</span>
						  </h4>
						  {console.log('Df',flag)
						  }
						  {flag==1?"Daily Limit Exceed":
						  <Link to={"/checkout"}>
							Proceed to Checkout
						  </Link>}
						</div>
					  </div>
					</div>
				</Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-3">
                      <i className="fas fa-shopping-cart" />
                    </div>
                    <div className="item-empty-area__text">
                      No items found in cart <br />{" "}
                      <Link to={"/shop"} className="btn btn-primary">Shop Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
    </Fragment>
  );
};

Cart.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  location: PropTypes.object,
  deleteAllFromCart: PropTypes.func,
  ApplyCoupon: PropTypes.func,
  RemoveCoupon: PropTypes.func,
  deleteFromCart: PropTypes.func
};

const mapStateToProps = state => {
  return {
    cartItems: state.cartData,
    currency: state.currencyData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (item, addToast, quantityCount, variationId) => {
      dispatch(addToCart(item, addToast, quantityCount, variationId));
    },
    decreaseQuantity: (item, addToast) => {
		
		
      dispatch(decreaseQuantity(item, addToast));
    },
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    deleteAllFromCart: addToast => {
      dispatch(deleteAllFromCart(addToast));
    },
    ApplyCoupon:(couponCode,addToast) => {
      dispatch(ApplyCoupon(couponCode,addToast));
    },
    RemoveCoupon:(addToast) => {
      dispatch(RemoveCoupon(addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
