import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddPackageModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-product-package").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("addPackageForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'inventory/add-package',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message_packages");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("add-product-package").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-product-package").disabled=false;
			});
		}
	}
	
	render() {
		const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==this.props.product.qty_type });
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Add Package
			  </ModalHeader>
			  <AvForm
					id="addPackageForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Quantity ({typeof qty_type!='undefined' ? qty_type.label:""})<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup className="error-t-negative">								
								<AvField
									id="quantity"
									name="quantity"
									type="text"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
							<Input type="hidden" name="product_id" value={this.props.product.id} />
							<Input type="hidden" name="package_type" value="1" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Price ( 1{typeof qty_type!='undefined' ? qty_type.label:""})<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup className="error-t-negative">								
								<AvField
									id="price"
									name="price"
									type="text"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
							
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>No. of Packages<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup className="error-t-negative">								
								<AvField
									id="no_packages"
									name="no_packages"
									type="number"
									value={1}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: "1",
											errorMessage: "Please enter a value greater than or equal to 1"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-product-package" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};