import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddDepositMoneyModal  extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
			selectBankType:"",
			banktotal:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			}
		};
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-money").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("moneyForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('currency_code',this.props.currency_code);
			
			axios.post(REST_API_END_POINT+'drawer/check-bank-stock',formData).then((res)=>{
				var data = res.data;				
				if(data.proceed){
					var formData = new FormData(document.getElementById("moneyForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					formData.append('currency_code',this.props.currency_code);
					
					axios.post(REST_API_END_POINT+'bank/move-to-external',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-money").disabled=false;
						this.props.toggleModal();
						this.setState({			
							selectBankType:"",
							banktotal:{
								'1_s':0,
								'2_s':0,
								'5_s':0,
								'10_s':0,
								'20_s':0,
								'50_s':0,
								'100_s':0,
								'cents':0,
								'nickles':0,
								'dimes':0,
								'quarters':0,
								'50_cents':0,
								'1_d':0
							}
						});
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("add-money").disabled=false;
					});
				}else{
					swal("There is no enough stock in the bank");
					document.getElementById("add-money").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-money").disabled=false;
			});
			
		}
	}
 
	render() {

        var banktotal = this.state.banktotal;	
		
		if(this.state.banktotal!=null){
			var total = parseInt(banktotal['1_s'])+parseInt(banktotal['2_s'])*2+parseInt(banktotal['5_s'])*5+parseInt(banktotal['10_s'])*10+parseInt(banktotal['20_s'])*20+parseInt(banktotal['50_s'])*50+parseInt(banktotal['100_s'])*100+parseInt(banktotal['cents'])*0.01+parseInt(banktotal['nickles'])*0.05+parseInt(banktotal['dimes'])*0.1+parseInt(banktotal['quarters'])*0.25+banktotal['50_cents']*0.5+parseInt(banktotal['1_d'])
		}else{
			var total=0;
		}
		
		var external_banks = [{ label:"--Select Bank--", value:"", key: 0},...this.props.external_banks];
		
		const currencies = this.props.currencies;
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.props.currency_code });
		var field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				
				field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup className="error-t-negative">
										  <AvField
											id={key}
											name={key}
											type="number"
											placeholder="0"
											min="0"
											step="1"
											value={this.state.banktotal[key]!=0?this.state.banktotal[key]:''}
											onChange={(e)=>{
												var banktotal = this.state.banktotal;
												if(e.target.value!=''){
													banktotal[key]=e.target.value;
												}else{
													banktotal[key]=0;
												}
												this.setState({
													banktotal
												});
											}}
										
										  />
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="lg"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Deposit Money to External Bank	
			</ModalHeader>
			<AvForm
		        id="moneyForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row className="mt-4">
					<Colxx xxs="12">
						<AvGroup className="error-t-negative">
							<Label>Select External Bank</Label>
							<AvField
								type="select"
								name="bank_id"
								id="bank_id"
								placeholder="--Select Bank--"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								value={this.state.selectBankType}
								onChange={(e)=>{
									this.setState({
										selectBankType:e.target.value
									});
								}}
							>
							{external_banks.map(opt=>{
								return <option key={opt.key} value={opt.value} >{opt.label}</option>
							})}
							</AvField>							
						</AvGroup>
					</Colxx>
				</Row>
				
				{/* <Row className="align-items-center">
					{field_view}
				</Row> */}
				
				<Row className="mt-4">
					<Colxx xxs="2">
						<h3>Total</h3>
					</Colxx>
					<Colxx xxs="3">
						<AvGroup className="error-t-negative">
						  <AvField
							id="total"
							name="total"
							type="text"
							readOnly={true}
							placeholder="0"
							value={total.toFixed(2)}						
						  />
						</AvGroup>
					</Colxx>
					<Colxx xxs="2">
					</Colxx>
					<Colxx xxs="3">				
					</Colxx>
				</Row>					
			</ModalBody>
			<ModalFooter>
				<Button color="primary" id="add-money" >
				  <IntlMessages id="customer.submit" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddDepositMoneyModal ;
