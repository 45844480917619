import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import inventory from "./inventory";			
import allRooms from "./allRooms";
import distillateBuckets from "./distillateBuckets";
import pricingGroup from "./pricingGroup";
import allStrains from "./allStrains";
import allProductCategories from "./allProductCategories";
import allTypes from "./allTypes";
import allTax from "./allTax";
import wasteManagement from "./wasteManagement";
import allAdditiveTemplate from "./allAdditiveTemplate";
import allOutgoingTransfer from "./allOutgoingTransfer";
import allHoldedTransfer from "./allHoldedTransfer";
import allIncomingTransfer from "./allIncomingTransfer";
import outgoingInvoice from "./outgoingInvoice";
import selectFacility from "./selectFacility";
import transfer from "./transfer";
import transferHold from "./transfer-hold";
import invoice from "./invoice";
import editProduct from "./editProduct";
import inventoryAudit from "./inventory-audit";
import allBrands from"./allBrands";

const Inventory = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Route path={`${match.url}/inventory`} component={inventory} />
			<Route path={`${match.url}/rooms`} component={allRooms} />
			<Route path={`${match.url}/distillate-bucket`} component={distillateBuckets} />
			<Route path={`${match.url}/pricing-groups`} component={pricingGroup} />
			<Route path={`${match.url}/strains`} component={allStrains} />
			<Route path={`${match.url}/product-category`} component={allProductCategories} />
			<Route path={`${match.url}/tax-category`} component={allTax} />
			<Route path={`${match.url}/type`} component={allTypes} />
			<Route path={`${match.url}/waste-management`} component={wasteManagement} />
			<Route path={`${match.url}/additive-templates`} component={allAdditiveTemplate} />
			<Route path={`${match.url}/holded-transfer`} component={allHoldedTransfer} />
			<Route path={`${match.url}/outgoing-transfer`} component={allOutgoingTransfer} />
			<Route path={`${match.url}/incoming-transfer`} component={allIncomingTransfer} />
			<Route path={`${match.url}/outgoing-invoice`} component={outgoingInvoice} />
			<Route path={`${match.url}/select-facility`} component={selectFacility} />
			<Route path={`${match.url}/transfer`} component={transfer} />
			<Route path={`${match.url}/transfer-hold/:order_id`} component={transferHold} />
			<Route path={`${match.url}/invoice`} component={invoice} />
			<Route path={`${match.url}/all-brands`} component={allBrands} />
			<Route path={`${match.url}/edit-product/:id`} component={editProduct} />	
			<Route path={`${match.url}/inventory-audit`} component={inventoryAudit} />	
		
			<Route path={`${match.url}`} component={inventory} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Inventory;