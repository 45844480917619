import PropTypes from "prop-types";
import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { Row, Card, Button, CardBody, FormGroup, Input, CustomInput, Label ,InputGroup,
	InputGroupAddon} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import IntlMessages from "../../helpers/IntlMessages";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, themeColor, CUSTOMER_ORDER_STATUS } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import moment from "moment";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { deleteAllFromCart } from "../../redux/cart/cartActions";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class Checkout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customer_id: 0,
			auto_apply_coupons: [],
			thc_categories: {
				medical_marijuana: [],
				edibles: [],
				non_edibles: [],
			},
			show_ship_address: false,
			create_account: false,
			customer: null,
			customerTypes: [],
			selectedOptions: null,
		};
		this.placeOrderFinal = this.placeOrderFinal.bind(this);
		this.handleChangeCustomer = this.handleChangeCustomer.bind(this);
		this.onChange=this.onChange.bind(this);
	}

	componentDidMount() {

		var facility_id = localStorage.getItem('facility_id');
		var shop_customer_id = localStorage.getItem('shop_customer_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var customerTypes = [];
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('customer_id', shop_customer_id);

		var user_id = localStorage.getItem('user_id');
		if (typeof user_id == 'undefined' || user_id == null) {
			localStorage.setItem('redirect_url', '/checkout');
		}

		axios.post(REST_API_END_POINT + 'front-end/get-place-order-data', formData).then((res) => {
			var data = res.data;
			customerTypes = data.customer_types
			customerTypes = customerTypes.map((element, index) => {
				element.label = element.name;
				element.value = element.id;
				element.key = index;
				return element;
			});
			if (data.status == 1) {
				this.setState({
					customer: data.customer,
					customer_id: shop_customer_id ? shop_customer_id : 0,
					auto_apply_coupons: data.auto_apply_coupons,
					thc_categories: data.thc_categories,
					customerTypes
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	placeOrderFinal(event, errors, values) {
		if (errors.length === 0) {
			if(this.state.selectedOptions==null){
				swal("Warning!", "Please choose Customer Type", "warning")
				return;
		
			}
			const { cartItems, currency } = this.props;
			const { thc_categories, show_ship_address } = this.state;

			const currency_rate = currency.currencyRate;
			const currency_code = currency.currencyName;

			const delivery_date = null;
			const checkout_mode = values.checkout_mode;
			const hypur_transaction_id = '';
			const change_data = null;
			let is_coupon_dis = false;
		    var cart = JSON.parse(JSON.stringify(cartItems));
			var cart_totals = {
				subtotal: 0,
				discount: 0,
				tax: 0,
				total: 0,
				thc_medical: 0,
				thc_edible: 0,
				thc_non_edible: 0,
				auto_apply_discount: 0,
				total_due: 0
			}

			cart = cart?.map(cartItem => {


				let variation = typeof cartItem.variations != 'undefined' ? cartItem.variations.find(single => single.variation_id === cartItem.variation_id) : { amount_of_flower: cartItem.amount_of_flower };

				let row = {};
				row.id = cartItem.id;
				row.name = cartItem.name;
				row.category_id = cartItem.category_id;
				row.sub_category = cartItem.sub_category_id;
				row.qty = cartItem.quantity;
				row.unit_price = cartItem.product_unit_price * currency_rate;
				row.price = (cartItem.price+(cartItem.price*this.state.selectedOptions.tax_rate/100)) * currency_rate;
				row.options = { variation_id: cartItem.variation_id, is_free: 0 };
				row.exise_tax = 0;
				row.sales_tax = cartItem.sales_tax * cartItem.quantity * currency_rate;
				row.tax = (row.exise_tax + row.sales_tax);
				row.free_discount = 0;
				row.coupon_discount = typeof cartItem.coupon_discount != 'undefined' ? cartItem.coupon_discount * currency_rate : 0;
				row.coupon_code = typeof cartItem.coupon_code != 'undefined' ? cartItem.coupon_code : "";
				row.discount = typeof cartItem.discount != 'undefined' ? cartItem.discount : 0;
				row.product_type = cartItem.product_type;
				row.insert_id = cartItem.cartItemId;
				row.deal_id = cartItem.deal_id;
				row.is_mix_match = cartItem.is_mix_match;
				row.deal_products = cartItem.deal_products;
				row.is_finished = 1;
				row.m_discount = 0;
				row.m_discount_type = 1;
				row.package_id = 0;
				row.parent_product = 0;
				row.actual_weight = variation.amount_of_flower;
				row.thc = variation.amount_of_flower;
				row.variation_weight_set = variation.variation_weight_set;
				row.daily_limit_amount = cartItem.daily_limit_amount;
				/*coupon_code: ""
				coupon_discount: 0
				discount: 10*/
				row.tax=parseFloat((row.unit_price* row.qty*this.state.selectedOptions.tax_rate / 100).toFixed(2));
				 cart_totals.tax += row.tax;

				cart_totals.subtotal += parseFloat((row['unit_price'] * row['qty']).toFixed(2));
				cart_totals.discount += parseFloat((row['discount'] + row['coupon_discount']).toFixed(2));
				cart_totals.total += parseFloat(((row['unit_price'] * row['qty'] - row['discount'] - row['coupon_discount'] + row.tax)+(row['unit_price'] * row['qty'] - row['discount'] - row['coupon_discount'] + row.tax)*this.state.selectedOptions.tax_rate/100).toFixed(2));
				
				if (thc_categories.medical_marijuana.indexOf(row['category_id']) != -1 || thc_categories.medical_marijuana.indexOf(row['sub_category']) != -1) {
					cart_totals.thc_medical += parseFloat(row.thc);
				} else if (thc_categories.edibles.indexOf(row['category_id']) != -1 || thc_categories.edibles.indexOf(row['sub_category']) != -1) {
					cart_totals.thc_edible += parseFloat(row.thc);
				} else if (thc_categories.non_edibles.indexOf(row['category_id']) != -1 || thc_categories.non_edibles.indexOf(row['sub_category']) != -1) {
					cart_totals.thc_non_edible += parseFloat(row.thc);
				}

				return row;
			});

			let coupon_discount = 0;
			var coupon_data = localStorage.getItem('shopCartCoupon');

			if (coupon_data != null) {
				coupon_data = JSON.parse(coupon_data);
				if (coupon_data['is_product'] == '1' && coupon_data['type'] == '0') {
					coupon_discount += parseFloat(coupon_data['amount']);
				}

				if (coupon_data['is_product'] == '1' && coupon_data['type'] == '1') {
					coupon_discount += cart_totals.total * parseFloat(coupon_data['amount']) / 100;
				}
			}

			if (cart_totals.total < coupon_discount) {
				coupon_discount = 0;
			}
			
			if (cart_totals.discount == 0) {
				is_coupon_dis = true
				cart_totals.discount += coupon_discount;
				cart_totals.total -= coupon_discount;
			}
		
			document.getElementById("final-place-order-btn").disabled = true;

			const user_id = localStorage.getItem('user_id');
			const customer_id = this.state.customer_id;
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("checkoutForm"));
			formData.append('facility_id', facility_id);
			formData.append('user_id', 0);
			formData.append('customer_id', customer_id);
			formData.append('create_account', this.state.create_account ? 1 : 0);
			formData.append('bogo_code', '');
			formData.append('currency_code', currency_code);
			formData.append('currency_rate', currency_rate);
			//formData.append('checkout_mode',checkout_mode);
			formData.append('delivery_date', delivery_date);
			formData.append('payment_data', null);
			formData.append('hypur_transaction_id', hypur_transaction_id);

			if (!show_ship_address) {
				formData.set('ship_address', formData.get("bill_address"));
				formData.set('ship_zipcode', formData.get("bill_zipcode"));
			}

			formData.append('cart_products', JSON.stringify(cart));

			formData.append('auto_apply_discount', cart_totals.auto_apply_discount * currency_rate);
			if (is_coupon_dis) {
				var coupon_code = localStorage.getItem('shopCartCouponCode');
				formData.append('coupon_discount', cart_totals.discount * currency_rate);
				if (coupon_code != null) {
					formData.append('coupon_code', coupon_code);
				}
			}
			formData.append('total_tax', cart_totals.tax);
			formData.append('thc_medical', cart_totals.thc_medical);
			formData.append('thc_edible', cart_totals.thc_edible);
			formData.append('thc_non_edible', cart_totals.thc_non_edible);
			formData.append('total_thc', parseFloat((cart_totals.thc_medical + cart_totals.thc_edible + cart_totals.thc_non_edible).toFixed(2)));

			axios.post(REST_API_END_POINT + 'front-end/place-order', formData).then((res) => {
				var data = res.data;

				if (data.status == 1) {
					var order_id = data.order_id;
					localStorage.removeItem('shopCartCoupon');
					localStorage.removeItem('shopCartCouponCode');
					this.props.deleteAllFromCart();
					swal("Success!", data.msg, "success").then(() => {
						window.location = "/shop";
					});
				} else {
					var msg =data.msg;
					var tempElement = document.createElement('div');
					tempElement.innerHTML = msg;
					var cleanMsg = tempElement.textContent || tempElement.innerText;
					swal("Warning!", cleanMsg, "warning");
					document.getElementById("final-place-order-btn").disabled = false;
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("final-place-order-btn").disabled = false;
			});
		}
	}
	handleChangeCustomer(type) {
	
		this.setState({ selectedOptions: type })
	}
	onChange = e => {
		switch (e.target.name) {
			case 'medical_card':
				if (e.target.files[0]) {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};
	render() {
		const { cartItems, currency } = this.props;
		const { show_ship_address, create_account } = this.state;
		let cartSubtotalPrice = 0;
		let cartTaxPrice = 0;
		let cartTotalPrice = 0;
		let cartDiscount = 0;
		const customer = this.state.customer;
		let totalPrice = 0;
		if (cartItems && cartItems.length >= 1) {
			let coupon_discount = 0;
			var coupon_data = localStorage.getItem('shopCartCoupon');
			if (coupon_data != null) {
				coupon_data = JSON.parse(coupon_data);
				if (coupon_data['is_product'] == '1' && coupon_data['type'] == '0') {
					coupon_discount += parseFloat(coupon_data['amount']);
				}
			}

			cartItems.map((cartItem, key) => {
				const finalProductPrice = (cartItem.price * currency.currencyRate).toFixed(2);

				cartSubtotalPrice += cartItem.product_unit_price * cartItem.quantity;
				cartTaxPrice += cartItem.sales_tax * cartItem.quantity;
				cartTotalPrice += finalProductPrice * cartItem.quantity;
				totalPrice += finalProductPrice * cartItem.quantity;

				if (this.state.selectedOptions) {
			
					cartTaxPrice = (totalPrice * this.state.selectedOptions.tax_rate / 100)

				}
				cartDiscount += typeof cartItem.coupon_discount != 'undefined' ? cartItem.coupon_discount * currency.currencyRate : 0
				return cartItem;
			});

			if (coupon_data != null) {
				if (coupon_data['is_product'] == '1' && coupon_data['type'] == '1') {
					coupon_discount += cartTotalPrice * parseFloat(coupon_data['amount']) / 100;
				}
			}

			if (cartTotalPrice < coupon_discount) {
				coupon_discount = 0;
			}
			cartDiscount += coupon_discount;
			cartTotalPrice -= cartDiscount;
		}

		return (
			<Fragment>
				<div className="checkout-area pt-95 pb-100">
					<div className="container">

						{cartItems && cartItems.length >= 1 ? (
							<AvForm
								id="checkoutForm"
								className="error-t-negative pl-4 pr-4"
								onSubmit={this.placeOrderFinal}
							>
								<div className="row">
									<div className="col-lg-7">
										<div className="billing-info-wrap">
											<h3>Billing Details</h3>
											<Row className="">
												<Colxx lg="12">
													<AvGroup className="">
														<Label>Name<span className="text-danger">*</span></Label>
														<AvField
															id="first_name"
															name="first_name"
															type="text"
															value={customer != null ? customer.vendor_name : ""}
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please enter the name"
																},
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name"
																},
																minLength: {
																	value: 2,
																	errorMessage: "Name must be between 2 and 100 characters"
																},
																maxLength: {
																	value: 100,
																	errorMessage: "Name must be between 2 and 100 characters"
																}
															}}
														/>
													</AvGroup>
												</Colxx>
												<Colxx md="6">
													<AvGroup className="">
														<Label>Email Address<span className="text-danger">*</span></Label>
														<AvField
															id="email_id"
															name="email"
															type="email"
															value={customer != null ? customer.email_id : ""}
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please enter the email address"
																}
															}}
														/>
													</AvGroup>
												</Colxx>

												<Colxx md="6">
													<AvGroup className="">
														<Label>Phone<span className="text-danger">*</span></Label>
														<AvField
															id="phone"
															name="phone"
															type="text"
															value={customer != null ? customer.phone : ""}
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please enter the phone number"
																},
																pattern: {
																	value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
																	errorMessage: "Invalid phone number"
																}
																
															}}
														/>
													</AvGroup>
												</Colxx>

												<Colxx lg="12">
													<AvGroup className="">
														<Label>Billing Address<span className="text-danger">*</span></Label>
														<AvField
															id="bill_address"
															name="bill_address"
															type="textarea"
															value={customer != null ? customer.vendor_address : ""}
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please enter the billing address"
																}
															}}
														/>
													</AvGroup>
												</Colxx>
												<Colxx lg="12">
													<AvGroup className="">
														<Label>Billing Postcode/Zip<span className="text-danger">*</span></Label>
														<AvField
															id="bill_zipcode"
															name="bill_zipcode"
															type="text"
															value={customer != null ? customer.zip_code : ""}
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please enter the zip code"
																}
															}}
														/>
													</AvGroup>
												</Colxx>
												<Colxx lg="12">
													<AvGroup className="error-t-negative ">
														<Label><IntlMessages id="customer.typeOfCustomer" /><span className="text-danger">*</span></Label>
														<Select
															components={{ Input: CustomSelectInput }}
															className="react-select"
															classNamePrefix="react-select"
															name="customer_type"
															id="customer_type"
															value={this.state.selectedOptions}
															options={this.state.customerTypes}
															// onChange={selectedOptions => { this.setState({ selectedOptions: selectedOptions }) }}
															onChange={selectedOptions => { this.handleChangeCustomer(selectedOptions) }}
														/>
													</AvGroup>
												</Colxx>
												<Colxx lg="12">
													<AvGroup className="error-l-125 error-t-negative">
														<Label>Upload Medical Card <span className="text-danger">*</span></Label>
														<InputGroup className="mb-3">
															<InputGroupAddon addonType="prepend">Upload </InputGroupAddon>
															<CustomInput
																type="file"
																name="medical_card"
																id="medical_card"
																accept="image/*"
																onChange={(e) => this.onChange(e, 'medical_card', this.props)}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "Please upload medical card"
																	}
																}}
															/>
														</InputGroup>
													</AvGroup>
												</Colxx>
												<Colxx lg="12" className="mb-2 d-none">
													<Label>
														<Switch
															className="custom-switch custom-switch-primary im-inline-block"
															checked={show_ship_address}
															onChange={status => { this.setState({ show_ship_address: !this.state.show_ship_address }) }}
														/>
														<span className="h6 ml-4">Ship to different address?</span>
													</Label>
												</Colxx>

												<Colxx lg="12" className={!show_ship_address ? "d-none" : ""}>
													<AvGroup className="">
														<Label>Shipping Address<span className="text-danger">*</span></Label>
														<AvField
															id="ship_address"
															name="ship_address"
															type="textarea"
															autoComplete="off"
															validate={{
																required: {
																	value: show_ship_address,
																	errorMessage: "Please enter the shipping address"
																}
															}}
														/>
													</AvGroup>
												</Colxx>

												<Colxx lg="12" className={!show_ship_address ? "d-none" : ""}>
													<AvGroup className="">
														<Label>Shipping Postcode/Zip<span className="text-danger">*</span></Label>
														<AvField
															id="ship_zipcode"
															name="ship_zipcode"
															type="text"
															autoComplete="off"
															validate={{
																required: {
																	value: show_ship_address,
																	errorMessage: "Please enter the zip code"
																}
															}}
														/>
													</AvGroup>
												</Colxx>

												{customer == null ? <Colxx lg="12" >
													<AvGroup className="">
														<Label>
															<Switch
																className="custom-switch custom-switch-primary im-inline-block"
																checked={create_account}
																onChange={status => { this.setState({ create_account: !this.state.create_account }) }}
															/>
															<span className="h6 ml-4">Create an account?</span>
														</Label>
													</AvGroup>
												</Colxx> : ""}
											</Row>
										</div>

										<div className="additional-info-wrap">
											<h4>Additional information</h4>
											<div className="additional-info">
												<Label>Order notes</Label>
												<AvField
													id="notes"
													name="notes"
													type="textarea"
													autoComplete="off"
													placeholder="Notes about your order, e.g. special notes for delivery. "
													rows="4"
												/>
											</div>
										</div>
									</div>

									<div className="col-lg-5">
										<div className="your-order-area">
											<h3>Your order</h3>
											<div className="your-order-wrap gray-bg-4">
												<div className="your-order-product-info">
													<div className="your-order-top">
														<ul>
															<li>Product</li>
															<li>Total</li>
														</ul>
													</div>
													<div className="your-order-middle">
														<ul>
															{cartItems.map((cartItem, key) => {
																let variation = typeof cartItem.variations != 'undefined' ? cartItem.variations.find(s => s.variation_id === cartItem.variation_id) : { variation_name: "" };

																const finalProductPrice = (cartItem.price * currency.currencyRate).toFixed(2);

																return (
																	<li key={key}>
																		<span className="order-middle-left">
																			{cartItem.name} {variation ? <span className="font-weight-bold">{variation.variation_name != "" ? "(" + variation.variation_name + ")" : ""}</span> : ""} X {cartItem.quantity}
																		</span>{" "}
																		<span className="order-price">
																			{currency.currencySymbol + (finalProductPrice * cartItem.quantity).toFixed(2)}
																		</span>
																	</li>
																);
															})}
														</ul>
													</div>
													<div className="your-order-total">
														<ul className="other-total">
															<li className="order-total">Sub total</li>
															<li>{currency.currencySymbol + cartSubtotalPrice.toFixed(2)}</li>
														</ul>
														<ul className="other-total">
															<li className="order-total">Tax</li>
															<li>{currency.currencySymbol + cartTaxPrice.toFixed(2)}</li>
														</ul>
														<ul className="other-total">
															<li className="order-total">Discount</li>
															<li>{currency.currencySymbol + cartDiscount.toFixed(2)}</li>
														</ul>
														<ul className="mt-2">
															<li className="order-total">Total</li>
															<li>
																{currency.currencySymbol + parseFloat(cartTotalPrice + cartTaxPrice).toFixed(2)}
															</li>
														</ul>
													</div>
												</div>
												<div className="payment-method">
													<h3>Payment Method</h3>
													<AvGroup className="">
														<AvField
															id="checkout_mode"
															name="checkout_mode"
															type="select"
															defaultValue="Express"
															autoComplete="off"
															validate={{
																required: {
																	value: true,
																	errorMessage: "Please select the "
																}
															}}
														>
															<option value="Express">Express checkout</option>
															<option value="Pick up in store">Pick up in store</option>
														</AvField>
													</AvGroup>
												</div>
											</div>
											<div className="place-order mt-3">
												<Button className="btn-hover" id="final-place-order-btn" >Place Order</Button>
											</div>
										</div>
									</div>
								</div>
							</AvForm>
						) : (<div className="row">
							<div className="col-lg-12">
								<div className="item-empty-area text-center">
									<div className="item-empty-area__icon mb-30">
										<i className="fas fa-cash-register" />
									</div>
									<div className="item-empty-area__text">
										No items found in cart to checkout <br />{" "}
										<Link to={"/shop"} className="btn btn-primary">Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	};
};

Checkout.propTypes = {
	cartItems: PropTypes.array,
	currency: PropTypes.object,
	deleteAllFromCart: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		cartItems: state.cartData,
		currency: state.currencyData
	};
};


const mapDispatchToProps = dispatch => {
	return {
		deleteAllFromCart: addToast => {
			dispatch(deleteAllFromCart(addToast));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
