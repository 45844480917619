import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvInput, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table, InputGroup, InputGroupAddon, Input
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from "../../../components/common/CustomDateTimePicker";
import { zeroPad } from "../../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_PAYMENTS, DEFAULT_QTY_TYPES } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class UploadTestResultModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			choose_date:null
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeChooseDate=this.handleChangeChooseDate.bind(this);
	
    }
	handleChangeChooseDate = date => {
		this.setState({
			choose_date: date
		});
	};
		
	handleSubmit(event, errors, values) {
		let errorMessage=""
		if(this.state.choose_date.length==0){
			errorMessage="Please choose date";
			swal("Warning",errorMessage,"warning");
			return false;
		}
		document.getElementById("choose-date").disabled=true;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);

		var user_id = localStorage.getItem('user_id');
		var formData = new FormData(document.getElementById("approveForm"));
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);

		axios.post(REST_API_END_POINT+'receive-inventory/sync-metrc-orders',formData).then((res)=>{

			var data = res.data;
			if(data.status==1){
				this.props.dataListRender()
				this.props.toggleModal()

			}else{
				this.props.dataListRender()
				this.props.toggleModal()
				swal("Warning!","Failed to fetch Orders","warning");
			}			
		})
	}
	
    render() {
		const { order_data, order_products } = this.state;
		const current_currency_symbol="$";
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Choose Date
				</ModalHeader>
				<Row>
				<Colxx lg="12 ml-4 mt-4">
				<h3> Pick Orders contain Package Label Only</h3>
				</Colxx>
				</Row>
				
				<AvForm
					id="approveForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Choose Date<span className="text-danger">*</span></Label>
								<DatePicker
									id="choose_date"
									name="choose_date"
									autoComplete="off"
									selected={this.state.choose_date}
									onChange={this.handleChangeChooseDate}
									placeholderText=""
									dateFormat="DD/MM/YYYY"
									maxDate={moment()}
								  />
							</AvGroup>
						</Colxx>					
					</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="choose-date">
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
