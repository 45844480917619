import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle, Form, Label, Input, Button, Container, ListGroup, ListGroupItem, Badge,DropdownMenu, DropdownItem, CustomInput, FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";


import classnames from "classnames";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import swal from 'sweetalert';
import number_format from "number_format-php";

import { zeroPad } from "../../helpers/Utils";
import { loginUser } from "../../redux/actions";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import InvoiceModal from "../../containers/pointofsale/InvoiceModal";
import ViewOrderModal from "../../containers/pointofsale/ViewOrderModal";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL, SITE_URL, projectTitle, CUSTOMER_ORDER_STATUS } from "../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class AllOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
			customer:[],
			modalOpenInvoice:false,
			modalOpenViewOrder:false,
        };
        this.dataListRender = this.dataListRender.bind(this);
		this.toggleModalViewOrder = this.toggleModalViewOrder.bind(this);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);
        
    }

    componentDidMount() {
        document.body.classList.add("background");
        document.body.classList.add("background-main-pages");
        var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
            this.dataListRender();
        } else {
            window.location = '/';
        }
    }
	
    componentWillUnmount() {
        document.body.classList.remove("background");
        document.body.classList.remove("background-main-pages");
    }

    dataListRender() {
        var facility_id = localStorage.getItem('facility_id');
        facility_id = dataCrypto.decrypt(facility_id);
		//const user_id = localStorage.getItem('user_id');
		const customer_id = localStorage.getItem('shop_customer_id');
		
        var data_upload = new FormData();
        data_upload.append('facility_id', facility_id);
        //data_upload.append('user_id', user_id);
        data_upload.append('customer_id', customer_id);
        axios.post(REST_API_END_POINT + 'point-of-sale/all-orders', data_upload).then((res) => {
            if (res.data.status == 1) {
				let data = res.data;
				if(data.orders){
					let orders = data.orders.map(row=>{
						if(row.delivery_date=='0'){
							row.delivery_date='';
						}else{
							row.delivery_date = moment(row.delivery_date*1000).format('MM/DD/YYYY HH:mm');
						}
						
						if(row.payment_status==1&&row.refund==0){ 
							row.payment_status_text='Paid'; 
						}else if(row.refund==1){ 
							row.payment_status_text='Refunded'; 
						}else{ 
							row.payment_status_text='Unpaid'; 
						}
						
						if(row.type_of_purchase==1){ 
							row.type_of_purchase_text='Shop'; 
						}else if(row.type_of_purchase==2){ 
							row.type_of_purchase_text='POS'; 
						}else{ 
							row.type_of_purchase_text=''; 
						}
						
						let order_status = CUSTOMER_ORDER_STATUS.find(r=>{ return r.value==row.status });
						
						if(row.status==1){
							if(row.shipping_status==1){
								order_status =  { label: "Ready to Ship", value: "6", badge: "secondary", key: 6 };
							}else if(row.shipping_status==2){
								order_status =  { label: "Shipped", value: "7", badge: "secondary", key: 7 };
							}
						}
						
						if(order_status!=null){
							row.status_text = order_status.label;
							row.status_badge = <Badge color={order_status.badge} style={{ whiteSpace: 'break-spaces' }} >{order_status.label}</Badge>;
						}else{
							row.status_text ="";
							row.status_badge ="";
						}
						
						return row;
					})
					this.setState({ orders ,
									customer:data.customer,
								});
				}else{
					this.setState({
						orders: [],
						customer:[],
					});
				}
            }
        }).catch(e => {
            console.log("Addition failed , Error ", e)
            this.setState({ blocking: false });
        });
    }
		
	toggleModalViewOrder = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenViewOrder: !this.state.modalOpenViewOrder
		});
	};
	
	toggleModalInvoice = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};
	
	cancelOrders = (e,selectedIds)  => {
		
		var msg="Cancel this order?";
        var success_msg="The order has been canceled.";
        var failure_msg="Failed to cancel order";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'point-of-sale/cancel-orders',formData).then((res)=>{
					var data = res.data;
				
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
    render() {
		var current_currency_symbol = '$';
        let data = this.state.orders;
		let customer=this.state.customer;
		if (this.state.search) {
			data = data.filter(row => {
				if(row.vendor_name==null){
					row.vendor_name='';
				}
				return row.order_number.includes(this.state.search) || row.vendor_name.toLowerCase().includes(this.state.search) || row.payment_mode.includes(this.state.search) || row.payment_status_text.includes(this.state.search) || row.status_text.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Order ID",
				accessor: "order_number",
				width:100,
				Cell: props => <p className="list-item-heading mb-0">{zeroPad(props.value,6)}</p>
			},
			{
				Header: "Order Date",
				accessor: "timestamp",
				width:120,
				Cell: props => <p className="text-muted mb-0">{moment(props.value*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted mb-0">{current_currency_symbol+""+number_format(parseFloat(props.value),2,'.','')}</p>
			},
			{
				Header: "Payment Mode",
				accessor: "payment_mode",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},			
			{
				Header: "Payment Status",
				accessor: "payment_status_text",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status_badge",
				width:140,
				Cell: props => props.value
			},
			{
				Header: "Options",
				accessor: "order_id",
				width:100,
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalViewOrder(e,props.value) }}>
									<span className="d-inline-block">View</span>
								</DropdownItem>
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalInvoice(e,props.value) }} >
									<span className="d-inline-block">Invoice</span>
								</DropdownItem>
								{props.original.status!=4 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.cancelOrders(e,[props.value]) }} >
									<span className="d-inline-block" >Cancel</span>
								</DropdownItem>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
        
        return (
            <Fragment>
				<Row>
					<Colxx xxs="9">
						<Breadcrumb heading="menu.all-orders" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
					<Colxx xxs="3">
						<h5 className="mb-5"><strong>Current Loyality Points: </strong>{customer != null ? customer.price_points : "0"}</h5>
					</Colxx>
				</Row>
				<div className="mb-4 dropdown-edit">					
					<FormGroup row>
						<Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
							/>
					  </Colxx>
					</FormGroup>
					
					<Row>          
						<Colxx lg="12">
							<ReactTable
							  data={data}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  minRows={4}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={true}
							  defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</Colxx>
					</Row>
				</div>
				
				<ViewOrderModal
					modalOpen={this.state.modalOpenViewOrder}
					toggleModal={this.toggleModalViewOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
					hideReply={true}
				/>	
				
				<InvoiceModal
					modalOpen={this.state.modalOpenInvoice}
					toggleModal={this.toggleModalInvoice}
					order_id={this.state.order_id}
				/>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default AllOrders;
