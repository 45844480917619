import { uuid } from "uuidv4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  APPLY_COUPON,
  REMOVE_COUPON
} from "./cartActions";

const initState = [];

const applyCouponToItems = (cartItems) =>{
	const shopCartCouponCode = localStorage.getItem('shopCartCouponCode');
	var coupon_data = localStorage.getItem('shopCartCoupon');
	var finalShopCartItems=[];
	var couponDiscount=0;
	var coupon_discount=0;
	let cartSubtotalPrice=0;
	if(coupon_data){
		coupon_data = JSON.parse(coupon_data);
		
		if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
			coupon_discount += parseFloat(coupon_data['amount']);
		}
		
		finalShopCartItems = cartItems.map(row =>{
			let product_ids= coupon_data['is_product']!='0' ? coupon_data['product_id'].split(','):[];
			let	category_ids=coupon_data['is_product']!='0' ? coupon_data['category_id'].split(','):[];
			let product_discount = 0;

			if((product_ids.indexOf(row.id.toString())!=-1 && coupon_data['is_product']=='3') || (category_ids.indexOf(row.category_id.toString())!=-1 && coupon_data['is_product']=='4') || (category_ids.indexOf(row.sub_category_id.toString())!=-1 && coupon_data['is_product']=='4') || coupon_data['is_product']=='2' || coupon_data['is_product']=='0'){
				if(coupon_data['type']=='0'){
					product_discount=parseFloat(coupon_data['amount']);	
				}else{ 
					product_discount=((row.product_unit_price+row.sales_tax)*row.quantity)*parseFloat(coupon_data['amount'])/100;
				}
				if(product_discount>parseFloat(((row.product_unit_price+row.sales_tax)*row.quantity).toFixed(2))){							
					product_discount=0;
				}
			}					
			row.coupon_discount = parseFloat(product_discount.toFixed(2));
			couponDiscount+=row.coupon_discount;
			if(row.coupon_discount>0){
				row.coupon_code = shopCartCouponCode;
			}
			cartSubtotalPrice += (row.product_unit_price+row.sales_tax) * row.quantity;
			return row;
		});
		
		if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
			coupon_discount+=cartSubtotalPrice*parseFloat(coupon_data['amount'])/100;
		}

		
		if(cartSubtotalPrice<coupon_discount){
			coupon_discount=0;
		}
		couponDiscount+=coupon_discount;
	}else{
		finalShopCartItems = cartItems.map(row =>{
			row.coupon_code = '';
			row.coupon_discount = 0;
			return row;
		});
	}
		
	return { cartItems:finalShopCartItems, coupon_discount:couponDiscount  };
}

const cartReducer = (state = initState, action) => {
	const shopCartItems = localStorage.getItem('shopCartItems');
	const cartItems = typeof shopCartItems!='undefined' && shopCartItems!=null ? JSON.parse(shopCartItems):[];
    const product = action.payload;
	 console.log('##########1233633333333',product);

	if (action.type === ADD_TO_CART) {    
		const cartItem = cartItems.filter(
			item =>
			  item.id === product.id && item.variation_id === product.variation_id &&
			  (product.cartItemId ? product.cartItemId === item.cartItemId : true) && (product.deal_id ? product.deal_id === item.deal_id : true)
		)[0];
		
		if (cartItem === undefined) {
			const finalShopCartItems = applyCouponToItems([
			  ...cartItems,
			  {
				...product,
				quantity: product.quantity ? product.quantity : 1,
				variation_id: product.variation_id,
				cartItemId: uuid()
			  }
			]);
			localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
			return finalShopCartItems.cartItems;
		} else {
			const finalShopCartItems = applyCouponToItems(cartItems.map(item =>
			  item.cartItemId === cartItem.cartItemId
				? {
					...item,
					variation_id: product.variation_id,
					quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
				  }
				: item
			));
			localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
			return finalShopCartItems.cartItems;
		}
	}
	
	if (action.type === APPLY_COUPON) {
		localStorage.setItem('shopCartCoupon', JSON.stringify(product.coupon_data));
		const finalShopCartItems = applyCouponToItems(cartItems);
		
		if(finalShopCartItems.coupon_discount>0){
			var coupon_code_target = document.getElementById('coupon_code');
			if(coupon_code_target){
				coupon_code_target.value='';
			}
			if(product.addToast){
				product.addToast("Coupon has been applied successfully", {
					appearance: "success",
					autoDismiss: true
				});
			}
		}else{
			if(product.addToast){
				product.addToast("Coupon is invalid", {
					appearance: "error",
					autoDismiss: true
				});
			}
		}
		
		localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
		return finalShopCartItems.cartItems;		
	}
	
	if (action.type === REMOVE_COUPON) {
		localStorage.removeItem('shopCartCoupon');
		localStorage.removeItem('shopCartCouponCode');
		const finalShopCartItems = applyCouponToItems(cartItems);
		localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
		return finalShopCartItems.cartItems;		
	}

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
		const finalShopCartItems = applyCouponToItems(remainingItems(cartItems, product));
		localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
		return finalShopCartItems.cartItems;
    } else {
		const finalShopCartItems = applyCouponToItems(cartItems.map(item =>
			item.cartItemId === product.cartItemId
			  ? { ...item, quantity: item.quantity - 1 }
			  : item
		));
		localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems.cartItems));
		return finalShopCartItems.cartItems;
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) => cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    const finalShopCartItems = remainingItems(cartItems, product);
	localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems));
	return finalShopCartItems;
  }

  if (action.type === DELETE_ALL_FROM_CART) {   
	const finalShopCartItems = [];
	localStorage.setItem('shopCartItems', JSON.stringify(finalShopCartItems));
	return finalShopCartItems;
  }

  return cartItems;
};

export default cartReducer;
