import React, {Fragment, Component } from "react";
import {
  Row, Card,Button
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";
import { projectTitle, REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL } from "../../constants/defaultValues";
import { uuid } from 'uuidv4';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
const dataCrypto = new SimpleCrypto(dataEncryptKey);

class HomeFooter extends Component{
	constructor(props) {
		super(props);

		this.state = {
			configurations: {
				facility_footer_logo:null
			},
			userCookie: localStorage.getItem('user_cookie_token')
		};
	}
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	render() {	
		const { userCookie, configurations } = this.state;
		return (
			<Fragment>
				<Row className="black-bg footer-layout">
					<Colxx xxs="12">
						<Card className="auth-card main-footer-wrapper no-shadow py-2">
							<div className="container">
								<Row className="w-100">
									<Colxx xxs="12" lg="4" xxl="3" >
										<div className="d-flex flex-wrap-wrap align-items-center mt-2">
											<div className="d-block w-100">
												{configurations.facility_footer_logo==null ? <img src="/assets/img/logo-black.svg" className="img-responsive" style={{ filter: 'invert(1)' ,height:'100px'}} />:<img src={BASE_URL+'pages/'+configurations.facility_footer_logo} className="img-responsive"  />}
												<p className="mt-3 mb-0" style={{whiteSpace: 'pre-wrap'}}>{configurations.footer_about}</p>
											</div>											
										</div>								
									</Colxx>
									<Colxx xxs="12" lg="8" xxl="9" >
										<Row>
											<Colxx xxs="12" lg="4" >
												<h2 className="bottom-line mt-3"><strong>Location</strong></h2>
												<p style={{whiteSpace: 'pre-wrap'}}>{configurations.website_locations}</p>
											</Colxx>
											<Colxx xxs="12" lg="4" >
												<h2 className="bottom-line mt-3"><strong>Working Hours</strong></h2>
												<p style={{whiteSpace: 'pre-wrap'}}>{configurations.working_hours}</p>
											</Colxx>
											<Colxx xxs="12" lg="4" >
												<h2 className="bottom-line mt-3"><strong>STAY CONNECTED</strong></h2>
												<div className="social-icons dark mt-20">
													<a target="_blank" href={configurations.social_twitter_link}><i className="fab fa-twitter" /></a>
													<a target="_blank" href={configurations.social_facebook_link}><i className="fab fa-facebook-f" /></a>
													<a target="_blank" href={configurations.social_instagram_link}><i className="fab fa-instagram"></i></a>
													<a target="_blank" href={configurations.social_linkedin_link}><i className="fab fa-linkedin-in"></i></a>
												</div>
												<div className="mt-2 px-3">
													<img src="/assets/img/weedmaps-logo.png" className="img-responsive" />
												</div>
											</Colxx>
										</Row>
									</Colxx>
								</Row>
							</div>
						</Card>
					</Colxx>
				</Row>
				<div className="gray-bg footer-layout main-footer-wrapper">
					<div className="container py-2">
						<Row>
							<Colxx md="5" >
								<p className="d-flex flex-wrap-wrap align-items-center h-100">@2021 {projectTitle} | Powered by Tridant</p>
							</Colxx>
							<Colxx md="7">
								<ul className="main-footer-links d-flex flex-wrap-wrap align-items-center h-100">
									<li><NavLink to={`#`} className="">Terms & Conditions</NavLink></li>
									<li><NavLink to={`#`} className="">Privacy Policy</NavLink></li>
									<li><NavLink to={`#`} className="">Cookies Policy</NavLink></li>
								</ul>
							</Colxx>
						</Row>
					</div>
				</div>
				{userCookie==null ? <div className="cookie-policy" style={{position:"fixed",bottom:"0",zIndex:"99999", width:'100%'}}>
					 <Row>
						 <Colxx lg={12}>
							  <div className="pl-4 pr-4 mt-4">
								  <h3 style={{fontSize: "1.75rem",lineHeight: "2rem",color:"#000"}}>COOKIE POLICY</h3>
							  </div>
							  <div>
								  <h5 className="underground-cookies-text pl-4 pr-4 black">
									We process personal information, including by placing cookies and other tracking technologies, for certain legitimate purposes to improve your experience and the services we provide. If you accept this processing, please click "Accept."
								  </h5>
							  </div>
							  <div className="pl-2 pr-2 mb-4">
									 <Button className="btn-secondary ml-2 mr-2">LEARN MORE</Button>
									 <Button className="btn-secondary ml-2 mr-2" onClick={()=>{
											let user_cookie_token = uuid()
											localStorage.setItem('user_cookie_token',user_cookie_token);
											this.setState({userCookie:user_cookie_token});
									 }}>ACCEPT</Button>
							  </div>
						 </Colxx>
					 </Row>
				</div>:""}
			</Fragment>
	  );
  }
};

export default HomeFooter;
