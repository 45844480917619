import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import { REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE } from "../../../constants/defaultValues";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AssignDrawerModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			selectStaff: null,
			banktotal: {
				'1_s': 0,
				'2_s': 0,
				'5_s': 0,
				'10_s': 0,
				'20_s': 0,
				'50_s': 0,
				'100_s': 0,
				'cents': 0,
				'nickles': 0,
				'dimes': 0,
				'quarters': 0,
				'50_cents': 0,
				'1_d': 0
			},
            staffs:[],
           
			currency_code: 'USD',
			assigned_to: this.props.assigned_to,
            drawerStartingAmount:this.props. drawerStartingAmount
		
		};

	}

    componentDidMount(){
        if(this.props.modalOpen){
        var staffs=this.props.staffs;
        var assigned_to=this.props.assigned_to;
       console.log('#######',staffs);
       
        staffs=staffs.concat(assigned_to);
       
    
        this.setState({
            staffs,
            }
        )
        } 
        
    }
	handleSubmit(event, errors, values) {

		if (errors.length === 0) {

			document.getElementById("add-drawer").disabled = true;
             if(this.state.assigned_to.length==0){
				swal("Please Choose Bud-tenders ");
				 document.getElementById("add-drawer").disabled = false;
				return
				
			 }
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("drawerForm"));
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
			formData.append('currency_code', this.state.currency_code);

			axios.post(REST_API_END_POINT + 'drawer/check-bank-stock', formData).then((res) => {
				var data = res.data;
				if (data.proceed) {
					var formData = new FormData(document.getElementById("drawerForm"));
					formData.append('facility_id', facility_id);
					formData.append('user_id', user_id);
					formData.append('drawer_id', this.props.drawer_id);
					formData.append('currency_code', this.state.currency_code);

					axios.post(REST_API_END_POINT + 'drawer/edit-assigned-budtender', formData).then((res) => {
						var data = res.data;

						var api_message = document.getElementById("api_message");
						api_message.innerHTML = "<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

						window.setTimeout(function () {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);

						JQUERY_MODULE.$('.alert .close').on("click", function (e) {
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						});

						document.getElementById("add-drawer").disabled = false;
						this.props.toggleModal();
						this.setState({ assigned_to:[],banktotal: { '1_s': 0, '2_s': 0, '5_s': 0, '10_s': 0, '20_s': 0, '50_s': 0, '100_s': 0, 'cents': 0, 'nickles': 0, 'dimes': 0, 'quarters': 0, '50_cents': 0, '1_d': 0 } })

						this.props.dataListRender();

					}).catch(e => {
						console.log("Addition failed , Error ", e);
						document.getElementById("add-drawer").disabled = false;
					});
				} else {
					swal("There is no enough money in the internal bank");
					document.getElementById("add-drawer").disabled = false;
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("add-drawer").disabled = false;
			});

		}
	}

	render() {
      var drawerStartingAmount=this.state.drawerStartingAmount
      var staffs=this.state.staffs
		var banktotal = this.state.banktotal;

		if (this.state.banktotal != null) {
			var total = parseInt(banktotal['1_s']) + parseInt(banktotal['2_s']) * 2 + parseInt(banktotal['5_s']) * 5 + parseInt(banktotal['10_s']) * 10 + parseInt(banktotal['20_s']) * 20 + parseInt(banktotal['50_s']) * 50 + parseInt(banktotal['100_s']) * 100 + parseInt(banktotal['cents']) * 0.01 + parseInt(banktotal['nickles']) * 0.05 + parseInt(banktotal['dimes']) * 0.1 + parseInt(banktotal['quarters']) * 0.25 + banktotal['50_cents'] * 0.5 + parseInt(banktotal['1_d'])
		} else {
			var total = 0;
		}

		const currencies = this.props.currencies;
		const selected_currency = currencies.find(row => { return row.currency_code == this.state.currency_code });
		var field_view = [];
		if (typeof selected_currency != 'undefined') {
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for (let key in currency_struct) {

				field_view.push(<Fragment key={key}>
					<Colxx xxs="3" className="mt-4 d-none">
						<img alt={currency_struct[key].alt} src={REST_API_BASE_URL + currency_struct[key].src} className="img-responsive" />
					</Colxx>
					<Colxx xxs="3" className="mt-4 d-none">
						<AvGroup className="error-t-negative">
							<AvField
								id={key}
								name={key}
								type="number"
								placeholder="0"
								min="0"
								step="1"
								value={this.state.banktotal[key] != 0 ? this.state.banktotal[key] : ''}
								onChange={(e) => {
									var banktotal = this.state.banktotal;
									if (e.target.value != '') {
										banktotal[key] = e.target.value;
									} else {
										banktotal[key] = 0;
									}
									this.setState({
										banktotal
									});
								}}

							/>
						</AvGroup>
					</Colxx>
				</Fragment>)

			}
		}

		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Assign to Bud-tender
				</ModalHeader>
				<AvForm
					id="drawerForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row className="mt-2 d-none">
							<Colxx xxs="3">
								<Label>Currency</Label>
								<AvField
									type="select"
									name="currency_code"
									id="currency_code"
									value={this.state.currency_code}
									onChange={(e) => { this.setState({ currency_code: e.target.value }) }}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								>
									{currencies.map(opt => {
										return <option key={opt.id} value={opt.currency_code} >{opt.country_name + " - " + opt.currency_code}</option>
									})}
								</AvField>
							</Colxx>
						</Row>

						<Row>
							<Colxx xxs="12">
								<AvGroup className="error-t-negative">
									<Label>Select Bud-tender</Label>
									
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										isMulti
										name="staffs[]"
										value={this.state.assigned_to}
										onChange={assigned_to => { this.setState({ assigned_to: assigned_to }) }}
										options={this.state.staffs}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>

						{/* <Row className="align-items-center">
							{field_view}
						</Row> */}

						<Row className="mt-4 ">
							<Colxx xxs="3">
								<h3>Starting Amount</h3>
							</Colxx>
							<Colxx xxs="8">
								<AvGroup className="error-t-negative">
									<AvField
										id="total"
										name="total"
										type="text"
										// readOnly={true}
										placeholder="0"
										value={drawerStartingAmount?drawerStartingAmount:""}
									/>
								</AvGroup>
							</Colxx>
							<Colxx xxs="2"></Colxx>
							<Colxx xxs="3"></Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-drawer" >
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default AssignDrawerModal;
