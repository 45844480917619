import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
//import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import DatePicker from "../../../components/common/CustomDateTimePicker";
import moment from 'moment';
import swal from 'sweetalert';

import Select from "react-select";
import {AvInput,AvFeedback, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const couponTypes = [
	{ label: "$", value: "0", key: 0 },
	{ label: "%", value: "1", key: 1 }
];

const UsageLimitOptions = [
	{ label: "--Select Limit--", value: "0", key: -1 },
	{ label: "Per Day", value: "1", key: 0 },
	{ label: "Per Week", value: "2", key: 1 },
	{ label: "Per Month", value: "3", key: 2 },
	{ label: "Per Year", value: "4", key: 3 }
];

const selectType = [
	{ label: "--Select Limit--", value: "0", key: -1 },
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
]; 

class EditNewCouponModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			start_date: null,
			end_date: null,
			selectedType:{ label: "$", value: "0", key: 0 },
			auto_apply_yes:true,
			auto_apply_yes_value:1,
			auto_apply_no_value:0,
			auto_apply_no:false,
			selectProducts:[],
			selectCategories:[],
			product_val:[],
			category_val:[],
			use_limit_type: { label: "--Select Limit--", value: "0", key: -1 },
			customer_type: { label: "--Select Customer Type--", value: "0", key: -1 },
			discountCode: null
		};
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			let vStartDate = document.getElementById("start_date").value.trim();
			let vEndDate = document.getElementById("end_date").value.trim();
			
			let errorMessage='';
			if(vStartDate.length==0){
				errorMessage+="Start Date is empty\n";
			}
			if(vEndDate.length==0){
				errorMessage+="End Date is empty\n";
			}

			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			var productVal= this.state.product_val.map(row=>row.value);
			var categoryVal= this.state.category_val.map(row=>row.value);			

			document.getElementById("update-coupon").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("couponForm"));
			formData.append('discount_id',this.props.discount_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('product_id',productVal);
			formData.append('category_id',categoryVal);
			
			axios.post(REST_API_END_POINT+'point-of-sale/edit-discount',formData).then((res)=>{
				var data = res.data;
				var couponDiscount=this.state.couponDiscount;
				couponDiscount.name=values.name;
				couponDiscount.code=values.code;
				couponDiscount.amount=values.amount;
				couponDiscount.type=values.type;
				couponDiscount.start_date=formData.get('start_date');
				couponDiscount.end_date=formData.get('end_date');
				couponDiscount.use_limit=values.use_limit;
				couponDiscount.use_limit_type=values.use_limit_type;
				couponDiscount.max_uses=values.max_uses;
				couponDiscount.is_product=values.is_product;
				couponDiscount.category_val=values.category_val;
				couponDiscount.product_val=values.product_val;
				couponDiscount.auto_apply=values.auto_apply;
				couponDiscount.customer_type=values.customer_type;
				this.setState({ couponDiscount:couponDiscount });
			
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
			    document.getElementById("update-coupon").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-coupon").disabled=false;
			});
		}
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){
			let couponDiscount;
			
            if (prevProps.discount_id !== this.props.discount_id) {
				var formData = new FormData();
				formData.append('discount_id',this.props.discount_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/get-discount-by-id',formData).then((res)=>{
					var data = res.data;
					var selectedType = null;
					var use_limit_type = null;
					var customer_type = null;
					var auto_apply_yes = true;
					var auto_apply_no = false;
					
					couponDiscount = data.discount;
			 
					couponTypes.forEach((element,index) => {
						if(element.value==couponDiscount.type){
							selectedType=element;
						}
					});
					
					UsageLimitOptions.forEach((element,index) => {
						if(element.value==couponDiscount.use_limit_type){
							use_limit_type = element;
						}
					});
			
					selectType.forEach((element,index) => {
						if(element.value==couponDiscount.customer_type){
							customer_type = element;
						}
					});						
					
					if(couponDiscount.auto_apply==1){
						auto_apply_yes = true;
						auto_apply_no = false;
					}else{
						auto_apply_yes = false;
						auto_apply_no = true;
					}
					
					let Ids = couponDiscount.product_id.split(',');
					var product_val = this.state.selectProducts.filter(row=>{ return Ids.indexOf(row.value)!==-1 });
					
					Ids = couponDiscount.category_id.split(',');
					var category_val = this.state.selectCategories.filter(row=>{ return Ids.indexOf(row.value)!==-1 })
					
					this.setState({ 
						couponDiscount,
						selectedType,
						use_limit_type,
						customer_type,
						auto_apply_yes,
						auto_apply_no,
						product_val,
						category_val,
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}
  
	componentDidMount() {		
		var products=[];
		var categories=[];
		var discountCode=null;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/get-product-and-category-for-discount',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			categories=data.categories;
			discountCode=data.discount_code;
			
			products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectProducts:products,
				selectCategories:categories,
				discountCode:discountCode
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	showRadio = e =>{
		var elements = document.querySelectorAll('input[name="is_product"]');
		var value='0';
		for (var element of elements) {
			if(element.checked==true){
				value=element.value;
			}
		}
	
		if(value==='1'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='2'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='3'){
			document.getElementById('show_products').style.display='block';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='4'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='block';
		}
	}
	
	onChange = e => {
		switch (e.target.name) {
			case 'profile_pic':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
			break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	render() {
		const {  selectProducts,selectCategories,start_date,end_date } = this.state;
		var product_val=[];
		var category_val=[];
		const today = new Date();
		const checkInValidationMessage = "This field is required";
		
		if(this.state.couponDiscount!=null){
			var couponDiscount=this.state.couponDiscount;
		}else{
			var couponDiscount;
		}
		
	  return (

		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Discount Program
		  </ModalHeader>
		  <AvForm
		        id="couponForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							value={ couponDiscount?((couponDiscount.name!=null)?couponDiscount.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The  name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Code<span className="text-danger">*</span></Label>
						  <AvField
							id="code"
							name="code"
							type="text"
							readOnly={true}
							autoComplete="off"
							value={ couponDiscount?((couponDiscount.code!=null)?couponDiscount.code:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Discount<span className="text-danger">*</span></Label>
						  <AvField
							id="amount"
							name="amount"
							type="number"
							autoComplete="off"
                           	value={ couponDiscount?((couponDiscount.amount!=null)?couponDiscount.amount:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "Please enter numeric value"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<Label>Type<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							name="type"
							id="type"
							value={this.state.selectedType}
							options={couponTypes}
							onChange={selectedType => { this.setState({ selectedType }) }}									
						/>						
					</Colxx>
				</Row>
				<Row className="mt-2">
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Start Date<span className="text-danger">*</span></Label>
							<DatePicker
								id="start_date"
								name="start_date"
								minDate={new Date()}
								onChange={e => {
									let tempEndDate=null;
									if(this.state.end_date){
										tempEndDate=this.state.end_date.unix();
									}else{
										if(couponDiscount){
											tempEndDate=(couponDiscount.end_date)?moment(couponDiscount.end_date,"YYYY-MM-DD").unix():0;
										}
									}

									if(tempEndDate<e.unix()){
										swal("Warning", "End date should be greater than start date!", "warning");
									}else{
										this.setState({start_date: e })
									}
								}}
								selected={ (this.state.start_date)?this.state.start_date:(couponDiscount?((couponDiscount.start_date)?moment(couponDiscount.start_date,'YYYY-MM-DD'):null):null) }
								placeholderText=""
								dateFormat="YYYY-MM-DD"								
							/>
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>End Date<span className="text-danger">*</span></Label>
							 <DatePicker
								id="end_date"
								name="end_date"
								minDate={(start_date)?start_date:(couponDiscount?((couponDiscount.start_date)?moment(couponDiscount.start_date,'YYYY-MM-DD'):(new Date())):(new Date()))}
								selected={ (this.state.end_date)?this.state.end_date:(couponDiscount?((couponDiscount.end_date)?moment(couponDiscount.end_date,'YYYY-MM-DD'):null):null) }
                                onChange={e =>{
									let tempStartDate=((start_date)?start_date:couponDiscount.start_date);
									if(tempStartDate){
										this.setState({end_date: e })
									}else{
										swal("Warning", "End Date should be greater than!", "warning").then(()=>{
											this.setState({end_date:null});
										});
									}
								}}
								placeholderText=""
								dateFormat="YYYY-MM-DD"
								required={true}
								min={today}
                                validationMessage={checkInValidationMessage}

							  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Usage Limit</Label>
						  <AvField
							id="use_limit"
							name="use_limit"
							value={ couponDiscount?((couponDiscount.use_limit!=null)?couponDiscount.use_limit:''):'' }
							min='0'
							type="number"
							autoComplete="off"
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-l-100 error-t-negative">
							<Label>&nbsp;&nbsp;</Label>
						   <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="use_limit_type"
								id="use_limit_type"
								value={this.state.use_limit_type}
								onChange={use_limit_type => { this.setState({ use_limit_type:use_limit_type }) }}
								options={UsageLimitOptions}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Maximum use per customer<span className="text-danger">*</span></Label>
						  <AvField
							id="max_uses"
							name="max_uses"
							type="number"
							autoComplete="off"
							value={ couponDiscount?((couponDiscount.max_uses!=null)?couponDiscount.max_uses:''):'' }
                            min='0'
							validate={{
								required: {
									value: true,
									errorMessage: "Please enter numeric value"
								}
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="mt-2 mb-2">
						<Label >Eligible Products </Label>	&nbsp;&nbsp;
						<AvRadioGroup
							inline
							name="is_product"
							onChange={(e) => this.showRadio(e)}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the options"
							  }
							}}
							value={ couponDiscount?((couponDiscount.is_product!=null)?couponDiscount.is_product:'1'):1}
							>
							<AvRadio customInput label="No Product" value="1" />
							<AvRadio customInput label="All Products" value="2" />
							<AvRadio customInput label="Specific Products" value="3" />
							<AvRadio customInput label="Specific Categories" value="4" />
						</AvRadioGroup >
					</Colxx>

				</Row>
				<div id="show_categories" style={{display:couponDiscount?((couponDiscount.is_product!=null&& couponDiscount.is_product==4)?'block':'none'):'none'}}>
					<Label >Choose Categories</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						name="category_val[]"
						value={this.state.category_val}
						onChange={category_val => { this.setState({ category_val:category_val }) }}
						options={this.state.selectCategories}
					/>
				</div>
				<div id="show_products" style={{display:couponDiscount?((couponDiscount.is_product!=null&& couponDiscount.is_product==3)?'block':'none'):'none'}}>
					<Label >Choose Products</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						name="product_val[]"
						value={this.state.product_val}
						onChange={product_val => { this.setState({ product_val:product_val }) }}
						options={this.state.selectProducts}
					/>
				</div>

				<Row className="mt-2">
					<Colxx lg="12" className="mt-2 mb-2">
						<Label>Auto Apply</Label>	&nbsp;&nbsp;
						<Label>
						  <CustomInput
							id="auto_apply_yes"
							type="radio"
							name="auto_apply"
                            value={this.state.auto_apply_yes_value}
                            checked={this.state.auto_apply_yes}
                            onChange={() => { this.setState({ auto_apply_yes:true,auto_apply_no:false }) }}							
							className="d-inline-block"
						  />
						  {" "}Yes
						</Label>&nbsp;&nbsp;
						<Label>
						  <CustomInput
							id="auto_apply_no"
							type="radio"
							name="auto_apply"
							value={this.state.auto_apply_no_value}
							checked={this.state.auto_apply_no}
                            onChange={() => { this.setState({ auto_apply_yes:false,auto_apply_no:true }) }}
							className="d-inline-block"
						  />
							{" "}No
						</Label>&nbsp;&nbsp;
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Type of Customer</Label>
						  <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="customer_type"
								id="customer_type"
								value={this.state.customer_type}
								onChange={customer_type => { this.setState({ customer_type:customer_type }) }}
								options={selectType}
							/>
						</AvGroup>
					</Colxx>
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-coupon" >Update</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditNewCouponModal;
