import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
    Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddTypeModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-type").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("typeForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'customer/add-customer-referral',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-type").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-type").disabled=false;
			});

		}
	}

  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Referral			
		  </ModalHeader>
		  <AvForm
		        id="typeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="cname"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
					
				</Row>
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-type" >
			  <IntlMessages id="customer.add" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddTypeModal;
