import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle, Form, Label, Input, Button, Container, ListGroup, ListGroupItem, Badge,DropdownMenu, DropdownItem, CustomInput, FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";

import classnames from "classnames";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import ChangePasswordForm from "../../components/profile/ChangePassword";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL, SITE_URL, projectTitle, themeColor, CUSTOMER_ORDER_STATUS } from "../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class ChangePassword extends Component {

    constructor(props) {
        super(props);
		
    }

    componentDidMount() {
        document.body.classList.add("background");
        document.body.classList.add("background-main-pages");
        var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
            
        } else {
            window.location = '/';
        }
    }
	
    componentWillUnmount() {
        document.body.classList.remove("background");
        document.body.classList.remove("background-main-pages");
    }
	
	
    render() {
		
        return (
            <Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.change-password" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				
				<div className="pl-2 pr-2">
					<ChangePasswordForm 
						hideHead={true}
					/>
				</div>
            </Fragment>
        );
    }
}

export default ChangePassword;
