import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label, FormGroup,
	InputGroup,
	InputGroupAddon
} from "reactstrap";
import DatePicker from "../../../components/common/CustomDateTimePicker";

import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddStrainModal from "../../inventory/AddStrainModal";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';

import ProductRowInventory from "../../../components/receive-inventory/ProductRowInventory";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const qty_types = [
	{ id: 1, name: 'Grams' },
	{ id: 2, name: 'Lbs' },
	{ id: 3, name: 'Units' }
];

class CreateOrderModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateProductRow = this.updateProductRow.bind(this);
		this.getStrainsList = this.getStrainsList.bind(this);

		this.state = {
			product_row_id: null,
			order_data: null,
			selectVendor: null,
			selectDriver: null,
			vendors: [],
			products: [],
			strains: [],
			ordered_date: moment(),
			due_date: null,
			product_rows: [],
			sub_total: "0",
			discount: "0",
			vat_amount: "0",
			grand_total: "0",
			delivery_own: "1"
		};

	}

	componentDidMount() {
		var strains = [];
		var vendors = [];
		var drivers = [];
		var products = [];

		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'receive-inventory/get-data-for-receive-order', formData).then((res) => {
			var data = res.data;

			vendors = data.vendors;
			drivers = data.drivers;
			products = data.products;

			strains = strains.map((element, index) => {
				element.label = element.name;
				element.value = element.id;
				element.key = index;
				return element;
			});

			drivers = drivers.map((element, index) => {
				element.label = element.name;
				element.value = element.id;
				element.key = index;
				return element;
			});

			vendors = vendors.map((element, index) => {
				element.label = element.vendor_name;
				element.value = element.vendor_id;
				element.key = index;
				return element;
			});

			products = products.map((element, index) => {
				element.label = element.name;
				element.value = element.id;
				element.key = index;
				return element;
			});

			this.setState({
				strains,
				vendors,
				drivers,
				products,
			});

		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.modalOpen) {
			if (prevProps.order_id !== this.props.order_id && this.props.order_id != 0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id = dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('order_id', this.props.order_id);
				formData.append('facility_id', facility_id);
				axios.post(REST_API_END_POINT + 'receive-inventory/get-order-detail', formData).then((res) => {
					var data = res.data;

					if (data.status == 1) {
						var strains = this.props.strains;
						var order_data = data.order_data;
						var order_products = data.order_products;
						let selectVendor = this.state.vendors.find(r => { return order_data.vendor_id == r.value });
						let selectDriver = this.state.drivers.find(r => { return order_data.driver_id == r.value });
						let ordered_date = moment(order_data.ordered_date * 1000);
						let due_date = moment(order_data.due_date * 1000);
						let sub_total = order_data.sub_total;
						let discount = order_data.discount;
						let vat_amount = order_data.vat_amount;
						let grand_total = order_data.grand_total;
						let delivery_own = order_data.delivery_own;

						var product_rows = [];

						for (let row of order_products) {
							let product_id = this.state.products.find(r => { return r.value == row.product_id });
							let strain_id = strains.find(r => { return r.value == row.strain_id });
							let variation_id = null;

							let quantity_val = row.qty;
							let uom = null;
							if (product_id != null) {
								uom = qty_types.find(r => { return r.id == parseInt(product_id.qty_type) })

								if (row.content_type != 0) {
									variation_id = {
										product_id: product_id.value,
										variation_id: row.content_type,
										variation_name: row.variation_name,
										value: row.content_type,
										label: row.variation_name,
										key: row.content_type,
									}
								}
								if (product_id.qty_type == 2) {
									quantity_val = (row.qty / LBS_CONSTANT).toFixed(2);
								}
							}
							let product_row = {
								id: uuid(),
								product_id: product_id,
								product_quantity: quantity_val,
								uom: uom,
								variation_id: variation_id,
								unit_cost: row.unit_price,
								product_total: row.total_amount,
								notes: row.notes,
								on_consignment: row.on_consignment == 1 ? true : false,
								metrc_label: row.metrc_label,
								strain_id: strain_id,
								batches: []
							}
							if (row.order_batches.length) {
								for (let batch of row.order_batches) {
									product_row.batches.push({
										id: uuid(),
										batch_number: batch.batch_number,
										harvest_date: batch.harvest_date != 0 ? moment(batch.harvest_date * 1000) : null,
										package_date: batch.package_date != 0 ? moment(batch.package_date * 1000) : null,
										exp_date: batch.exp_date != 0 ? moment(batch.exp_date * 1000) : null,
										package_uid: batch.package_uid,
										gross_pkg: batch.gross_pkg,
										rcvd_total: batch.rcvd_total,
										net_mmj: batch.net_mmj,
										rcvd_amt_of_flower: batch.rcvd_amt_of_flower,
									});
								}
							}

							product_rows.push(product_row);

						}

						this.setState({
							strains,
							selectVendor,
							selectDriver,
							ordered_date,
							due_date,
							order_data,
							product_rows,
							sub_total,
							discount,
							vat_amount,
							grand_total,
							delivery_own
						});
					} else {
						this.props.toggleModal();
						swal("Warning!", "There is no such order", "warning");
					}
				}).catch(e => {
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}

	getStrainsList(product_row_id = null) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'inventory/get-strains-list', formData).then((res) => {
			var data = res.data;

			var strains = data.strains;
			strains = strains.map((element, index) => {
				element.label = element.name;
				element.value = element.id;
				element.key = index;
				return element;
			});

			var product_rows = this.state.product_rows;

			if (product_row_id != null) {
				product_rows = product_rows.map(row => {
					if (row.id == product_row_id && strains.length) {
						row.strain_id = strains[0];
					}
					return row;
				})
			}

			this.setState({
				strains: strains,
				product_rows
			});

		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	handleChangeDate = date => {
		this.setState({
			ordered_date: date
		});
	};

	handleChangeExpectedDate = date => {
		this.setState({
			due_date: date
		});
	};

	toggleModalAdd = (e, product_row_id = null) => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd,
			product_row_id
		});
	};

	addProductRow = (value, id) => {
		var product_rows = this.state.product_rows;

		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'receive-inventory/generate-package-id', formData).then((res) => {
			var data = res.data;

			product_rows.push({
				id: uuid(),
				product_id: this.state.products.length ? this.state.products[0] : null,
				product_quantity: "",
				uom: null,
				variation_id: null,
				unit_cost: "",
				product_total: "",
				notes: "",
				on_consignment: false,
				strain_id: null,
				batches: [
					{
						id: uuid(),
						batch_number: "",
						harvest_date: null,
						package_date: null,
						exp_date: null,
						package_uid: data.package_barcode,
						gross_pkg: "",
						rcvd_total: "",
						net_mmj: "",
						rcvd_amt_of_flower: "",
					}
				]
			});

			this.setState({
				product_rows: product_rows
			});

		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	updateProductRow = (value) => {
		var product_rows = this.state.product_rows;
		var sub_total = 0;
		var grand_total = 0;
		product_rows = product_rows.map(row => {
			if (row.id === value.id) {
				row = value;
			}
			if (row.product_total != "") {
				sub_total += parseFloat(row.product_total)
			}
			return row;
		});

		sub_total = sub_total.toFixed(2);

		let discount = 0;
		if (this.state.discount != "") {
			discount = parseFloat(this.state.discount);
		}

		let vat_amount = 0;
		if (this.state.vat_amount != "") {
			vat_amount = parseFloat(this.state.vat_amount);
		}

		grand_total = parseFloat(sub_total) - discount + vat_amount;

		this.setState({
			product_rows,
			sub_total,
			grand_total
		});
	}

	removeProductRow = (id) => {
		var product_rows = this.state.product_rows;
		var sub_total = 0;
		var grand_total = 0;
		product_rows = product_rows.filter(row => { return row.id != id });
		product_rows = product_rows.map(row => {
			if (row.product_total != "") {
				sub_total += parseFloat(row.product_total)
			}
			return row;
		});

		sub_total = sub_total.toFixed(2);

		let discount = 0;
		if (this.state.discount != "") {
			discount = parseFloat(this.state.discount);
		}

		let vat_amount = 0;
		if (this.state.vat_amount != "") {
			vat_amount = parseFloat(this.state.vat_amount);
		}

		grand_total = parseFloat(sub_total) - discount + vat_amount;

		this.setState({
			product_rows,
			sub_total,
			grand_total,
			flag:0
		});
	}

	handleSubmit(event, errors, values) {


	
		if (errors.length === 0) {

		
			const batches = this.state.product_rows[0].batches;
			let flag=this.state.flag;
			if (batches) {
				
				batches.forEach((r, i) => {
					if (r['exp_date'] === null) {
						this.setState({flag:1})
						flag=1
						const errorMessage = "Please Enter Expiry date";
						swal("Warning", errorMessage, "warning");
						return;
						
					} 
					this.setState({flag:0})
					flag=0
				});
			} else {
				console.log("#########vvvvvbatch",batches);
				// Handle the case where batches is undefined or null
			}

			console.log("#########flag",flag);
			if(flag==1){
				const errorMessage = "Please Enter Expiry date";
				swal("Warning", errorMessage, "warning");
					return;
			}
			
			let errorMessage = '';
			

			if (this.state.selectVendor == null) {
				errorMessage = "Please choose the vendor";
				swal("Warning", errorMessage, "warning");
				return false;
			}

			if (this.state.selectDriver == null && values.delivery_own == 1) {
				errorMessage = "Please choose the driver";
				swal("Warning", errorMessage, "warning");
				return false;
			}


			if (this.state.product_rows.length == 0) {
				errorMessage = "Please add at least one row";
				swal("Warning", errorMessage, "warning");
				return false;
			}

			document.getElementById("edit-order").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("orderForm"));

			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
			formData.append('order_id', this.props.order_id);

			axios.post(REST_API_END_POINT + 'receive-inventory/update-order', formData).then((res) => {
				var data = res.data;
				document.getElementById("edit-order").disabled = false;
				if (data.status == 1) {
					formData.append('facility_id', facility_id);
					formData.append('user_id', user_id);
					formData.append('order_id', this.props.order_id);

					axios.post(REST_API_END_POINT + 'receive-inventory/receive-order', formData).then((res) => {
						var data = res.data;

						// document.getElementById("receive_order").disabled = false;
						// this.props.dataListRender();
						// this.props.toggleModal();

						// if(data.status==1){
						// 	swal("Success",data.msg,"success");
						// }else{
						// 	swal("Failed",data.msg,"warning");
						// }
						var api_message = document.getElementById("api_message");
						api_message.innerHTML = "<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

						window.setTimeout(function () {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);

						JQUERY_MODULE.$('.alert .close').on("click", function (e) {
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						});

						this.props.toggleModal();
						this.props.dataListRender();
					})
				} else {
					swal("Warning!", data.msg, 'warning');
				}


			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-order").disabled = false;
			});

		}

	}

	render() {
		const { vendors, products, strains, drivers, product_rows, order_data } = this.state;
		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right modal-right-lg"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Order
				</ModalHeader>
				<AvForm
					id="orderForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>

						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Select Vendors<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="vendor_id"
										id="edit_vendor_id"
										value={this.state.selectVendor}
										options={vendors}
										onChange={selectVendor => { this.setState({ selectVendor }) }}
										required
									/>
								</AvGroup>
							</Colxx>
						</Row>


						{product_rows.map((product, index) => {
							return (<ProductRowInventory
								key={index}
								index={index}
								product_row={product}
								products={this.state.products}
								strains={this.state.strains}
								toggleModalAdd={this.toggleModalAdd}
								updateProductRow={this.updateProductRow}
								removeProductRow={this.removeProductRow}
								product_rows_length={product_rows.length}
							/>);
						})}




					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="edit-order" >
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
				<AddStrainModal
					modalOpen={this.state.modalOpenAdd}
					product_row_id={this.state.product_row_id}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.getStrainsList}
				/>
			</Modal>
		);
	}
};

export default CreateOrderModal;
