import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];
 
class EditTypeModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {	
			statusType: { label: "Active", value: "1", key: 0 },
			typeCategory:null,
		};	
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-type").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("typeForm"));
			formData.append('type_id',this.props.type_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'customer/edit-customer-referral',formData).then((res)=>{
				var data = res.data;
				
				var typeCategory=this.state.typeCategory;
				typeCategory.name=values.name;
				this.setState({ typeCategory:typeCategory });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-type").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-type").disabled=false;
			});

		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
	   
        if(this.props.modalOpen){
			let typeCategory; 		  
            if (prevProps.type_id !== this.props.type_id) {
				var formData = new FormData();
				formData.append('type_id',this.props.type_id);
	
				axios.post(REST_API_END_POINT+'customer/get-referral-category-by-id',formData).then((res)=>{
					var data = res.data;
					var status=null;
					typeCategory=data.result;
					
					selectStatus.forEach((element,index) => {
                       if(element.value==typeCategory.status){
							status=element;
						}
					});
					this.setState({ 
						typeCategory,
						statusType:status,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}
	  
  render() {
		if(this.state.typeCategory!=null){
			var typeCategory=this.state.typeCategory;
		}else{
			var typeCategory;
		}
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Type Category			
		  </ModalHeader>
		  <AvForm
		        id="typeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="cname"
							name="name"
							value={ typeCategory?((typeCategory.name!=null)?typeCategory.name:''):'' }
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "Your name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "Your name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Status</Label>
						 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"						
						name="status"
						id="status"
						value={this.state.statusType}						
						options={selectStatus}
						onChange={statusType => { this.setState({ statusType }) }}

					/>
						</AvGroup>					
					</Colxx>					
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-type" >Update</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditTypeModal;
