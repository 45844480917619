import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { REST_API_BASE_URL ,REST_API_END_POINT,dataEncryptKey} from "../../constants/defaultValues";
import { empty } from "uuidv4";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import axios from 'axios';

import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);
export default class CashPaymentModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			// cash_total:{
			// 	'1_s':0,
			// 	'2_s':0,
			// 	'5_s':0,
			// 	'10_s':0,
			// 	'20_s':0,
			// 	'50_s':0,
			// 	'100_s':0,
			// 	'cents':0,
			// 	'nickles':0,
			// 	'dimes':0,
			// 	'quarters':0,
			// 	'50_cents':0,
			// 	'1_d':0
			// },
			cash_total: 0,
			total_due: 0,
			balance: 0,
			pinValidate: true,
			user_pin: this.props.user_pin,
			drawer_id: "",
			pinEntered: "",
			currency_code:'USD'
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		const payment_data = this.props.payment_data;

		var cash_total = this.state.cash_total;
		var total_due = this.props.total_due.toFixed(2);
		{
			console.log('Due state start', total_due);
		}
		if (payment_data != null) {
			cash_total = payment_data['cash']
		}
		// 	cash_total={
		// 		'1_s':payment_data['1_s'],
		// 		'2_s':payment_data['2_s'],
		// 		'5_s':payment_data['5_s'],
		// 		'10_s':payment_data['10_s'],
		// 		'20_s':payment_data['20_s'],
		// 		'50_s':payment_data['50_s'],
		// 		'100_s':payment_data['100_s'],
		// 		'cents':payment_data['cents'],
		// 		'nickles':payment_data['nickles'],
		// 		'dimes':payment_data['dimes'],
		// 		'quarters':payment_data['quarters'],
		// 		'50_cents':payment_data['50_cents'],
		// 		'1_d':payment_data['1_d']
		// 	}
		// }
		this.setState({ cash_total, total_due: parseFloat(total_due).toFixed(2) });
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			var total_due = parseFloat((this.state.total_due)) - parseFloat(this.state.cash_total);
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			let formData = new FormData();
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
			formData.append('drawer_id', this.state.drawer_id.value);
			formData.append('currency_code', this.state.currency_code);
			formData.append('return_amount',total_due < 0 ? (total_due.toFixed(2) * -1) : "0.0")

			let api_endpoint = "drawer/check-drawer-stock";

			axios.post(REST_API_END_POINT + api_endpoint, formData).then((res) => {
				var data = res.data;

				if (data.proceed){
					var payment_data = this.props.payment_data;
					var cash_total = this.state.cash_total;
		
					// payment_data['1_s'] = cash_total['1_s'];
					// payment_data['2_s'] = cash_total['2_s'];
					// payment_data['5_s'] = cash_total['5_s'];
					// payment_data['10_s'] = cash_total['10_s'];
					// payment_data['20_s'] = cash_total['20_s'];
					// payment_data['50_s'] = cash_total['50_s'];
					// payment_data['100_s'] = cash_total['100_s'];
					// payment_data['cents'] = cash_total['cents'];
					// payment_data['nickles'] = cash_total['nickles'];
					// payment_data['dimes'] = cash_total['dimes'];
					// payment_data['quarters'] = cash_total['quarters'];
					// payment_data['50_cents'] = cash_total['50_cents'];
					// payment_data['1_d'] = cash_total['1_d'];
					console.log('#######payment', payment_data);
		
					if (this.state.cash_total != null) {
						//payment_data['cash'] = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
						payment_data['cash'] = cash_total
					} else {
						payment_data['cash'] = 0;
					}
					if(this.state.drawer_id){
						payment_data['drawer_id']=this.state.drawer_id
						payment_data['pin']=this.state.pinEntered
					}
					this.props.updatePaymentData(payment_data);
					this.props.toggleModal();
				}else {
						swal("There is no enough money in the drawer");
						document.getElementById("complete-order").disabled = false;
					}
				}).catch(e => {
					console.log("Addition failed , Error ", e);
					
				});
			
		}
	}
	calculateTotal = () => {

	}
	handleInputChange(event) {


		// var user_pin=this.state.user_pin?this.state.user_pin:""
		var user_pins = this.state.drawer_id ? this.state.drawer_id.user_pins : ""
		console.log('Nush123', this.state.drawer_id);
		let userPinsArray = user_pins.split(',');
		let isPinValid = userPinsArray.includes(event.target.value);
		if (isPinValid) {
			this.setState({ pinValidate: false })
		}
		else {
			this.setState({ pinValidate: true })
		}
		this.setState({ pinEntered: event.target.value })

	}
	handleSelect(drawer_id) {
		let pin = this.state.pinEntered;
		console.log('#####nush',drawer_id);
		
		this.setState({ drawer_id: drawer_id })
		let user_pins = drawer_id.user_pins
		let userPinsArray = user_pins.split(',');
		let isPinValid = userPinsArray.includes(pin);
		if (isPinValid) {
			this.setState({ pinValidate: false })
		}
		else {
			this.setState({ pinValidate: true })
		}
	}
	render() {

		var cash_total = this.state.cash_total;
		var total = 0;
	
		if (this.state.cash_total != null) {
			//total = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
			total = this.state.cash_total
		}


		if (total.length == 0) {
			total = 0;

		}
		

		var total_due = parseFloat((this.state.total_due - total).toFixed(2));

		var balance = parseFloat(this.state.total_due) - parseFloat(total);


		if (parseFloat(total_due.toFixed(2)) == 0) {
			total_due = 0;
		}


		const currencies = this.props.currencies;
		const selected_currency = currencies.find(row => { return row.currency_code == this.props.currency_code });
		var field_view = [];
		// if(typeof selected_currency!='undefined'){
		// 	const currency_struct = JSON.parse(selected_currency.currency_struct);
		// 	for(let key in currency_struct){

		// 		field_view.push(<Fragment key={key}>
		// 							<Colxx xxs="3" className="mt-4">
		// 								<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
		// 							</Colxx>
		// 							<Colxx xxs="3" className="mt-4">
		// 								<AvGroup className="denomination-qty">
		// 									<InputGroup>
		// 										<InputGroupAddon addonType="prepend" onClick={e=>{
		// 											var cash_total = this.state.cash_total;
		// 											cash_total[key]+=1;
		// 											this.setState({
		// 												cash_total
		// 											});
		// 										}} >+</InputGroupAddon>
		// 										<AvField
		// 											id={key}
		// 											name={key}
		// 											type="number"
		// 											placeholder={currency_struct[key].alt}
		// 											min="0"
		// 											step="1"
		// 											value={this.state.cash_total[key]!=0?this.state.cash_total[key]:''}
		// 											onChange={(e)=>{
		// 												var cash_total = this.state.cash_total;
		// 												if(e.target.value!=''){
		// 													cash_total[key]=e.target.value;
		// 												}else{
		// 													cash_total[key]=0;
		// 												}
		// 												this.setState({
		// 													cash_total
		// 												});
		// 											}}
		// 										/>
		// 										<InputGroupAddon addonType="append" onClick={e=>{
		// 											var cash_total = this.state.cash_total;
		// 											if(cash_total[key]!=0){
		// 												cash_total[key]-=1;
		// 												this.setState({
		// 													cash_total
		// 												});
		// 											}
		// 										}} >-</InputGroupAddon>
		// 									</InputGroup>
		// 								</AvGroup>
		// 							</Colxx>
		// 						</Fragment>)

		// 	}
		// }
	
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				size="lg"
			>
				
				<ModalHeader toggle={this.props.toggleModal}>
					Cash Payment
				</ModalHeader>
				<AvForm
					id="cashMoneyForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row className="align-items-center">
							{field_view}
						</Row>
						<Row className="mt-4">
							<Colxx xxs="3">
								<h3>Choose Drawer</h3>
							</Colxx>
							<Colxx xxs="6">
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="customer_refer_from"
									id="customer_refer_from"
									value={this.state.drawer_id}
									options={this.props.drawers}
									// onChange={drawer_id => { this.setState({ drawer_id: drawer_id }) }}
									onChange={drawer_id => { this.handleSelect(drawer_id) }}
								/>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx xxs="3">
								<h3>Cash</h3>
							</Colxx>
							<Colxx xxs="6">
								<AvGroup className="error-t-negative">
									<AvField
										id="cash"
										name="cash"
										type="number"
										value={this.state.cash_total}
										onChange={(e) => { this.setState({ cash_total: e.target.value }) }}
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the amount"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than 0"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-4">
							{console.log('##########Due', total_due)}
							<Colxx xxs="3">
								<h3>Total Due</h3>
							</Colxx>
							<Colxx xxs="6">
								<AvGroup className="error-t-negative">
									<AvField
										id="total_due"
										name="total_due"
										type="text"
										readOnly={true}
										placeholder="0"
										value={(total_due > 0) ? total_due.toFixed(2) : "0.0"}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx xxs="3">
								<h3>Balance</h3>
							</Colxx>
							<Colxx xxs="6">
								<AvGroup className="error-t-negative">
									<AvField
										id="total_due"
										name="total_due"
										type="text"
										readOnly={true}
										placeholder="0"
										value={total_due < 0 ? (total_due.toFixed(2) * -1) : "0.0"}
									/>
								</AvGroup>
							</Colxx>
						</Row>

						<Row className="mt-4">
							<Colxx xxs="3">
								<h3>PIN</h3>
							</Colxx>
							<Colxx xxs="6">
								<AvGroup className="error-t-negative">
									<AvField
										id="pin"
										name="pin"
										type="password"
										style={{ borderColor: this.state.pinValidate ? 'red' : 'green' }}
										// readOnly={true}
										placeholder="pin"
										value=""
										onChange={this.handleInputChange}
									/>
								</AvGroup>
							</Colxx>

						</Row>

					</ModalBody>
					{console.log('##########Duest', total_due )}
					{console.log('##########Duest2', parseFloat(total_due) > 0)}
					<ModalFooter className="justify-content-start">
						<Button color="primary" id="add-money"  disabled={(this.state.pinValidate || parseFloat(total_due) >0)}>
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
