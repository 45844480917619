import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle, Form, Label, Input, Button, Container, ListGroup, ListGroupItem, Badge, DropdownMenu, DropdownItem, CustomInput, FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

import classnames from "classnames";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL, JQUERY_MODULE, projectTitle, themeColor, CUSTOMER_ORDER_STATUS } from "../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			customer: null,
			blocking: true,
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.verifyShippingAddress = this.verifyShippingAddress.bind(this);

	}

	componentDidMount() {
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var facility_id = localStorage.getItem('facility_id');
		if (facility_id) {
			this.dataListRender();
		} else {
			window.location = '/';
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}

	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		const user_id = localStorage.getItem('user_id');

		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'wholesale/get-data-for-add-vendor', formData).then((res) => {
			var data = res.data;
			let stateOptions = [];
			if (data.partner_types) {
				stateOptions = data.states;
				let selectStateType = null;

				stateOptions = stateOptions.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				if (stateOptions.length) {
					selectStateType = stateOptions[0];
				}

				this.setState({
					stateOptions,
					selectStateType
				});
			}

			let customer_id = localStorage.getItem('shop_customer_id');
			formData = new FormData();
			formData.append('customer_id', customer_id);
			axios.post(REST_API_END_POINT + 'customer/get-customer-by-id', formData).then((res) => {
				if (res.data.status == 1) {
					let data = res.data;
					let customer = res.data.customer;
					let selectStateType = stateOptions.find(r => { return r.id == customer.vendor_state });

					this.setState({ customer, selectStateType, blocking: false, });
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e)
				this.setState({ blocking: false });
			});
		}).catch(e => {
			console.log("Addition failed , Error ", e);
			this.setState({ blocking: false });
		});

	}

	async handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			let proceed = await this.verifyShippingAddress();
			if (proceed) {
				document.getElementById("update-customer").disabled = true;

				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id = dataCrypto.decrypt(facility_id);
				let customer_id = localStorage.getItem('shop_customer_id');

				var formData = new FormData(document.getElementById("customerForm"));
				formData.append('customer_id', customer_id);
				formData.append('facility_id', facility_id);
				formData.append('user_id', user_id);

				axios.post(REST_API_END_POINT + 'customer/edit-profile', formData).then((res) => {
					var data = res.data;

					if (data.status == 1) {
						var api_message = document.getElementById("api_message_profile");
						api_message.innerHTML = "<div class=\"rounded alert alert-" + (data.status == 1 ? "success" : "danger") + " alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

						window.setTimeout(function () {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);

						JQUERY_MODULE.$('.alert .close').on("click", function (e) {
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
								JQUERY_MODULE.$(this).remove();
							});
						});
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}

					document.getElementById("update-customer").disabled = false;

				}).catch(e => {
					console.log("Addition failed , Error ", e);
					document.getElementById("update-customer").disabled = false;
				});
			}

		}
	}


	async verifyShippingAddress() {
		let proceed = true;
		const { selectStateType, stateOptions } = this.state;
		let billAddress = document.getElementById("customer_address").value;
		let billCity = document.getElementById("vendor_city").value;
		let customerStateName = selectStateType != null ? selectStateType.short_name : "";
		let billZipCode = document.getElementById("zip_code").value;

		if (billAddress != '' && billCity != '' && customerStateName != '' && billZipCode != '') {

			document.getElementById("update-customer").disabled = true;

			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData();
			formData.append('facility_id', facility_id);
			formData.set('bill_address', billAddress);
			formData.set('bill_city', billCity);
			formData.set('customer_state_name', customerStateName);
			formData.set('bill_zipcode', billZipCode);

			await axios.post(REST_API_END_POINT + 'front-end/verify-shipping-address', formData).then((res) => {
				var data = res.data;
				let address = data.address;

				if (typeof address.Error != 'undefined') {
					swal("Warning!", address.Error.Description, "warning");
					proceed = false;
				} else if (typeof address.Address2 != 'undefined') {
					if (typeof address.State != 'undefined') {
						this.setState({ selectStateType: stateOptions.find(s => { return s.short_name.toLowerCase() == address.State.toLowerCase() }) });
					}
					let finalAddress = [typeof address.Address1 != 'undefined' ? address.Address1 : '', address.Address2].filter(row => { return row != '' }).join(', ');
					document.getElementById("customer_address").value = finalAddress;
					document.getElementById("vendor_city").value = typeof address.City != 'undefined' ? address.City : "";
					document.getElementById("zip_code").value = typeof address.Zip5 != 'undefined' ? address.Zip5 : "";
				}

				document.getElementById("update-customer").disabled = false;
			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("update-customer").disabled = false;
			});
		}
		return proceed;
	}

	render() {
		const { customer, stateOptions } = this.state;

		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.edit-profile" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />} message="Please wait" keepInView>
					<div id="api_message_profile"></div>

					<AvForm
						id="customerForm"
						className="error-t-negative pl-2 pr-2 pb-5"
						onSubmit={this.handleSubmit}
					>
						<div className="billing-info-wrap">
							<Row className="">
								<Colxx lg="12">
									<AvGroup className="">
										<Label>Name<span className="text-danger">*</span></Label>
										<AvField
											id="customer_name"
											name="customer_name"
											type="text"
											value={customer != null ? customer.vendor_name : ""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the name"
												},
												pattern: {
													value: "^[a-zA-Z ,.'-]+$",
													errorMessage: "Invalid name"
												},
												minLength: {
													value: 2,
													errorMessage: "Name must be between 2 and 100 characters"
												},
												maxLength: {
													value: 100,
													errorMessage: "Name must be between 2 and 100 characters"
												}
											}}
										/>
									</AvGroup>
								</Colxx>
								<Colxx md="6">
									<AvGroup className="">
										<Label>Email Address<span className="text-danger">*</span></Label>
										<AvField
											id="email_id"
											name="email"
											type="email"
											value={customer != null ? customer.email_id : ""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the email address"
												}
											}}
										/>
									</AvGroup>
								</Colxx>

								<Colxx md="6">
									<AvGroup className="">
										<Label>Phone<span className="text-danger">*</span></Label>
										<AvField
											id="phone"
											name="phone"
											type="text"
											value={customer != null ? customer.phone : ""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the phone number"
												},
												number: {
													value: true,
													errorMessage: "Please enter a valid number"
												}
											}}
										/>
									</AvGroup>
								</Colxx>

								{this.state.checkout_mode === "Pick up in store" ? "" : <Fragment>
									<Colxx lg="12">
										<AvGroup className="">
											<Label>Billing Address<span className="text-danger">*</span></Label>
											<AvField
												id="customer_address"
												name="customer_address"
												type="textarea"
												value={customer != null ? customer.vendor_address : ""}
												autoComplete="off"
												validate={{
													required: {
														value: true,
														errorMessage: "Please enter the billing address"
													}
												}}
											/>
										</AvGroup>
									</Colxx>

									<Colxx lg="12">
										<AvGroup className="">
											<Label>City<span className="text-danger">*</span></Label>
											<AvField
												id="vendor_city"
												name="vendor_city"
												type="text"
												value={customer != null ? customer.vendor_city : ""}
												autoComplete="off"
												validate={{
													required: {
														value: true,
														errorMessage: "Please enter the city"
													}
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="12">
										<AvGroup className="">
											<Label>State<span className="text-danger">*</span></Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"
												name="vendor_state"
												id="vendor_state"
												value={this.state.selectStateType}
												options={stateOptions}
												onChange={selectStateType => { this.setState({ selectStateType }) }}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="12">
										<AvGroup className="">
											<Label>Postcode/Zip<span className="text-danger">*</span></Label>
											<AvField
												id="zip_code"
												name="zip_code"
												type="text"
												value={customer != null ? customer.zip_code : ""}
												autoComplete="off"
												validate={{
													required: {
														value: true,
														errorMessage: "Please enter the zip code"
													}
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="12">
										<AvGroup className="error-t-negative ">
											<Label>Notes</Label>	&nbsp;&nbsp;
											<AvField
												id="designation_notes"
												name="designation_notes"
												type="textarea"
												value={customer ? ((customer.designation_notes != null) ? customer.designation_notes : '') : ''}
												placeholder=""
												rows="3"
												validate={{
													minLength: {
														value: 10,
														errorMessage:
															"Your message must have minimum of 10 characters"
													},
												}}
											/>

										</AvGroup>
									</Colxx>
								</Fragment>}
							</Row>
							<div className="place-order mt-3">
								<Button color="primary" id="update-customer" >Submit</Button>
							</div>
						</div>
					</AvForm>
				</BlockUi>
			</Fragment >
		);
	}
}

export default EditProfile;
